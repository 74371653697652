import { html } from 'lit';
import { connect } from 'pwa-helpers/connect-mixin.js';

import { PageViewElement } from '@fundwave/ui-utils/src/page-view-element.js';
import { DialogStyles } from '@fundwave/styles/dialog-styles';
import { UserUtil } from "@fundwave/ui-utils/src/UserUtil.js";

import "@fundwave/fw-dropdown/src/fw-dropdown.js";

import "@polymer/paper-listbox/paper-listbox.js";
import "@polymer/paper-item/paper-item.js";
import '@material/mwc-icon/mwc-icon.js';

import { store } from '../store.js';
import { routeProperties, allRouteGroups } from "../constants";
import { navigate, showMessageBar } from "../actions/app.js";
import { fetchAssets, updateAssetById } from "../actions/asset.js";
import { updateFundById } from "../actions/fund.js";

import { addUserToAsset } from "./utils/asset-utils.js";

import { Fzf } from "fzf";
import { updateInvestorById } from '../actions/investor.js';
import { track } from '../usage-tracking.js';

const RESULTS_SIZE = 10;
const ENTITY_WEIGHT = 0.05;

const staticParamValues = {
  noticeType: ["close"],
  importType: ["add", "contact", "bank"],
  portalType: ["investor", "asset", "deal", "functions"]
}

const supportedParams = ["fundId", "assetId", "investorId", ...Object.keys(staticParamValues)];
const supportedEntities = ["fund", "asset", "investor"];

const getTieBreakers = function(selectedEntity) {
  return [(a, b) => {
    const contextRankMap = {
      "none": 1,
      "fund": 2,
      "asset": 3,
      "investor": 4
    }

    if (selectedEntity?.type) {
      contextRankMap[selectedEntity?.type] = 0;
    }

    if ((a.item.searchPriority || b.item.searchPriority) && a.item.searchPriority !== b.item.searchPriority)
      return ((a.item.searchPriority ?? Infinity) - (b.item.searchPriority ?? Infinity)) || 0;
    else
      return contextRankMap[a.item.contextType ?? "none"] - contextRankMap[b.item.contextType ?? "none"]
  }]
};

const userRoles = ["investor", "portfolio", "asset", "fund", "admin"];
const roleChains = {
  "fund": UserUtil.fundRoleChain,
  "user": userRoles.reduce((roleMap, role) => {
    roleMap[role] = userRoles.slice(userRoles.indexOf(role), userRoles.length);
    return roleMap;
  }, {})
}

export class Search extends connect(store)(PageViewElement) {
  render() {
    return html`
      ${DialogStyles}
      <style>
        :host {
          width: 28vw;
          max-width: 28vw;
        }

        #results-container {
          display: flex;
          flex-direction: column;
        }

        .hide {
          display: none !important;
        }

        #search-box {
          --paper-input-container-underline_-_display: none;
          --paper-input-container-underline-focus_-_display: none;
          --paper-input-container-underline-disabled_-_display: none;
        }

        #search-box::part(header) {
          border: var(--secondary-color-l1) 1px solid;
          background-color: var(--secondary-color-l4);
          border-radius: 6px;
          padding: 4px 10px;
          --paper-input-container_-_padding: 0 0 0 12px;
        }

        #search-box::part(header-open) {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border: 0;
          border-bottom: var(--secondary-color-l2) 1px solid;
          background-color: #fff;
        }

        #search-box::part(list-content) {
          max-height: 34vh;
          box-shadow: none;
        }

        #join-asset-dialog {
          display: flex;
          justify-content: center;
        }

        @media (max-width: 760px) {
          :host {
            max-width: 100%;
            width: 100%;
          }
        }
      </style>

      <fw-dropdown
        id="search-box"
        .label=${`Quick Navigate (${this.platform === "Mac" ? "Cmd" : "Ctrl"}+K)`}
        .list=${this.searchResults}
        .addNew=${false}
        .alwaysFloatLabel=${false}
        .key=${"displayValue"}
        .idKey=${"url"}
        .noFloatLabel=${true}
        .required=${false}
        .iconPosition=${"prefix"}
        .customSearch=${true}
        .showBackdrop=${true}
        .renderListItem=${this.renderResultItem}
        @item-selected=${(e) => this.navigateToResult(e.detail.selectedItem)}
        @value-changed=${this.debounce((e) => { this.updateSearchSuggestions(e.detail.value) }, 200)}
        @dropdown-closed=${(e) => {
          track("Collapse search suggestions", { query: this.shadowRoot?.getElementById("search-box")?.searchBarValue, source: e.detail.source });
        }}
      ></fw-dropdown>
      <paper-dialog id="join-asset-dialog" class="centered" modal @iron-overlay-opened=${(e) => this.patchOverlay(e)}>
        <h3>Joining ${this.selectedAssetName ?? "asset"}...</h3>
      </paper-dialog>
    `;

  }

  static get properties() {
    return {
      flattenedRoutes: Array,
      searchResults: Array,
      fzfObject: Object,
      baseRoutesFzfObject: Object,
      selectedEntityFzfObject: Object,
      userFunds: Array,
      fundsOfUser: Array,
      fundRoleMap: Object,
      funds: Array,
      assets: Array,
      assetsOfUser: Array,
      assetUpdateTime: Object,
      investors: Array,
      userInvestors: Array,
      user: Object,
      captableTeamId: String,
      platform: String,
      selectedAssetName: Object,
      fund: Object,
      asset: Object,
      investor: Object,
      selectedEntity: Object,
      user: Object
    }
  }

  async initFzf() {
    const routeGroupMap = allRouteGroups.reduce((groupMap, group) => {
      group.routes.map(route => groupMap[route] = group);
      return groupMap;
    }, {});

    this.flattenedRoutes = [];
    
    Object.entries(routeProperties).forEach(([routeName, routeProperty]) => {
      if (routeProperty.skipGlobalSearch) return;

      // Get icon from route groups
      const routeGroup = routeGroupMap[routeName]
      if (routeGroup && !routeProperty.icon) {
        routeProperty.icon = routeGroup.icon;
        routeProperty.iconProps = routeGroup.iconProps;
      }

      // Flatten all urls
      this.flattenedRoutes.push(...routeProperty.urls.map(url => {
        const route = {
          ...routeProperty,
          key: routeName,
          url
        };
        delete route.urls;
        
        return route;
      }));
    });

    this.baseRoutesFzfObject = new Fzf(this.populateBasicSearchSelectors(this.sanitizeRoutes(this.flattenedRoutes)), {
      selector: (item) => item.searchSelector,
      casing: "case-insensitive"
    });
  }

  constructor() {
    super();

    this.initFzf();

    this.fundRoleMap = null;
    this.fundsOfUser = null;
    this.searchResults = [];
    this.platform = navigator.userAgent.includes("Mac") ? "Mac" : "Linux/Windows";

    document.addEventListener('keydown', (e) => {
      if ((this.platform === "Mac" ? e.metaKey : e.ctrlKey) && e.key === "k") {
        e.preventDefault();
        e.stopPropagation();
        this.shadowRoot.getElementById("search-box").shadowRoot.getElementById("searchBar").focus();
      }
    })
  }

  async updated(changedProperties) {
    if ((changedProperties.has("userInvestors") || changedProperties.has("fundRoleMap")) && this.userInvestors && this.fundRoleMap) {
      this.investors = this.userInvestors.map(investor => {
        investor.parentEntities = [{ ...investor.partnership.fund, type: "fund", role: this.fundRoleMap[investor.partnership.fund.id] }];
        return investor;
      });
    }

    if ((changedProperties.has("fundsOfUser") || changedProperties.has("fundRoleMap")) && this.fundRoleMap && this.fundsOfUser) {
      this.funds = this.fundsOfUser.map(fund => ({
        id: String(fund.fundId),
        name: fund.fundName,
        role: this.fundRoleMap[fund.fundId]
      }));
    }

    if (changedProperties.has("userFunds")) {
      this.fundRoleMap = this.userFunds.reduce((fundRoleMap, userFund) => {
        fundRoleMap[userFund.fund.fundId] = userFund.fundRole;
        return fundRoleMap;
      }, {});
    }

    if (changedProperties.has("fzfObject") || changedProperties.has("selectedEntityFzfObject") || changedProperties.has("baseRoutesFzfObject") || changedProperties.has("selectedEntity")) {
      if (this.fzfObject || !this.selectedEntityFzfObject || !this.baseRoutesFzfObject)
        this.updateSearchSuggestions(this.shadowRoot?.getElementById("search-box")?.searchBarValue ?? "");
    }

    if ((changedProperties.has("funds") || changedProperties.has("assets") || changedProperties.has("investors")) && this.funds && this.assets && this.investors && this.user) {
      this.fzfObject = this.buildFzf(this.flattenedRoutes, null);
    }

    if ((changedProperties.has("fund") || changedProperties.has("asset") || changedProperties.has("investor"))) {

      if (!this.fund && !this.asset && !this.investor) {
        this.selectedEntity = null;
      } else {
        this.selectedEntity = this.getTopmostEntity(this.fund, this.asset, this.investor);
        this.selectedEntityFzfObject = this.buildFzf(this.flattenedRoutes, this.selectedEntity);
      }

    }
  }

  getTopmostEntity(fund, asset, investor) {
    if (investor)
      return {...investor, id: String(investor.id), type: "investor", parentEntities: [{ ...investor.partnership?.fund, type: "fund", role: this.fundRoleMap[String(investor.partnership?.fund?.fundId)]}]};

    if (asset)
      return {...asset, id: String(asset.id), type: "asset"};

    return {...fund, id: String(fund?.fundId), name: fund?.fundName, type: "fund", role: this.fundRoleMap[String(fund?.fundId)]};
  }

  stateChanged(state) {
    this.userFunds = state.fund.userFunds;
    this.fundsOfUser = state.fund.fundsOfUser;
    this.assetsOfUser = state.asset.assets;
    this.assets = state.asset.userAssets;
    this.userInvestors = state.investor.userInvestors;
    this.user = state.user.user;
    this.captableTeamId = state.captable.captableTeamId;
    this.assetUpdateTime = state.updateTime.asset;
    this.fund = state.fund.fund;
    this.asset = state.asset.asset;
    this.investor = state.investor.investor;
  }

  async updateStateContext({ context, parentId, contextName }) {
    switch (context?.entityType) {
      case "fund":
        store.dispatch(updateInvestorById(null));
        store.dispatch(updateAssetById(null));
        store.dispatch(updateFundById(context.id));
        break;

      case "asset": 
        const asset = this.assetsOfUser.find((userAsset) => userAsset.id == context.id);

        if (!asset) {
          this.selectedAssetName = contextName;
          this.shadowRoot.getElementById("join-asset-dialog").open();

          try {
            await addUserToAsset(context, this.user, this.captableTeamId);
          } catch (error) {
            store.dispatch(showMessageBar(error.message, "error"));
          }

          this.shadowRoot.getElementById("join-asset-dialog").close();
          await store.dispatch(fetchAssets());
        }

        if (parentId) {
          store.dispatch(updateFundById(parentId));
        } else {
          store.dispatch(updateFundById(null));
        }

        store.dispatch(updateAssetById(context.id));
        break;

      case "investor":
        store.dispatch(updateFundById(parentId));
        store.dispatch(updateInvestorById(context.id));
        break;

      default: 
        store.dispatch(updateFundById(null));
        store.dispatch(updateInvestorById(null))
        store.dispatch(updateAssetById(null));
    }
  }

  populateBasicSearchSelectors(routes) {
    routes.forEach(routeProperty => {
      routeProperty.searchSelector = `${routeProperty.keywords?.join(" ") ?? ""}`
      if (typeof routeProperty.title === "string") routeProperty.searchSelector += ` | ${routeProperty.title}`;
      if (routeProperty.name && !(routeProperty.name instanceof Function)) routeProperty.searchSelector += ` | ${routeProperty.name}`;
    });

    return routes;
  }

  checkRoutePermission(routeRole, userEntityRole, entityType, params) {
    if (!routeRole || !userEntityRole) return true;
    if (typeof routeRole === "function") routeRole = routeRole(params);

    if (
      routeRole && 
      userEntityRole && 
      !roleChains[entityType][routeRole].includes(userEntityRole)
    ) return false;

    return true;
  }

  populateEntities(routes, selectedEntity) {
    const entityPopulatedRoutes = [];

    routes.forEach(routeProperty => {
      const matches = routeProperty.url.match(/(\/)*\:\w(\/)*/g);
      const contextMatches = routeProperty.url.match(new RegExp(supportedParams.map(param => `(${param})`).join("|"), "g"));

      if (contextMatches?.length !== matches?.length) // For non top-level entities like :noticeId, :allocationKeyId
        return; 

      if (!this.checkRoutePermission(routeProperty[`userRole`], this.user.role.role, "user")) return;

      if (!matches || matches.length === 0)
        return entityPopulatedRoutes.push({ ...routeProperty, url: routeProperty.url });

      (selectedEntity ? [selectedEntity.type] : supportedEntities).forEach(entity => {
        const entities = selectedEntity ? [selectedEntity] : this[`${entity}s`];

        entities?.forEach(entityObj => {
          if (!routeProperty.url.includes(`:${entity}Id`)) return;

          const entityRoute = { 
            ...routeProperty,
            url: routeProperty.url.replace(`:${entity}Id`, entityObj.id),
            searchSelector: entityObj.name + " " + routeProperty.searchSelector + " " + entityObj.name,
            contextName: entityObj.name,
            params: { ...(routeProperty.params ?? {}), [`${entity}Id`]: entityObj.id },
            context: { ...entityObj, entityType: entity },
            contextType: entity
          };

          if (!this.checkRoutePermission(routeProperty[`${entity}Role`], entityObj.role, entity, entityRoute.params)) return;

          if (entityObj.parentEntities?.length) {
            entityObj.parentEntities.forEach(parentEntity => {
              if (
                !entityRoute.url?.includes(`:${parentEntity.type}Id`) ||
                !this[`${parentEntity.type}s`].find(entity => entity.id === parentEntity.id)
              ) return;

              const childEntityRoute = {
                ...entityRoute,
                url: entityRoute.url.replace(`:${parentEntity.type}Id`, parentEntity.id),
                parentId: parentEntity.id,
                parentType: parentEntity.type,
                parentContextName: parentEntity.name,
                params: { ...entityRoute.params, [`${parentEntity.type}Id`]: parentEntity.id }
              };

              if (!this.checkRoutePermission(routeProperty[`${parentEntity.type}Role`], parentEntity.role, parentEntity.type, childEntityRoute.params)) return;

              entityPopulatedRoutes.push(childEntityRoute);
            })
          } else
            entityPopulatedRoutes.push(entityRoute);
        });
      });
    })

    return entityPopulatedRoutes;
  }

  populateStaticParams(routes) {
    const staticParamPopulatedRoutes = [];

    routes.forEach((route => {  // For static parameters like noticeType
      const staticParams = Object.keys(staticParamValues);
      const matchedParam = route.url.match(new RegExp(staticParams.map(param => `(${param})`).join("|"), "g"))?.[0];
      if (!matchedParam)
        return staticParamPopulatedRoutes.push(route);
      
      staticParamValues[matchedParam].map(paramValue => {
        const entityRoute = {...route};
        const params = { ...entityRoute.params, [matchedParam]: paramValue }
        if (typeof entityRoute.title === "function") {
          entityRoute.title = entityRoute.title(params);
          entityRoute.searchSelector += " " + entityRoute.title;
        }

        entityRoute.url = entityRoute.url.replace(`:${matchedParam}`, paramValue);
        entityRoute.params = params

        staticParamPopulatedRoutes.push(entityRoute);
      });
    }));
    return staticParamPopulatedRoutes;
  }

  sanitizeRoutes(routes, selectedEntity) {
    return routes.filter(option => 
      !option.url.match(/(\/)*\:\w(\/)*/g)?.length &&
      (!option.title || typeof option.title === "string") &&
      (!selectedEntity || option.contextType)
    );
  }

  getSearchOptions(routes, selectedEntity) {
    const selectorPopulatedRoutes = this.populateBasicSearchSelectors(routes);
    const staticParamPopulatedRoutes = this.populateStaticParams(selectorPopulatedRoutes);
    const entityPopulatedRoutes = this.populateEntities(staticParamPopulatedRoutes, selectedEntity);
    const searchOptions = this.sanitizeRoutes(entityPopulatedRoutes, selectedEntity);

    return searchOptions;
  }

  buildFzf(routes, selectedEntity) {
    const searchOptions = this.getSearchOptions(routes, selectedEntity);

    const fzfObject = new Fzf(searchOptions, {
      selector: (item) => item.searchSelector,
      casing: "case-insensitive",
      sort: false
    });

    return fzfObject;
  }

  debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  };

  patchOverlay(e) {
    e.target.style.zIndex = 204;
    if (e.target.withBackdrop) {
      e.target.backdropElement.style.zIndex = 203;
      e.target.parentNode.insertBefore(e.target.backdropElement, e.target);
    }
  }

  skewAndSortResults(rawResults) {
    return rawResults
      .map(result => {
        const isEntitySelected = 
          result.item.context?.id &&
          result.item.context.id === this.selectedEntity?.id &&
          result.item.contextType === this.selectedEntity?.type;

        const isParentEntitySelected = 
          result.item.parentId &&
          result.item.parentId === this.selectedEntity?.id &&
          result.item.parentType === this.selectedEntity?.type;

        if (
          !result.item.context ||
          this.selectedEntity && (isEntitySelected || isParentEntitySelected)
        ) {
          result.score *= (1 + ENTITY_WEIGHT);
        }

        return result;
      })
      .sort((a, b) => {
        if (a.score === b.score) {
          for (let tiebreaker of getTieBreakers(this.selectedEntity)) {
            const diff = tiebreaker(a, b);
            if (diff !== 0) return diff;
          }
        }

        return b.score - a.score;
      })
  }

  updateSearchSuggestions(searchValue) {
    try {

      let rawSearchResults;

      if (!searchValue || searchValue === "") {

        if (this.selectedEntity)
          rawSearchResults = this.selectedEntityFzfObject.find("");
        else
          rawSearchResults = this.baseRoutesFzfObject.find("");

      } else {
        rawSearchResults = this.fzfObject.find(searchValue ?? "");
      }

      this.searchResults = this.skewAndSortResults(rawSearchResults).slice(0, RESULTS_SIZE).map(item => item.item);

    } catch (err) {
      console.log(err);
    }
  }

  async navigateToResult(suggestion) {
    track("Navigated via search", {
      query: this.shadowRoot?.getElementById("search-box")?.searchBarValue,
      title: suggestion.title ?? suggestion.name,
      url: suggestion.url
    });

    await this.updateStateContext(suggestion);

    this.updateSearchSuggestions("");
    store.dispatch(navigate(suggestion.key, suggestion.params));
  }

  renderResultItem(item) {
    let head = "";
    let subHead = "";

    if (!item.contextName || (!item.title && !item.name)) subHead = "";
    else if (item.parentContextName) subHead = item.contextName + " | " + item.parentContextName;
    else subHead = item.contextName;

    if (item.title || item.name)
      head = item.title ?? item.name;
    else if (item.parentContextName && item.contextName)
      head = item.contextName + " | " + item.parentContextName;
    else
      head = item.contextName ?? "";

    let icon;
    switch(item.contextType) {
      case "fund": 
        icon = "monetization_on";
        break;
      case "asset":
        icon = "apartment";
        break;
      case "investor":
        icon = "group";
        break;
      default:
        icon = "group";
        break;
    }

    if (item.iconProps?.type === "image") icon = html`<img class="image-icon" src=${item.icon} />`;
    else if (item.icon) icon = item.icon;

    return html`
      <style>
        .list-grid-container {
          display: grid;
          grid-template-columns: auto;
          grid-template-areas: "icon head" "icon sub-text";
          align-items: center;
        }

        .list-item-head {
          grid-area: head;
          font-size: 15px;
        }

        .list-item-subtext {
          grid-area: sub-text;
          font-size: 12px;
        }

        .icon {
          grid-area: icon;
          margin-right: 15px;
          font-size: var(--body-font-size);
        }

        .image-icon {
          height: var(--body-font-size);
          width: var(--body-font-size);
          filter: grayscale(1);
        }
      </style>
      <div class=${"list-grid-container"}>
        <mwc-icon class="icon" .value=${item}>${icon}</mwc-icon>
        <span class="list-item-head" .value=${item}>${head}</span>
        <span class="list-item-subtext" .value=${item}>${subHead}</span>
      </div>
    `
  }

}

customElements.define("search-bar", Search);
