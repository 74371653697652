import {
  FETCH_DEAL_USERS,
  FETCH_DEALS,
  FETCH_TEAMS
} from '../actions/deal.js';

const initialState = {
  deals: undefined,
  dealUsers: [],
  teams: undefined
}

const deal = (state = initialState,  action) => {

  switch (action.type) {

    case FETCH_DEALS:
      return {
        ...state,
        deals: action.data
      };

    case FETCH_TEAMS:
      return {
        ...state,
        teams: action.data
      };

    case FETCH_DEAL_USERS:
      return {
        ...state,
        dealUsers: action.data
      };

    default:
      return state;

  }

}

export default deal;
