
import mixpanel from 'mixpanel-browser';

export const track = (event, details) => {
  setTimeout(() => {
    const subscriptionType = localStorage.getItem('subscriptionType');
    if (Boolean(subscriptionType)) details = { ...details, subscriptionType };

    try {
      mixpanel.track(event, details);
    } catch (error) {
      console.warn("mixpanel tracking failed: ", error);
    }

    try {
      Appcues.track(event, details);
    } catch (error) {
      console.warn("appcues tracking failed: ", error);
    }

  }, 0);
};
