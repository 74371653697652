import { currentAssetSelector } from '../reducers/asset.js';
import { SubmissionAPI } from '@fundwave/api-client/src/submission';

export const UPDATE_SUBMISSION = 'UPDATE_SUBMISSION';

/** Status for saving submission for review. **/
export const SAVED_SUBMISSION = 'SAVED_SUBMISSION';
export const SAVE_SUBMISSION_FAIL = 'SAVE_SUBMISSION_FAIL';
export const SAVE_SUBMISSION = 'SAVE_SUBMISSION';

/** Status for deleting submission. **/
export const DELETED_SUBMISSION = 'DELETED_SUBMISSION';
export const DELETE_SUBMISSION_FAIL = 'DELETE_SUBMISSION_FAIL';
export const DELETE_SUBMISSION = 'DELETE_SUBMISSION';

/** Status for saving submission as draft. **/
export const DRAFT_SUBMISSION_FAIL = 'DRAFT_SUBMISSION_FAIL';
export const DRAFTED_SUBMISSION = 'DRAFTED_SUBMISSION';

export const RESET_SAVE_STATUS = 'RESET_SAVE_STATUS';
export const RESET_STATUS = 'RESET_STATUS';
export const RESET_SUBMISSION = 'RESET_SUBMISSION';

/** Status for fetching submission. **/
export const FETCHED_SUBMISSION = 'FETCHED_SUBMISSION';
export const UPDATE_FETCH_STATUS = 'UPDATE_FETCH_STATUS';
export const FETCH_SUBMISSION = 'FETCH_SUBMISSION';
export const FETCH_SUBMISSION_FAIL = 'FETCH_SUBMISSION_FAIL';


export const resetStatus = () => {
  return {
    type: RESET_STATUS
  };
}

export const resetSubmission = () => {
  return {
    type: RESET_SUBMISSION
  };
}

export const updateSubmission = (submission) => {
  return {
    type: UPDATE_SUBMISSION,
    submission
  };
}

export const updateFetchStatus = (status) => {
  return {
    type: UPDATE_FETCH_STATUS,
    status
  };
}

/**  API calls follow. **/

// Submit submission.
export const submitSubmission = () => (dispatch, getState) => {
  const state = getState();
  const asset = currentAssetSelector(state);
  const submission = state.submission.submission;
  dispatch( { type: SAVE_SUBMISSION } )
  SubmissionAPI.submitSubmission(submission, asset.id)
  .then(data => {
    if(data == null) dispatch({ type: RESET_SAVE_STATUS})
    else dispatch( {type: SAVED_SUBMISSION, data})
  }).catch ( () => dispatch({ type: SAVE_SUBMISSION_FAIL}) )
};

// Delete submission
export const deleteSubmission = (submission) => (dispatch, getState) => {
  dispatch({ type: DELETE_SUBMISSION });
  SubmissionAPI.deleteSubmission(submission.id)
  .then( (resp) => {
    if(resp == null) dispatch({ type: RESET_SAVE_STATUS})
    else dispatch({ type: DELETED_SUBMISSION })})
  .catch ( () => dispatch({ type: DELETE_SUBMISSION_FAIL }))
};

// Update submission status based on user's response.
export const updateSubmissionStatus = (submission, statusId, commentId, sendMail) => (dispatch, getState) => {

  SubmissionAPI.updateSubmissionStatus(submission.id,statusId,commentId,sendMail)
  .then(data => {
    if(data == null) dispatch({ type: RESET_SAVE_STATUS})
    else dispatch({ type: SAVED_SUBMISSION, data })
   })
   .catch (() => dispatch({ type: SAVE_SUBMISSION_FAIL }))
};


// Fetch latest submission for deliverable request.
export const fetchSubmissionForDeliverableRequest = (deliverableRequestId) => (dispatch, getState) => {

      if(deliverableRequestId) {
        dispatch({ type: FETCH_SUBMISSION });
        SubmissionAPI.fetchSubmissionForDeliverableRequest(deliverableRequestId)
        .then(data => {
          dispatch({ type: FETCHED_SUBMISSION, data })
          })
        .catch ( () => dispatch({ type: FETCH_SUBMISSION_FAIL }))
      }
};
