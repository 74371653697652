import { LitElement, html } from "lit";
import { connect } from "pwa-helpers/connect-mixin.js";
import { fundRouteGroups, allFundRouteGroups, investorRouteGroups, allFundInvestorRouteGroups, routeProperties, assetRouteGroups } from "../constants.js";
import { store } from "../store.js";
import { AppDrawerStyles } from "@fundwave/styles/app-drawer-styles.js";
import "@polymer/iron-icons/iron-icons.js";
import "@polymer/iron-icons/av-icons.js";
import "@polymer/iron-icons/social-icons.js";
import "@polymer/iron-icons/communication-icons.js";
import "@polymer/iron-icons/maps-icons.js";
import "@polymer/iron-icons/editor-icons.js";
import "@material/mwc-icon";
import { getUserRoles, navigate } from "../actions/app.js";
import { UserUtil } from "@fundwave/ui-utils/src/UserUtil.js";
import { isSameObject } from "@fundwave/ui-utils/src/parse-util.js";

class MyAppDrawer extends connect(store)(LitElement) {
  render() {
    return html`
      ${AppDrawerStyles}
      <style>
        .drawer-item {
          cursor: pointer;
        }
        .drawer-item-floating {
            width: 52%;
            margin: auto;
            display: flex;
            justify-content: center;
            border: 1px solid transparent;
            border-radius: 50px;
            background: #ecdd0fb0;
            /* margin-top: 24rem; */
        }
        .drawer-item-floating:hover, .drawer-item-floating[active]{
          background-color: #b8ae21 !important;
        }
        .drawer-floating-group{
          position: absolute;
          bottom: 90px;
          width:100%;
        }

        .imageIcon {
          grayscale: 1;
          width: calc(var(--body-font-size) + 5px);
          height:calc(var(--body-font-size) + 5px);
          filter: grayscale(1);
          margin-right: 5%;
        }
      </style>

      <div class="drawer">
        ${this.modalDrawer
          ? html`
              <div class="drawer-header">
                <img .src="${this.logoUrl}" @error=${(e) => this.onImageError(e)} id="fund-logo-container" class="header-img" />
              </div>
            `
          : html` <mwc-icon class="toggle-icon" @tap=${(e) => this.fireEvent("toggleDrawer")}>${this.drawerOpen ? "keyboard_arrow_left" : "keyboard_arrow_right"}</mwc-icon> `}

        <div class="drawer-content" ?hiddenDrawer=${!this.drawerOpen} active>
          ${this._selectedRouteGroups?.map(
            (routeGroup) => {
              if(routeGroup.type != "floating")
                return  html`
                    <a class="drawer-item" ?active="${this.isRouteGroupActive(routeGroup)}" @tap=${(e) => this._navigate(routeGroup)}>
                      ${routeGroup.iconProps?.type === "image" ? 
                        html `<img src='${routeGroup.icon}' style="opacity: ${routeGroup.iconProps?.opacity??0.75};" class="imageIcon"/>`
                        : 
                        html `<mwc-icon class="drawer-icon">${routeGroup.icon}</mwc-icon>`
                      }
                      <span class="drawer-item-title">${routeGroup.name}</span>
                    </a>
                `}
          )}

          <div class="drawer-floating-group">
          ${this._selectedRouteGroups?.map(
            (routeGroup) => {
              if(routeGroup.type === "floating")
                return  html`
                    <a class="drawer-item drawer-item-floating" ?active="${this.isRouteGroupActive(routeGroup)}" @tap=${(e) => this._navigate(routeGroup)}>
                      ${routeGroup.iconProps?.type === "image" ? 
                        html `<img src='${routeGroup.icon}' style="opacity: ${routeGroup.iconProps?.opacity??0.75};" class="imageIcon"/>`
                        : 
                        html `<mwc-icon class="drawer-icon">${routeGroup.icon}</mwc-icon>`
                      }
                      <span class="drawer-item-title">${routeGroup.name}</span>
                    </a>
                `}
          )}</div>
        </div>
      </div>
    `;
  }

  static get properties() {
    return {
      activePath: Object,
      fund: Object,
      funds: Array,
      modalDrawer: Boolean,
      drawerOpen: Object,
      logoUrl: Object,
      investor: Object,
      investors: Array,
      asset: Object,
      assets: Array,
      user: Object,
      fundRole: Object,
      params: Object,
      _selectedRouteGroups: Object,
    };
  }

  updated(changedProps) {
    if (changedProps.has("fund") || changedProps.has("funds") || changedProps.has("investor") || changedProps.has("asset") || changedProps.has("user") || changedProps.has("fundRole") || changedProps.has("investors") || changedProps.has("assets")) {
      this._selectRouteGroup();
    }
    if (changedProps.has("_selectedRouteGroups")) {
      this.fireEvent("routeGroupChanged", this._selectedRouteGroups);
    }
  }

  stateChanged(state) {
    this.fund = state.fund.fund;
    this.funds = state.fund.fundsOfUser;
    this.fundRole = state.fund.userFundRole;
    this.investor = state.investor?.investor;
    this.investors = state.investor?.investors;
    this.asset = state.asset?.asset;
    this.assets = state.asset?.assetsOfFund;
    this.user = state.user?.user;
    this.params = state.router.params;
  }

  _selectRouteGroup() {
    let selectedRouteGroups = allFundRouteGroups;
    if (this.investor) {
      selectedRouteGroups = this.fund ? investorRouteGroups : allFundInvestorRouteGroups;
    } else if (this.asset) {
      selectedRouteGroups = assetRouteGroups;
    } else {
      selectedRouteGroups = this.fund ? fundRouteGroups : allFundRouteGroups;
    }
    this._selectedRouteGroups = selectedRouteGroups
      ?.map((routeGroup) => {
        let filteredRouteGroup = { ...routeGroup };
        filteredRouteGroup.routes = routeGroup.routes.filter((route) => {
          if (routeProperties[route].required && !routeProperties[route].required.evaluate({ asset: this.assets?.length, investor: this.investors?.length })) return false;
          if (routeProperties[route].userRole) return getUserRoles(this.user?.role?.role)?.includes(routeProperties[route].userRole);
          else if (routeProperties[route].fundRole && this.fundRole) {
            let routeFundRole = routeProperties[route].fundRole;
            if(routeProperties[route].fundRole instanceof Function) routeFundRole = routeProperties[route].fundRole(this.params);
            return UserUtil.fundRoleChain[routeFundRole]?.includes(this.fundRole);
          }
          else return true;
        });
        return filteredRouteGroup;
      })
      .filter((routeGroup) => {
        if (routeGroup.required && !routeGroup.required.evaluate({ fund: this.funds?.length })) return false;
        return Boolean(routeGroup.routes?.length)
      });

  }

  isRouteGroupActive(routeGroup) {
    if (!routeGroup.routes?.includes(this.activePath)) return false;
    let routeGroupParams = routeGroup?.params?.[this.activePath];
    if (routeGroupParams) {
      for (let key in routeGroupParams) 
        if (!isSameObject(routeGroupParams[key], this.params[key])) return false;
    }
    return true;
  }

  _navigate(routeGroup) {
    store.dispatch(navigate(routeGroup?.routes[0], routeGroup?.params?.[routeGroup?.routes[0]]));
  }

  fireEvent(eventName, detail) {
    this.dispatchEvent(new CustomEvent(eventName, { detail, bubbles: true, composed: true }));
  }

  onImageError(e) {
    const defaultFundLogo = "node_modules/@fundwave/images/brand/logoFundwave.png";
    e.target.src = defaultFundLogo;
  }
}

customElements.define("app-drawer", MyAppDrawer);
