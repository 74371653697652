import { routes as portfolioRoutes, routeProperties as portfolioRoutesProperties } from "../portfolio/constants.js";

export const externalURLs = {
  "academy": "https://fundwave.com/guide/academy/",
  "guide": "https://fundwave.com/support/#docs",
  "blog": "https://fundwave.com/blogs/?view=general",
  "dealflow": "https://dealflow.app",
  "onboardingCall": "https://calendly.com/fundwave-onboarding/onboarding-call",
  "fwFunctions": "https://appsource.microsoft.com/en-us/product/office/wa200002923?tab=overview",
  "support": "https://fundwave.freshdesk.com/support/tickets/new",
  "help": "https://fundwave.com/guide/fund-administration-software"
}

export const guideURLs = {
  investorPortal: "https://fundwave.com/guide/fund-administration-software/Investor+Portal",
  fundPolicies: "https://fundwave.com/guide/fund-administration-software/Key+fund+policies",
  funds: "https://fundwave.com/guide/fund-administration-software/Create+a+fund",
  investors: "https://fundwave.com/guide/fund-administration-software/Investors",
  fundDetails: "https://fundwave.com/guide/fund-administration-software/Getting+Started",
  kpis: "https://fundwave.com/guide/fund-administration-software/KPIs",
  portfolioAnalysis: "https://fundwave.com/guide/fund-administration-software/Analysis",
  createAsset: "https://fundwave.com/guide/fund-administration-software/Create+Asset",
  importTransactions: "https://fundwave.com/guide/fund-administration-software/Import+Your+Portfolio+Transactions",
  assets: "https://fundwave.com/guide/fund-administration-software/Assets"
}

export const routes = {
  home: "home",
  accounting: "accounting-summary",
  portfolioSummary: "all-fund-investor-portfolio",
  allocationSettings: "allocation-settings",
  allocationKey: "allocation-key",
  bankImport: "bank-import",
  integrations: "integrations",
  accountingSettings: "accounting-settings",
  allocationDashboard: "allocation-dashboard",
  customDashboard: "custom-dashboard",
  dashboard: "dashboard",
  fundMetricDashboard: "fund-metric-dashboard",
  transactions: "transaction-dashboard",
  importTransactions: "import-transactions",
  importPreview: "import-preview",
  feed: "update-feed",
  fundBank: "fund-bank",
  fundContact: "fund-contact",
  fundSummary: "fund-summary",
  investorBank: "investor-bank",
  investorContact: "investor-contact",
  investorHome: "investor-home",
  investorBalances: "investor-balances",
  portalSplash: "portal-splash",
  investorKYC: "kyc",
  investorDashboard: "fund-investor-dashboard",
  investorSummary: "investor-summary",
  importDashboard: "import-dashboard",
  importInvestors: "import-investors",
  importInvestorTeam: "import-investor-team",
  journal: "journal",
  notice: "notice",
  noticeApp: "notice-app",
  waterfallPolicy: "waterfall-policy",
  equalizationPolicy: "equalization-fee-policy",
  managementPolicy: "management-fee-policy",
  onboarding: "onboarding",
  portfolio: "portfolio",
  dealflow: "dealflow",
  preview: "notice-preview",
  report: "report",
  stockDistribution: "stock-distribution",
  transfer: "notice-transfer",
  notificationSettings: "notification-settings",
  watcherSettings: "watcher-settings",
  exchangeRate: "exchange-rate",
  canvas: "canvas",
  // portal-admin
  deliverableGenerator: "deliverable-generator",
  deliverableRequestList: "deliverable-request-list",
  metricGrid: "metric-grid",
  // dataroom
  groupFilesList: "group-files-list",
  uploadFiles: "upload-files",
  fileGroups: "file-groups",
  mfa: "multi-factor",
  unknown: "view-404",
  ...portfolioRoutes,
};

export const routeProperties = {
  [routes.home]: {
    name: "Home",
    title: "Home",
    urls: ["/"],
    searchPriority: 2
  },
  [routes.accounting]: {
    name: "Trial balance",
    title: "Accounting",
    urls: ["/:fundId/trialbalance"],
    fundRole: "VIEWER",
    required: {
      entities: ["asset", "investor"],
      operand: "or"
    }
  },
  [routes.portfolioSummary]: {
    title: "Portfolio Summary",
    urls: ["/investor/:investorCode/portfolio", "/:fundId/investor/:investorId/portfolio", "/:fundId/portfolio/summary", "/portfolio/summary"],
    parentRoute: (params) => {
      if (!params.investorCode && !params.investorId) return routes.portfolio;
    },
    fundRole: (params) => {
      if (params.investorId || params.investorCode) return "VIEWER";
      else if (params.fundId) return "PORTFOLIO_VIEWER";
      else return null;
    },
    keywords: ["Table view"]
  },
  [routes.allocationSettings]: {
    title: "Transactions | Settings",
    name: "Transactions",
    urls: ["/:fundId/settings/transaction"],
    fundRole: "VIEWER",
    required: {
      entities: ["asset", "investor"],
      operand: "or"
    }
  },
  [routes.allocationKey]: {
    title: "Allocation Key",
    urls: ["/:fundId/allocation-key", "/:fundId/allocation-key/:allocationKeyId"],
    fundRole: "VIEWER"
  },
  [routes.bankImport]: {
    title: "Bank Accounts",
    urls: ["/:fundId/bank-import"],
    fundRole: "VIEWER",
    required: {
      entities: ["asset", "investor"],
      operand: "or"
    }
  },
  [routes.integrations]: {
    title: "Integrations",
    urls: ["/integrations"],
  },
  [routes.accountingSettings]: {
    title: "Accounting | Settings",
    name: "Accounting",
    urls: ["/:fundId/settings/accounting"],
    fundRole: "VIEWER",
    required: {
      entities: ["asset", "investor"],
      operand: "or"
    }
  },
  [routes.customDashboard]: {
    title: "Charts",
    urls: ["/charts"],
    keywords: ["Custom Dashboard"],
    icon: "table_view"
  },
  [routes.dashboard]: {
    keywords: ["Dashboard", "Home"],
    urls: ["/:fundId/"],
    searchPriority: 1
  },
  [routes.dealflow]: {
    title: "Dealflow",
    urls: ["/dealflow", ":fundId/dealflow"],
  },
  [routes.fundMetricDashboard]: {
    title: "Fund Performance",
    name: "Performance",
    urls: ["/:fundId/summary"],
    parentRoute: routes.dashboard,
    icon: "insights"
  },
  [routes.transactions]: {
    title: "Transactions",
    urls: ["/:fundId/transactions"],
    fundRole: "VIEWER",
    required: {
      entities: ["asset", "investor"],
      operand: "or"
    }
  },
  [routes.importTransactions]: {
    name: "Transactions",
    title: "Import Transactions",
    urls: ["/:fundId/import/transactions"],
    fundRole: "INITIATOR",
    parentRoute: routes.importDashboard,
  },
  [routes.feed]: {
    title: "Feed",
    urls: ["/:fundId/feed"],
    parentRoute: routes.dashboard,
    fundRole: "VIEWER"
  },
  [routes.fundBank]: {
    title: "Bank Accounts",
    urls: ["/:fundId/bank"],
    parentRoute: routes.dashboard,
    fundRole: "VIEWER"
  },
  [routes.fundContact]: {
    title: "Contact Details",
    urls: ["/:fundId/contact"],
    parentRoute: routes.dashboard,
    fundRole: "VIEWER",
    icon: "menu_book"
  },
  [routes.fundSummary]: {
    title: "Funds",
    urls: ["/funds"],
    icon: "equalizer"
  },
  [routes.investorBank]: {
    title: "Bank",
    urls: ["/:fundId/investor/:investorId/bank"],
    fundRole: "VIEWER",
    parentRoute: routes.investorHome,
  },
  [routes.investorContact]: {
    title: "Contact",
    urls: ["/:fundId/investor/:investorId/contact"],
    fundRole: "VIEWER",
    parentRoute: routes.investorHome,
    icon: "menu_book"
  },
  [routes.investorHome]: {
    urls: ["/:fundId/investor/:investorId/dashboard"],
    fundRole: "VIEWER",
    keywords: ["Dashboard", "Home"],
    searchPriority: 3
  },
  [routes.investorBalances]: {
    title: "Balances",
    urls: ["/:fundId/investor/:investorId/balances"],
    fundRole: "VIEWER",
    parentRoute: routes.investorHome,
  },
  [routes.investorKYC]: {
    title: "KYC",
    urls: ["/:fundId/investor/:investorId/kyc"],
    fundRole: "VIEWER",
    parentRoute: routes.investorHome,
  },
  [routes.importDashboard]: {
    name: "Import",
    title: "Imports",
    urls: ["/:fundId/imports"],
    fundRole: "PORTFOLIO_MANAGER"
  },
  [routes.importInvestors]: {
    name: (params) => {
      const title = !Boolean(params?.fundId) ? "Import " : "";
      if (params?.importType === "add") return title.concat("Investors");
      else if (params?.importType === "contact") return title.concat("Contact Details");
      else if (params?.importType === "bank") return title.concat("Bank Details");
    },
    title: (params) => {
      const title = !Boolean(params?.fundId) ? "Import " : "";
      if (params?.importType === "add") return title.concat("Investors");
      else if (params?.importType === "contact") return title.concat("Contact Details");
      else if (params?.importType === "bank") return title.concat("Bank Details");
    },
    urls: ["/import/investors/:importType", "/:fundId/import/investors/:importType"],
    fundRole: "INITIATOR",
    parentRoute: (params) => Boolean(params.fundId) ? routes.importDashboard : null,
  },
  [routes.importPreview]: {
    name: "Import",
    title: "Import Preview",
    urls: ["/:fundId/import/preview/:importId"],
    fundRole: "INITIATOR",
  },
  [routes.investorDashboard]: {
    title: "Investors",
    urls: ["/investors", "/:fundId/investors"],
    fundRole: "VIEWER",
    searchPriority: 3,
  },
  [routes.investorSummary]: {
    title: "Investor Summary",
    urls: ["/:fundId/investors/summary"],
    parentRoute: routes.investorDashboard,
    fundRole: "VIEWER",
    keywords: ["Balances", "Investor Balances"]
  },
  [routes.importInvestorTeam]: {
    name: "Investor Contact",
    title: "Import Investor Contacts",
    urls: ["/:fundId/import/investor/contact", "/:fundId/:investorId/import/contact"],
    fundRole: "INITIATOR",
    parentRoute: (params) => {
      if (params.investorId) return routes.investorHome;
      else return routes.importDashboard;
    },
  },
  [routes.journal]: {
    title: "",
    urls: ["/:fundId/journal/:journalID"],
    fundRole: "VIEWER"
  },
  [routes.notice]: {
    title: "Notices",
    urls: ["/:fundId/notice"],
    parentRoute: routes.investorDashboard,
    fundRole: "VIEWER",
    keywords: ["Capital calls", "Settlements", "Distributions"]
  },
  [routes.noticeApp]: {
    title: (params) => {
      if (params?.noticeType == "close") return "Investor Close";
      return "Capital Call";
    },
    urls: ["/:fundId/notice/:noticeType", "/:fundId/notice/:noticeType/:noticeGroupId"],
    parentRoute: routes.notice,
    fundRole: "INITIATOR"
  },
  [routes.waterfallPolicy]: {
    title: "Waterfall Policy",
    urls: ["/:fundId/policy/waterfall", "/:fundId/:investorId/policy/waterfall"],
    parentRoute: (params) => {
      if (params.investorId) return routes.investorHome;
      else return routes.investorDashboard;
    },
    fundRole: "VIEWER"
  },
  [routes.equalizationPolicy]: {
    title: "Equalization Policy",
    urls: ["/:fundId/policy/equalization", "/:fundId/:investorId/policy/equalization"],
    parentRoute: (params) => {
      if (params.investorId) return routes.investorHome;
      else return routes.investorDashboard;
    },
    fundRole: "VIEWER"
  },
  [routes.managementPolicy]: {
    title: "Management Fees Policy",
    urls: ["/:fundId/policy/management", "/:fundId/:investorId/policy/management"],
    parentRoute: (params) => {
      if (params.investorId) return routes.investorHome;
      else return routes.investorDashboard;
    },
    fundRole: "VIEWER"
  },
  [routes.onboarding]: {
    title: "Onboarding",
    urls: ["/onboarding"],
    skipGlobalSearch: true
  },
  [routes.portfolio]: {
    title: "Portfolio",
    urls: ["/:fundId/portfolio", "/portfolio"],
    keywords: ["Assets"],
    searchPriority: 2
  },
  [routes.preview]: {
    urls: ["/:fundId/notice-preview/:noticeGroupId"],
    parentRoute: routes.notice,
    fundRole: "VIEWER"
  },
  [routes.report]: {
    title: "DIY Reports",
    urls: ["/report", "/report/:reportGroupId", "/:fundId/report", "/:fundId/report/:reportGroupId"],
    fundRole: "VIEWER",
  },
  [routes.stockDistribution]: {
    name: "Stock Distribution",
    urls: ["/:fundId/stock-distribution", "/:fundId/stock-distribution/:noticeGroupId"],
    parentRoute: routes.notice,
    fundRole: "VIEWER"
  },
  [routes.transfer]: {
    name: "Investor Transfer",
    urls: ["/:fundId/transfer", "/:fundId/transfer/:noticeGroupId"],
    parentRoute: routes.notice,
    fundRole: "VIEWER"
  },
  [routes.unknown]: {
    title: "404",
    urls: ["*"],
    skipGlobalSearch: true
  },
  [routes.notificationSettings]: {
    title: "Notifications | Settings",
    name: "Notifications",
    urls: ["/:fundId/settings/notification"],
    fundRole: "VIEWER",
    required: {
      entities: ["asset", "investor"],
      operand: "or"
    }
  },
  [routes.watcherSettings]: {
    title: "Templates | Settings",
    name: "Templates",
    urls: ["/settings/watcher"]
    // fundRole: "VIEWER"
  },
  // admin view
  [routes.deliverableGenerator]: {
    title: "Create Deliverable Request",
    urls: ["/:fundId/deliverable/:requestMode"],
    fundRole: "ADMIN",
  },
  [routes.deliverableRequestList]: {
    title: "Metrics",
    urls: ["/:fundId/deliverables", "/:fundId/:investorId/deliverables"],
    parentRoute: (params) => {
      if (params.investorId) return routes.investorHome;
      else return routes.investorDashboard;
    },
    fundRole: "VIEWER"
  },
  [routes.metricGrid]: {
    urls: ["/:fundId/metrics/:metricId", "/:fundId/:investorId/metrics/:metricId"],
    fundRole: "ADMIN",
    parentRoute: (params) => {
      if (params.investorId) return routes.investorHome;
      else return routes.deliverableRequestList;
    },
  },
  [routes.fileGroups]: {
    title: "Files",
    urls: ["/:fundId/documents", "/:fundId/:investorId/documents"],
    parentRoute: (params) => {
      if (params.investorId) return routes.investorHome;
      else return routes.investorDashboard;
    },
    fundRole: "VIEWER"
  },
  [routes.groupFilesList]: {
    urls: ["/:fundId/documents/:fileGroupId", "/:fundId/:investorId/documents/:fileGroupId"],
    parentRoute: routes.fileGroups,
    fundRole: "VIEWER",
    parentRoute: (params) => {
      if (params.investorId) return routes.investorHome;
      else return routes.fileGroups;
    }
  },
  [routes.uploadFiles]: {
    title: "Upload",
    urls: ["/:fundId/upload/documents", "/:fundId/:investorId/upload/documents"],
    fundRole: "ADMIN",
    parentRoute: (params) => {
      if (params.investorId) return routes.investorHome;
      else return routes.fileGroups;
    }
  },
  [routes.portalSplash]: {
    urls: ["/:fundId/portal/:portalType", "/portal/:portalType"],
    title: (params) => {
      if (params?.portalType === "investor") return "Investor View";
      else if(params?.portalType === "asset") return "Company View";
      else if(params?.portalType === "deal") return "Dealflow";
      else if(params?.portalType === "functions") return "Fundwave Functions";
    },
    parentRoute: (params) => {
      if (params?.portalType === "investor") return routes.investorDashboard;
      else if(params?.portalType === "asset") return routes.portfolio;
      else if(params?.portalType === "deal") return routes.dashboard;
    },
    fundRole: (params) => {
      if (params?.fundId && params?.portalType == "investor") return "VIEWER";
      else if (params?.fundId) return "PORTFOLIO_VIEWER";
      else return null;
    }
  },
  [routes.mfa]: {
    title: "Multi-Factor Authentication | Settings",
    name: "Multi-Factor Authentication",
    urls: ["/settings/mfa"],
    userRole: "admin",
  },
  [routes.allocationDashboard]: {
    title: "Allocations | Settings",
    name: "Allocations",
    urls: ["/:fundId/settings/allocations"],
    fundRole: "VIEWER",
    required: {
      entities: ["asset", "investor"],
      operand: "or"
    }
  },
  [routes.exchangeRate]: {
    title: "Exchange Rate",
    name: "Exchange Rate",
    urls: ["/:fundId/fxrate"],
    parentRoute: routes.importDashboard
  },
  [routes.canvas]: {
    title: "Canvas",
    name: "Canvas",
    urls: ["/:fundId/canvas", "/:fundId/asset/:assetId/canvas", "/asset/:assetId/canvas", "/:fundId/investor/:investorId/canvas"],
  },
  ...portfolioRoutesProperties,
  [routes.portfoliorequestOverview]: {
    ...portfolioRoutesProperties[routes.portfoliorequestOverview],
    parentRoute: (params) => {
      if (!params.assetId) return routes.portfolio;
    },
  },
  [routes.portfolioprojections]: {
    ...portfolioRoutesProperties[routes.portfolioprojections],
    parentRoute: routes.portfolio
  },
  [routes.portfolioportfolioSummary]: {
    ...portfolioRoutesProperties[routes.portfolioportfolioSummary],
    parentRoute: routes.portfolio
  },
  [routes.portfolioimportMetrics]: {
    ...portfolioRoutesProperties[routes.portfolioimportMetrics],
    parentRoute: routes.importDashboard
  },
  [routes.portfoliocoinvestors]: {
    ...portfolioRoutesProperties[routes.portfoliocoinvestors],
    parentRoute: routes.portfolio
  },

};

function evaluateEntitiesAgainstRequirements(state) {
  if (this.operand === "or") return this.entities.some(entity => state[entity]);
  else if (this.operand === "and") return this.entities.every(entity => state[entity]);
  else if (this.operand === "atmostOne") return this.entities.every(entity => state[entity] <= 1);
  return false;
}

Object.values(routeProperties).forEach(route => {
  if (route.required instanceof Object) route.required.evaluate = evaluateEntitiesAgainstRequirements;
});

export const linkedRoutes = [
  [routes.report, routes.customDashboard],
  [routes.allocationSettings, routes.mfa],
];

export const fundRouteGroups = [
  {
    name: "Dashboard",
    icon: "dashboard",
    routes: [routes.dashboard]
  },
  {
    name: "Investors",
    icon: "groups",
    routes: [routes.investorDashboard],
  },
  {
    name: "Portfolio",
    icon: "rocket_launch",
    routes: [routes.portfolio],
  },
  {
    name: "Accounts",
    icon: "account_balance_wallet",
    routes: [routes.accounting, routes.bankImport, routes.transactions],
  },
  {
    name: "Reports",
    icon: "menu_book",
    routes: [routes.report, routes.portalSplash],
    params: {
      [routes.portalSplash]: { portalType: "functions" }
    },
  },
  {
    name: "Import",
    icon: "file_upload",
    routes: [
      routes.importDashboard,
    ],
    type: "floating"
  },
  {
    name: "Settings",
    icon: "settings",
    routes: [routes.allocationSettings, routes.accountingSettings, routes.notificationSettings, routes.allocationDashboard]
    // dropdowns: [{ name: "List", routes: [routes.allocationDashboard, routes.configuration] }],
  },
];

export const allFundRouteGroups = [
  {
    name: "Home",
    icon: "home",
    routes: [routes.home],
  },
  {
    name: "Investors",
    icon: "groups",
    routes: [routes.investorDashboard],
    required: {
      entities: ["fund"],
      operand: "atmostOne"
    }
  },
  {
    name: "Portfolio",
    icon: "rocket_launch",
    routes: [routes.portfolio],
  },
  {
    name: "Dealflow",
    icon: "/node_modules/@fundwave/images/logos/dealflow-minimal.png",
    iconProps: {
      type: "image",
      opacity: 0.75
    },
    routes: [routes.dealflow],
    //routes: [routes.dealflow, routes.portalSplash],
  },
  {
    name: "Reports",
    icon: "menu_book",
    routes: [routes.report, routes.portalSplash],
    params: {
      [routes.portalSplash]: { portalType: "functions" }
    },
  },
  {
    name: "Integrations",
    icon: "store",
    routes: [routes.integrations],
  },
  {
    name: "Settings",
    icon: "settings",
    routes: [routes.mfa, routes.watcherSettings],
  },
];

export const investorRouteGroups = [
  {
    name: "Dashboard",
    icon: "dashboard",
    routes: [routes.investorHome],
  },
  {
    name: "Portfolio",
    icon: "rocket_launch",
    routes: [routes.portfolioSummary],
  },
  {
    name: "Canvas",
    icon: "description",
    routes: [routes.canvas],
  },
];

export const assetRouteGroups = [
  {
    name: "Cashflows",
    icon: "timeline",
    routes: [routes.portfoliotimeline, routes.portfolioinstruments],
  },
  {
    name: "Captable",
    icon: "donut_small",
    routes: [routes.portfoliorounds, routes.portfolioforecastRounds, routes.portfoliocaptableByInvestor, routes.portfolioexitWaterfall],
  },
  {
    name: "Monitoring",
    icon: "equalizer",
    routes: [routes.portfoliodashboard, routes.portfoliorequestOverview, routes.portfolioassetMetrics, routes.portfolioassetImportMetrics],
  },
  {
    name: "Feed",
    icon: "chat",
    routes: [routes.portfoliofeed],
  },
  {
    name: "Canvas",
    icon: "description",
    routes: [routes.canvas],
  },
  {
    name: "Files",
    icon: "folder",
    routes: [routes.portfoliofiles],
  },
  {
    name: "Details",
    icon: "account_balance_wallet",
    routes: [routes.portfolioassetDetails],
  },
];

export const allFundInvestorRouteGroups = [
  {
    name: "Portfolio",
    icon: "rocket_launch",
    routes: [routes.portfolioSummary],
  },
];

export const noMenuRouteGroups = [
  {
    name: "Import Transactions",
    icon: "account_balance_wallet",
    routes: [routes.importTransactions],
  },
];


Object.values(allFundRouteGroups).forEach(routeGroup => {
  if (routeGroup.required instanceof Object) routeGroup.required.evaluate = evaluateEntitiesAgainstRequirements;
})

export const headerDisabledRoutes = [
  routes.preview,
  routes.transfer,
  routes.allocationKey,
  routes.noticeApp,
  routes.investorHome,
  routes.investorContact,
  routes.investorBank,
  routes.metricGrid,
  routes.groupFilesList,
  routes.investorKYC,
  routes.deliverableGenerator,
  routes.portfolioimportPreview,
];

export const assetEnabledRoutes = [
  routes.portfoliopending,
  routes.portfoliohistory,
  routes.portfoliodashboard,
  routes.portfoliometricsOverview,
  routes.portfoliorequestOverview,
  routes.portfoliodeliverablePlanner,
  routes.portfoliofeed,
  routes.portfolioportfolioSummary,
  routes.portfoliopeople,
  routes.portfolioassetDetails,
  routes.portfoliorounds,
  routes.portfoliotimeline,
  routes.portfoliovaluationTimeline,
  routes.portfolioassetMetrics,
  routes.portfolioexitWaterfall,
  routes.portfoliocaptableByInvestor,
  routes.portfoliopostRealization,
  routes.portfoliopostIncome,
  routes.portfoliopostExpense,
  routes.portfolioinstruments,
  routes.portfoliounknown,
  routes.portfoliofiles,
  routes.portfolioprojections,
  routes.portfolioforecastRounds,
  routes.portfolioassetImportMetrics,
  routes.portfolioimportAssets,
  routes.portfolioimportTransactions,
  routes.canvas,
];

export const headerHiddenRoutes = [routes.stockDistribution];

export const allRouteGroups = [...allFundRouteGroups, ...fundRouteGroups, ...investorRouteGroups, ...allFundInvestorRouteGroups, ...noMenuRouteGroups, ...assetRouteGroups];
