import { APIClientOIDC } from "@fundwave/api-client";
import { GET_HEADERS } from '@fundwave/api-client/src/common.js';

export const FETCH_DEAL_USERS = 'FETCH_DEAL_USERS';
export const FETCH_DEALS = 'FETCH_DEALS';
export const FETCH_TEAMS = 'FETCH_TEAMS';

export const fetchDeals = () => (dispatch) => new Promise(async (resolve, reject) => {

    let options = {
        method: 'GET',
        credentials: 'include',
        headers: await APIClientOIDC.prepareHeaders(GET_HEADERS),
        timeout: 5000
    }

    let deals = await fetch(`${process.env.DF_SERVER_URL}/deals/all`, options)
    .then(resp => resp.json())
    .catch((error) => console.log(error));

    deals = deals ?? [];
    dispatch({type: FETCH_DEALS, data: deals });
    resolve();

});
  
export const fetchTeams = () => async (dispatch) => {
    const options = {
        method: 'GET',
        headers: await APIClientOIDC.prepareHeaders(GET_HEADERS),
        timeout: 5000
    };

    const teams = await fetch(`${process.env.DF_SERVER_URL}/team/boards`, options)
        .then(resp => resp.json())
        .catch((error) => console.log(error));

    return dispatch({type: FETCH_TEAMS, data: (teams ?? []) });
};
  
export const fetchDealUsers = () => (dispatch) => new Promise(async (resolve, reject) => {


    let options = {
        method: 'GET',
        credentials: 'include',
        headers: await APIClientOIDC.prepareHeaders(GET_HEADERS),
        timeout: 5000
      }

    let users = await fetch(`${process.env.DF_SERVER_URL}/users/all`, options)
    .then(resp => resp.json())
    .catch((error) => console.log(error)) ;

    users = users ?? [];
    dispatch({type: FETCH_DEAL_USERS, data: users });
    resolve();

});