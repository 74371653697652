import { UPDATE_TAGS } from "../actions/tag.js";

const tag = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_TAGS:
      return {
        ...state,
        tags: action.data,
      };

    default:
      return state;
  }
};

export default tag;
