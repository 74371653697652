import { LitElement, html } from 'lit';
import { setPassiveTouchGestures } from '@polymer/polymer/lib/utils/settings.js';
import { connect} from 'pwa-helpers/connect-mixin.js';
import { installMediaQueryWatcher } from 'pwa-helpers/media-query.js';
import { installRouter } from 'pwa-helpers/router.js';

import mixpanel from 'mixpanel-browser';

// These are the actions needed by this element.
import { fetchFundsOfUser, fetchUserFunds, updateFund, getFund} from '../actions/fund';
import { fetchTeam, fetchUser, fetchUserInvites } from '../actions/user.js';
import { closeMessageBar, updateLayout, getURLParameter, toggleDrawer, verifyPage, navigate, showMessageBar, verifyPagePermission, setServerVersion } from "../actions/app.js";

// We are lazy loading its reducers.
import app from '../reducers/app.js';
import fund from '../reducers/fund.js';
import user from '../reducers/user.js';
import investor from '../reducers/investor.js';
import router from '../reducers/router.js';
import updateTime from '../reducers/update.js';
import asset from '../reducers/asset.js';
import captable from '../reducers/captable.js';
import deal from '../reducers/deal.js';
import subscription from '../reducers/subscription.js';
import tag from '../reducers/tag.js';

//API
import { HttpDetails } from '@fundwave/api-client/src/http-details.js';
import { HttpDetails as OpenAPIHttpDetails } from '@fundwave/openapi-client/src/http-details';
import { HttpDetails as UserAPIHttpdetails } from '@fundwave/openapi-client/user/src/http-details';
import { HttpDetails as ActivityHttpDetails } from "@fundwave/openapi-client/activity/src/http-details";
import { HttpDetails as PredictionHttpDetails } from "@fundwave/openapi-client/prediction/src/http-details";
import { HttpDetails as ImportAPIHttpDetails } from "@fundwave/openapi-client/bulkImport/src/http-details";
import { HttpDetails as RequestScheduleHttpDetails } from '@fundwave/openapi-client/requestSchedule/src/http-details';
import { HttpDetails as LogicAPIHttpDetails } from '@fundwave/openapi-client/logicOperations/src/http-details';
import { HttpDetails as ChatbotAPIHttpDetails } from "@fundwave/openapi-client/chatbot/src/http-details";
import { HttpDetails as CanvasAPIHttpDetails } from "@fundwave/openapi-client/canvas/src/http-details";
import { FundAPI } from "@fundwave/api-client/src/fund";
import { ActivityOIDCClient } from "@fundwave/openapi-client/activity/src";
import { UserOIDCClient } from "@fundwave/openapi-client/user/src";
import { OIDCClient as OpenApiOIDCClient } from '@fundwave/openapi-client/src';
import { AddonOIDCClient } from '@fundwave/openapi-client/addon/src/index.js';
import { APIClientOIDC } from '@fundwave/api-client/src/index.js';
import { PredictionOIDCClient } from "@fundwave/openapi-client/prediction/src/index";
import { ImportOIDCClient } from "@fundwave/openapi-client/bulkImport/src/index";
import { RequestScheduleOIDCClient } from '@fundwave/openapi-client/requestSchedule/src';
import { LogicOIDCClient } from '@fundwave/openapi-client/logicOperations/src';
import { CaptableOIDCClient } from '@fundwave/openapi-client/captable/src';
import { CaptableHttpDetails } from '@fundwave/api-client/src/captable-http-details';
import { HttpDetails as CaptableOpenApiHttpDetails } from '@fundwave/openapi-client/captable/src/http-details';
import { HttpDetails as ExchangeRateHttpDetails } from '@fundwave/openapi-client/exchangeRate/src/http-details';
import { ExchangeRateOIDCClient } from '@fundwave/openapi-client/exchangeRate/src';
import { InstanceAPI } from '@fundwave/api-client/src/instance';
import { GlobalAPIClient } from "@fundwave/openapi-client/src";
import { ChatbotOIDCClient } from "@fundwave/openapi-client/chatbot/src";
import { CanvasOIDCClient } from "@fundwave/openapi-client/canvas/src";

import "./app.pollyfill.js";

store.addReducers({
  app,
  user,
  investor,
  fund,
  asset,
  router,
  updateTime,
  captable,
  deal,
  subscription,
  tag,
});

// This element is connected to the Redux store.
import { store } from '../store.js';

//Styles
import '../styles/custom-styles.css';
import { MyAppStyles } from "../styles/app-styles.js";
import { VaadinStyles } from "@fundwave/styles/vaadin-styles.js";
import { ShimmerStyles } from "@fundwave/images/shimmer/shared-styles";
import { AppStyles } from "@fundwave/styles/app-styles.js";

//Components
import './lit-routes.js';
import './page-header.js';
import './app-header.js';
import './app-drawer.js';
import "./onboarding/onboarding.js";
import '@fundwave/message-bar/src/message-bar.js';
import '@fundwave/fw-invites/src/fw-invites-modal.js';
import "./subscription-banner.js"

import { routes } from "../constants";
import { setDefaultMantissa } from "@fundwave/ui-utils/src/ValueFormatter";

import { fetchAllInvestors, fetchInvestorsOfFund, getInvestor, updateInvestor } from '../actions/investor';
import { fetchAllAssets, fetchAssets, fetchAssetsOfFund, getAsset, updateAssetById } from '../actions/asset.js';
import { fetchDealSubscription } from "../actions/subscription.js";
import { updateCaptableDetails } from "../actions/captable.js";
import { track } from '../usage-tracking';
import { Trackers } from "@fw-components/trackers";
import { fetchTags } from '../actions/tag';

//OIDC base url
const openIdBaseUrl = process.env.OCS_URL;
// const openIdPath = "fw-openidconnectconsumerserver-dynamic";

class App extends connect(store)(LitElement) {
  static get properties() {
    return {
      user: Object,
      team: Object,
      userInvites: Array,
      fund: Object,
      asset: Object,
      fundsOfUser: Object,
      fundSettings: Object,
      userFunds: Object,
      userFundRole: Object,
      activePath: Object,
      pathParams: Object,
      wideLayout: String,
      drawerOpen: String,
      messageBarOpened: Boolean,
      message: String,
      messageClass: String,
      messageURL: String,
      messageURLTarget: String,
      messageURLText: String,
      messageCustomEvent: String,
      messageCustomEventText: String,
      messageCustomEventData: Object,
      fundLogoUrl: String,
      loading: Boolean,
      authenticating: Boolean,
      assets: Array,
      investorsOfFund: Object,
      investor: Object,
      investorUpdateTime: Object,
      assetUpdateTime: Object,
      tracker: Object,
      hideDrawer: Boolean,
      isTrialUser: Boolean,
      subscriptionType: String
    };
  }

  render() {
    return html`
      ${VaadinStyles}
      ${ShimmerStyles}
      ${AppStyles}
      ${MyAppStyles}
      
      <div class="subscription-banner" >
        <subscription-banner
          style="display : ${this.fundsOfUser?.length ? "block" : "none"}"
          .user=${this.user}
          @updated-subscription=${(e) => this.subscriptionUpdated(e)}
          ></subscription-banner>
      </div>
      <div class="main-div" ?hideDrawer=${this.hideDrawer} ?openDrawer=${this.drawerOpen} ?active=${this.activePath !== routes.onboarding}>

          <div class="app-header">
            <app-header
              ?hide-drawer-toggle=${this.hideDrawer}
              .wideLayout=${this.wideLayout}
              .activePath=${this.activePath}
              .fund=${this.fund}
              .asset=${this.asset}
              .user=${this.user}
              .funds=${this.fundsOfUser}
              .userFunds=${this.userFunds}
              .logoUrl=${this.fundLogoUrl}
              .userInvites=${this.userInvites}
              .isTrialUser=${this.isTrialUser}
              @logout=${(e) => this.signOut(e)}
              @toggleDrawer=${() => this.toggleDrawer()}
              @open-activities=${() => this._navigateToActivities()}
              @open-invites-modal=${() => this._openInvitesModal()}
            ></app-header>
          </div>

          <div class="drawer-content" ?modal=${!this.wideLayout} ?open=${this.drawerOpen}>
            <app-drawer
              ?hidden=${this.hideDrawer}
              .activePath=${this.activePath}
              .drawerOpen=${this.drawerOpen}
              .modalDrawer=${!this.wideLayout}
              .logoUrl=${this.fundLogoUrl}
              @toggleDrawer=${() => this.toggleDrawer()}
              @routeGroupChanged=${(e) => this.routeGroupChanged(e.detail)}
            />
          </div>
          <div class="drawer-scrim" @tap=${(e) => this.drawerOpen = !this.drawerOpen}></div> <!-- Overlay for modal -->

          <div class="main-content">

          <div class="page">

            <div style="height: 70vh; margin: 3%; display: ${this.loading || this.authenticating ? "block" : "none"}">
              <div class="gradient" style="width :100%; height:15%;"></div><br>
              <div class="gradient" style="width :100%; height:15%;"></div><br>
              <div class="gradient" style="width :100%; height:15%;"></div><br>
              <div class="gradient" style="width :100%; height:15%;"></div><br>
            </div>

            <div style="display: ${!this.loading && !this.authenticating && (this.fundsOfUser && this.fundsOfUser.length > 0) ? "block" : "none"}">
              <page-header 
                class="page-header"
                .activePath=${this.activePath} 
                .wideLayout=${this.wideLayout} 
                @investor-updated=${(e) => this.changeSelectedInvestor(e)} 
                @asset-updated=${(e) => this.changeSelectedAsset(e)}
                @fundChanged=${(e)=> this.changeSelectedFund(e)}
              ></page-header>
              <lit-routes .tracker=${this.tracker} .visible=${!this.loading && !this.authenticating && (this.fundsOfUser && this.fundsOfUser.length) ? true:false} .version=${this.version}></lit-routes>
              <!-- 'visible' indicates child to create highcharts only after the parent becomes visible/non-hidden. Leads to chart reflow issues otherwise. -->
            </div>

            <div style="height: 70vh; margin: 3%; text-align:center; color:var(--secondary-color); font-size: var(--h2-font-size); display: ${!this.loading && !this.authenticating && !(this.fundsOfUser && this.fundsOfUser.length > 0) ? "block" : "none"}">
              <img src="node_modules/@fundwave/images/icons/empty.svg" style="max-height: 30vh"><br><br>
              You don't have access to any fund.
            </div>

          </div>

            <message-bar .messageBarOpened="${this.messageBarOpened}" .message="${this.message}" .messageClass="${this.messageClass}" .url=${this.messageURL} .target=${this.messageURLTarget} .urlText=${this.messageURLText} .customEvent=${this.messageCustomEvent} .customEventText=${this.messageCustomEventText} .customEventData=${this.messageCustomEventData} @close=${(e) => store.dispatch(closeMessageBar())}></message-bar>

            <div class="footer">
              <div style="text-align:center;background-color:transparent;">
                <span class="bottom-text-small">POWERED BY</span> <br>
                <a href="https://fundwave.com/" target="_blank"><img style="height:50px;" src="node_modules/@fundwave/images/brand/logoFundwave.png"></a>
              </div>
            </div>

          </div>

        </div>
        <onboarding-page class="onboarding-tab" ?active=${this.activePath === routes.onboarding}></onboarding-page>
        ${!this.loading && !this.authenticating && this.userInvites?.length ? html`
          <fw-invites-modal 
            .userInvites=${this.userInvites} 
            .opened=${this.userInvites?.some(invite => invite.expiry > new Date())}
            @invite-accepted=${this._fetchUserInvitesAndFunds}
            @invite-declined=${() => store.dispatch(fetchUserInvites(this.user.altUserName))}
            @accept-invite-failed=${() => store.dispatch(showMessageBar("We're having trouble accepting the invite", "error"))}
            @decline-invite-failed=${() => store.dispatch(showMessageBar("We're having trouble declining the invite", "error"))}
          ></fw-invites-modal>
        `: ``}
    `;
  }

  constructor() {
    super();
    document.addEventListener('logged-out',(e) => window.location = this.getLoginUrl());
    
    // To force all event listeners for gestures to be passive.
    // See https://www.polymer-project.org/2.0/docs/devguide/gesture-events#use-passive-gesture-listeners
    setPassiveTouchGestures(true);
    
    window['isUpdateAvailable']
    ?.then(updateAvailable => {
      console.log("App update available");
      if(updateAvailable) location.reload();
    });
    
   
  }


  async getCaptableDetails() {
    let captableDetails = await InstanceAPI.getCaptableDetails();
    store.dispatch(updateCaptableDetails(captableDetails));
    CaptableOpenApiHttpDetails.setBaseUrl(captableDetails.captableServerURL);
    CaptableHttpDetails.setBaseUrl(captableDetails.captableServerURL);
  }

  async getServerVersion() {
    await GlobalAPIClient.getServerVersion().then((version) => {
          store.dispatch(setServerVersion(version));
      }).catch((err) => {
        if(err.status == 404){
          console.log("Server version not found");
          store.dispatch(setServerVersion("0.0.0"));
        }
      });
  }

  firstUpdated(){
    //router
    installRouter(() => {
      window.dispatchEvent(new CustomEvent('route'));
    });

    //wide layout = screen width > 1025px.
    installMediaQueryWatcher(`(min-width: 1025px)`, (matches) => {
      store.dispatch(updateLayout(matches));
      this.drawerOpen = false;
    });
  }

  getChatbotUrl(){
    const url = localStorage.getItem("chatbot_url");
    if(url) return url;
    
    return process.env.CHATBOT_URL;
  }

  connectedCallback() {
    super.connectedCallback();

     //Set base url from local storage
     let teamUrl = localStorage.getItem('team');
     if(!teamUrl) teamUrl="";

     const predictionBaseUrl = process.env.PREDICTION_BASE_URL;
     const importBaseUrl = process.env.BULK_IMPORT_SERVICE_URL;
     const requestServiceBaseUrl = process.env.REQUEST_SERVICE_URL;
     const logicBaseUrl = process.env.LOGIC_SERVICE_URL;
     const exchangeRateServiceUrl = process.env.EXCHANGE_RATE_SERVICE_URL;
     const chatbotBaseUrl = this.getChatbotUrl();
     const canvasBaseUrl = process.env.CANVAS_SERVICE_URL;
     
     HttpDetails.setBaseUrl(teamUrl);
     OpenAPIHttpDetails.setBaseUrl(`${teamUrl}/services/openapi`);
     ActivityHttpDetails.setBaseUrl(`${teamUrl}/api/activity`);
     UserAPIHttpdetails.setBaseUrl(`${teamUrl}/api/user`);
     PredictionHttpDetails.setBaseUrl(predictionBaseUrl);
     ImportAPIHttpDetails.setBaseURL(importBaseUrl);
     RequestScheduleHttpDetails.setBaseUrl(requestServiceBaseUrl);
     LogicAPIHttpDetails.setBaseUrl(logicBaseUrl);
     ExchangeRateHttpDetails.setBaseUrl(exchangeRateServiceUrl);
     ChatbotAPIHttpDetails.setBaseUrl(chatbotBaseUrl);
     CanvasAPIHttpDetails.setBaseURL(canvasBaseUrl);

     const realm = localStorage.getItem("realm") || "fundwave";
     // const oidcBaseUrl = `${openIdBaseUrl}/${openIdPath}/${realm}`;
     const oidcBaseUrl = `${openIdBaseUrl}/${realm}`;

     OpenApiOIDCClient.setBaseUrl(oidcBaseUrl);
     ActivityOIDCClient.setBaseUrl(oidcBaseUrl);
     AddonOIDCClient.setBaseUrl(oidcBaseUrl);
     UserOIDCClient.setBaseUrl(oidcBaseUrl);
     APIClientOIDC.setBaseUrl(oidcBaseUrl);
     PredictionOIDCClient.setBaseUrl(oidcBaseUrl);
     ImportOIDCClient.setBaseUrl(oidcBaseUrl);
     RequestScheduleOIDCClient.setBaseUrl(oidcBaseUrl);
     LogicOIDCClient.setBaseUrl(oidcBaseUrl);
     CaptableOIDCClient.setBaseUrl(oidcBaseUrl);
     ExchangeRateOIDCClient.setBaseUrl(oidcBaseUrl);
     ChatbotOIDCClient.setBaseUrl(oidcBaseUrl);
     CanvasOIDCClient.setBaseUrl(oidcBaseUrl);

     this.getServerVersion();
     this.getCaptableDetails();

     this.loading = true;
     this.authenticating = true;
     this.hideDrawer = false;

     store.dispatch(fetchTeam());
     store.dispatch(fetchUser());
     store.dispatch(fetchAssets());
     store.dispatch(fetchFundsOfUser()); //ListOfFunds which user has access to.
     store.dispatch(fetchUserFunds()); //ListOfUserFund including - userId, fundId, fundRole.
     store.dispatch(fetchDealSubscription());
     store.dispatch(fetchTags());
     this.initializeTrackers();

  }

  stateChanged(state) {
    this._state_ = state;
    this.user = state.user.user;
    this.team = state.user.team;
    this.userFunds = state.fund.userFunds;
    this.userInvites = state.user.userInvites;
    this.fundsOfUser = state.fund.fundsOfUser;
    this.assets = state.asset.assets;
    this.asset = state.asset.asset;
    this.investorsOfFund = state.investor.investors;
    this.investorUpdateTime = state.updateTime.investor;
    this.assetUpdateTime = state.updateTime.asset;
    this.fund = state.fund.fund;
    this.userFundRole = state.fund.userFundRole;
    this.investor = state.investor.investor;
    this.fundSettings = state.fund.fundSettings;
    this.activePath = state.router.activeRoute;
    this.pathParams = state.router.params;
    this.wideLayout = state.app.wideLayout;
    this.messageBarOpened = state.app.messageBarOpened;
    this.message = state.app.message;
    this.messageClass = state.app.messageClass;
    this.messageURL= state.app.messageURL;
    this.messageURLTarget = state.app.messageURLTarget;
    this.messageURLText = state.app.messageURLText;
    this.messageCustomEvent = state.app.messageCustomEvent;
    this.messageCustomEventText = state.app.messageCustomEventText;
    this.messageCustomEventData = state.app.messageCustomEventData;
    this.version = state.app.version;
  }

  updated(changedProps){
    //Close the drawer whenever path changes
    if(changedProps.has('activePath') && !this.wideLayout){
      if(this.drawerOpen) this.drawerOpen = false;
    }

    if(changedProps.has('activePath')){
      this.pageChanged();
    }

    if(changedProps.has('drawerOpen')){
      window.dispatchEvent(new Event('resize'));
    }

    if(changedProps.has('user') && this.user){
      if (this.user.role?.role === "investor") {
        const teamUrl = localStorage.getItem("team");
        window.location.href = process.env.PORTAL_APP_URL ?? `${teamUrl}/portal`;
      }
    }

    if((changedProps.has('user') || changedProps.has('team') || changedProps.has("fund") || changedProps.has("investor") || changedProps.has("asset")) && this.user ){
      this.identifyUser();
    }

    if((changedProps.has('team') || changedProps.has('subscriptionType')) && this.team){
      this.identifyUserGroup();
    }

    if((changedProps.has("isTrialUser") || changedProps.has("wideLayout")) && this.wideLayout && this.isTrialUser){
      this.drawerOpen = true;
    }

    if(changedProps.has('user') && this.user){
      store.dispatch(fetchUserInvites(this.user.altUserName));
    }

    if((changedProps.has('fundsOfUser') || changedProps.has('pathParams')) && this.fundsOfUser?.length) {
        store.dispatch(getFund());
    }

    if (changedProps.has("fundsOfUser") || changedProps.has("assetUpdateTime"))
      store.dispatch(fetchAllAssets());

    if (changedProps.has("fundsOfUser") || changedProps.has("investorUpdateTime"))
      store.dispatch(fetchAllInvestors());

    if((changedProps.has('assets') || changedProps.has('pathParams')) && this.assets) {
      store.dispatch(getAsset());
    }

    if (changedProps.has('fundsOfUser') && changedProps.get("fundsOfUser")) {
      this.loading = false;
    }

    if((changedProps.has('investorsOfFund') || changedProps.has('pathParams')) && this.investorsOfFund) {
      store.dispatch(getInvestor());
    }

    if(changedProps.has('fund')){
      this.fundChanged();
    }

    if(changedProps.has('fund') || changedProps.has('investorUpdateTime'))
      this.fetchInvestorsOfFund(changedProps.has('investorUpdateTime'));

    if(changedProps.has('fund') || changedProps.has('assetUpdateTime'))
      this.fetchAssetsOfFund();
    
    if(changedProps.has('fundSettings') || changedProps.has('activePath'))
      this.setFormatDefaults();
    
    if(changedProps.has('fund') || changedProps.has('investor') || changedProps.has('asset')){
      if(this.fund !== undefined && this.asset !== undefined && this.investor !== undefined)
        store.dispatch(verifyPage());
    }

    if((changedProps.has('user') || changedProps.has('userFundRole') || changedProps.has('activePath')) && this.user && this.activePath)
      store.dispatch(verifyPagePermission());

    if(changedProps.has('user') || changedProps.has('fundsOfUser') || changedProps.has('userInvites') || changedProps.has('loading'))
      this.checkForOnboarding();
    
  }

  pageChanged() {
    if(process.env.ENABLE_APPCUES === "true") Appcues?.page();
  }

  identifyUser() {
    
    let teamUrl = HttpDetails.getBaseURL();
    teamUrl = teamUrl.replace(/^https?:\/\//i,'');
    
    const region = this.team?.region;
    
    
    //Setup appcues
    try {
      const identifier = {
        id: this.user.userId,
        role: this.user.role?.role,
        userName: this.user.userName,
        firstName: this.user.firstName,
        team: teamUrl,
        region: region,
        fundId : this.fund?.fundId,
        assetId: this.asset?.id,
        investorId: parseInt(this.investor?.id) || undefined
      };
      if(process.env.ENABLE_APPCUES === "true") Appcues.identify(this.user.altUserName, identifier);
    } catch (error) {
      console.log("failed to setup appcues", error);
    }

    //Setup mixpanel
    try {
      mixpanel.init(process.env.MIXPANEL_ID, {persistence: 'localStorage'});
      mixpanel.identify(this.user.altUserName);
      mixpanel.people.set({$name: this.user.userName, $email: this.user.altUserName});
      mixpanel.set_group("team", teamUrl);
    }
    catch(err) {
      console.error("Error setting up mixpanel", err);
    }
  }

  identifyUserGroup() {

    if(!this.team || !this.team.domain) return;
    
    if (this.subscriptionType === "TRIAL" && process.env.ENABLE_HOTJAR === "true") this.initializeHotjar();
    try {
      if(process.env.ENABLE_APPCUES === "true") Appcues.group(this.team.name, { role: this.user.role?.role, region: this.team.region, domain: this.team.domain, subscriptionType: this.subscriptionType });
    } catch (error) {
        console.log("failed to setup subscription groups w/ appcues", error);
    }
  }
  
  initializeHotjar() {
    const fundwaveTeams = ["anzbox.fundwave.com", "demo.fundwave.com"];
    if (fundwaveTeams.includes(this.team.domain) || this.user.altUserName.includes("fundwave.com")) return;

    if (window._hjSettings) return;

    const HOTJAR_ID = 5081233;
    window.hj = window.hj || function () { (window.hj.q = window.hj.q || []).push(arguments) };
    window._hjSettings = { hjid: HOTJAR_ID, hjsv: 6 };

    const script = document.createElement("script");

    script.src=`https://static.hotjar.com/c/hotjar-${HOTJAR_ID}.js?sv=6`;
    script.setAttribute("crossorigin", "anonymous");
    script.setAttribute("defer", "");
    // script.integrity="sha512-LVFArFrSDj75O6zfoy/i+9tGLFO3p8a66yjsRPvRteaoaJnAKdEB5jeTXEz6/veQH5eNsKtT1wtxviDnSXoxdQ==";
    script.setAttribute("nonce", "99df52dad6033f0dc9e7");

    document.getElementsByTagName("head")[0].appendChild(script);
  }

  async initializeTrackers() {

    try {

      this.tracker = new Trackers({
        store: {
          type: "notion",
          context: {
            url: `${process.env.CMS_SERVICE_URL}trackers/page/${process.env.TRACKERS_PAGE_ID}`,
            pageId: process.env.TRACKERS_PAGE_ID
          }
        },
        track: (title, target, event, config) => {
          track(title);
        },
      });

      this.tracker.initialize();

      this.tracker.observeNode(this.shadowRoot);

    } catch (error) {
      console.warn("Failed to initialize trackers", error);
    }

  }

  checkForOnboarding() {
      if (
        !this.fundsOfUser?.length &&
        this.userInvites !== undefined &&
        !this.userInvites?.length &&
        this.loading == false &&
        ["fund", "admin", "asset"].some((role) => role === this.user?.role?.role)
      ) {
        store.dispatch(navigate(routes.onboarding));
      }
  }

  fundChanged(){
    this.getImageUrl();
  }
  
  fetchInvestorsOfFund(override, fundId){
    store.dispatch(fetchInvestorsOfFund(override, fundId));
  }

  fetchAssetsOfFund(){
    store.dispatch(fetchAssetsOfFund());
  }

  getImageUrl(){
    if(!this.fund){
      this.fundLogoUrl = null;
      return;
    }
    FundAPI.getLogo(this.fund.fundId).then((blob) => {
      this.fundLogoUrl = URL.createObjectURL(blob);
    }).catch((err) => {
      console.warn(err);
      this.fundLogoUrl = null;
    });
  }

  changeSelectedFund(e){
    let fund = e.detail.item;
    if(!fund || !fund.fundId) fund = null;
    store.dispatch(updateFund(fund));
  }

  changeSelectedInvestor(e){
    let investor = e.detail.item;
    store.dispatch(updateInvestor(investor));
  }

  changeSelectedAsset(e){
    let asset = e.detail.item;
    store.dispatch(updateAssetById(asset.id));
  }

  toggleDrawer(){
    this.drawerOpen = !this.drawerOpen;
    store.dispatch(toggleDrawer(this.drawerOpen));
  }

  _navigateToActivities() {
    store.dispatch(navigate(this.asset ? routes.portfolioactivities : routes.activities));
  }

  setFormatDefaults(){
    if(this.fundSettings?.allocationMathFormat && (
      this.activePath == routes.noticeApp ||
      this.activePath == routes.preview ||
      this.activePath == routes.transfer
    )){
      setDefaultMantissa(this.fundSettings.allocationMathFormat?.decimalPrecision);
    }
    else{
      setDefaultMantissa();
    }
  }

  signOut(){
    let redirectUri = this.getLoginRedirectionPath();
    sessionStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    const url = OpenApiOIDCClient.getBaseUrl()+"/logout?redirectUri=" + encodeURIComponent(redirectUri);
    window.location.href = url;
  }

  getLoginRedirectionPath(){

    let redirectURI = window.location.origin+"/login";

    //Clean params for next url
    let params = new URLSearchParams(location.search);
    params.delete('team');
    if(this.fund && !params.get('fundId')) params.append("fundId",this.fund.fundId);

    params = params.toString();
    redirectURI = redirectURI + "?next="+encodeURIComponent(`${location.pathname}${params ? `?${params}` : ``}`);

    return redirectURI;
  }

  getLoginUrl(){
    let loginUrl = this.getLoginRedirectionPath();
    let team = getURLParameter('team');
    if(team) loginUrl = loginUrl + "&team="+team;
    return loginUrl;
  }

  
  _fetchUserInvitesAndFunds() {
    store.dispatch(fetchUserInvites(this.user.altUserName));
    store.dispatch(fetchFundsOfUser()); //ListOfFunds which user has access to.
    store.dispatch(fetchUserFunds()); //ListOfUserFund including - userId, fundId, fundRole.
  }

  _openInvitesModal() {
    this.shadowRoot.querySelector("fw-invites-modal").open();
  }

  subscriptionUpdated(e) {
    let isBannerVisible = e.detail.isBannerVisible;
    this.subscriptionType = e.detail.subscriptionType;
    this.isTrialUser = e.detail.isTrialUser;
    
    if(isBannerVisible) {
      const sheet = new CSSStyleSheet();
      sheet.replaceSync(`:host { --subscription-banner-height:  var(--default-app-banner-height)}`);
      this.shadowRoot.adoptedStyleSheets.push(sheet);
    }
  }

  routeGroupChanged(group) {
    if (this.loading) return;
    this.hideDrawer = (group?.length <= 1);
  }

}

customElements.define("my-app", App);
