import { TagAPIClient } from "@fundwave/openapi-client/src/index.js";
export const UPDATE_TAGS = "UPDATE_TAGS";

export const fetchTags = () => async (dispatch) => 
  TagAPIClient.getInvestorTags()
    .then((tags) => dispatch({ type: UPDATE_TAGS, data: tags }))
    .catch((err) => {
      console.log("Failed to retrieve investor-tags", err);
      dispatch({ type: UPDATE_TAGS, data: [] });
    });
