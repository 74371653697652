import { querySelectorAllDeep, querySelectorDeep } from 'query-selector-shadow-dom';

Promise.allSettled = (promises) =>
  Promise.all(promises.map((item) => item?.then((value) => ({ status: "fulfilled", value }))?.catch((reason) => ({ status: "rejected", reason }))));

(function overrideQuerySelector() {
  let querySelectorAll = DocumentFragment.prototype.querySelectorAll;
  let querySelector = DocumentFragment.prototype.querySelector;

  //Shadow root query selector for helphero
  DocumentFragment.prototype.querySelectorAll = function (selector) {
    if (selector.indexOf(">>>") >= 0) {
      return querySelectorAllDeep(selector.split(">>>").pop(), this);
    }
    return querySelectorAll.apply(this, arguments);
  };

  DocumentFragment.prototype.querySelector = function (selector) {
    if (selector.indexOf(">>>") >= 0) {
      return querySelectorDeep(selector.split(">>>").pop(), this);
    }
    return querySelector.apply(this, arguments);
  };
})();
