import { html } from "lit";
import { showMessageBar, navigate } from "../../actions/app";
import { routes, urls } from "../../constants";
import { connect } from "pwa-helpers/connect-mixin.js";
import { store } from "../../store.js";
import { track } from "../../usage-tracking.js";
import { fetchFundsOfUser, fetchUserFunds, updateFundById } from "../../actions/fund";
import { EntityAPI } from "@fundwave/api-client/src/entity";
import { FundAPIClient } from "@fundwave/openapi-client/src/index";
import { PageViewElement } from "@fundwave/ui-utils/src/page-view-element";
import { PrimaryButtonStyles, UnderlinedButtonStyles } from "@fundwave/styles/button-styles";
import { BoxInputStyles } from "@fundwave/styles/input-styles";
import "@polymer/paper-button/paper-button";
import "@polymer/paper-dialog/paper-dialog";
import "@polymer/paper-spinner/paper-spinner.js";
import OnboardingWidget from "@fundwave/onboarding-widget";

import "lit-react-wrapper";
import { OnboardingStyles } from "../../styles/onboarding-styles.js";

export class Onboarding extends connect(store)(PageViewElement) {
  render() {
    return html`
      ${PrimaryButtonStyles}${UnderlinedButtonStyles}${BoxInputStyles} ${OnboardingStyles}

      <div id="onboard-container">
        ${this.loading
          ? html`
              <div style="width: 100%; text-align: center; margin-top: 30px; color: var(--secondary-color)">
                <img class="loading-img" src="node_modules/@fundwave/images/icons/user-interface.png" class="loading-img" />
                <div>
                  ${this.showLoadingSpinner ? html` <paper-spinner active style="width: 20px; height: 20px; margin: 0 10px;"></paper-spinner> ` : null}
                  <span class="loading-message">${this.loadingMessage ?? "Fetching data..."}</span>
                </div>
              </div>
            `
          : html`
              <lit-react-wrapper
                id="onboarding-widget-wrapper"
                .element=${OnboardingWidget}
                .props=${{
                  steps: this.fundCreationSteps,
                }}
                @onboard-submit=${(e) => this.createFund(e.detail.result)}
                style=${`display: ${this.currentStep == "fund" ? "block" : "none"}`}
              >
              </lit-react-wrapper>
            `}
      </div>
    `;
  }

  static get properties() {
    return {
      fundCreationSteps: Array,
      steps: Array,
      currentStep: String,
      investor: Boolean,
      fund: Object,
      funds : Array,
      sectors: Array,
      sectorNames: Set,
      loading: Boolean,
      loadingMessage: Boolean,
      showLoadingSpinner: Boolean,
      currencies: Array,
    };
  }

  constructor() {
    super();

    this.steps = ["fund"];
    this.currentStep = "fund";
    this.fund = {
      initialClosingDate: new Date()
    };
    this.currencies = [];
    this.funds = [];
    this.showLoadingSpinner = true;
    this.fundCreationSteps = [
      {
        title: "Let's give your fund a name",
        required: true,
        description: "Fund is the legal entity through which you make your investments.",
        key: "fundName",
        type: {
          datatype: "text",
        },
        graphic: "node_modules/@fundwave/images/icons/architect-flatline.png",
      },
      {
        title: "What's the initial closing date of your fund?",
        required: true,
        description: "Initial Closing Date is the the first time when investors invest their money in the fund",
        key: "initialClosingDate",
        type: {
          datatype: "date",
        },
        format: "MMM dd, yyyy",
        maxDate: new Date().toISOString().split("T")[0],
        graphic: "node_modules/@fundwave/images/icons/calendar-monochromatic.png",
      },
      {
        title: "Which currency do you invest from?",
        required: true,
        description: "This would be your fund's reporting currency",
        key: "currency",
        type: {
          datatype: "dropdown",
        },
        allOptionsLabel: "ALL CURRENCIES",
        famousOptionsLabel: "FAMOUS CURRENCIES",
        graphic: "node_modules/@fundwave/images/icons/currency-monochromatic.png",
        // graphic : currency
      },
    ];
    this.entitiesCreated = [];

    this.fetchCurrencies();
  }

  stateChanged(state) {
    this.funds = state.fund.fundsOfUser;
  }
  updated(changedProperties) {
    if (changedProperties.has("active") && this.active) {
      track("Onboarding started for fund");
      this.currentStep = "fund";
      this.entitiesCreated = [];
    }
    if(changedProperties.has("funds")&& this.funds?.length){
      if(this.funds.length === 1){
        store.dispatch(updateFundById(this.funds[0].fundId));
      }
      else{
        store.dispatch(navigate(routes.home));
      }
    }
  }

  setStepOptions(dataField, optionsArray, famousOptionsArray) {
    let stepIndex = this.fundCreationSteps.findIndex((step) => step.key === dataField);
    if (stepIndex < 0) return;
    this.fundCreationSteps[stepIndex].options = optionsArray;

    if (famousOptionsArray) this.fundCreationSteps[stepIndex].famousOptions = famousOptionsArray;

    this.fundCreationSteps = this.fundCreationSteps.concat();
  }

  async fetchCurrencies() {
    const currencies = await EntityAPI.fetchCurrency().then((data) => data.currency);
    this.currencies = currencies.map((curr) => ({ code: curr.currencyCode, name: curr.currencyName }));
    this.setStepOptions(
      "currency",
      this.currencies,
      this.currencies.filter((curr) => ["USD", "EUR", "AUD", "INR", "GBP"].includes(curr.code))
    );
  }

  async createFund(fundDetails) {
    this.loading = true;
    this.loadingMessage = "Creating your fund...";

    try {
      // Create the fund
      const fundPayload = {
        currency: fundDetails.currency,
        investorAccountType: {
          name: "Capital Accounts Only",
          type: "CAP",
        },
        name: fundDetails.fundName,
        initialClosingDate: new Date(fundDetails?.initialClosingDate),
        type: "CLOSE_ENDED",
      };

      const fundId = await FundAPIClient.addFund(undefined, undefined, undefined, fundPayload);
      this.fund = {
        fundId,
        fundName: fundDetails.fundName,
        currency: fundDetails.currency,
        initialClosingDate: fundDetails.initialClosingDate,
      };

      track(`Fund created using onboarding`, { id: this.fund?.fundId, name: this.fund?.fundName });
      this.entitiesCreated.push("fund");
      this.endOnboard();
    } catch (err) {
      console.log(err);
      this.loading = false;
      store.dispatch(showMessageBar("We're having trouble adding this Fund", "error"));
    }
  }

 

  gotoNextStep() {

    for (let [index, step] of this.steps.entries()) {
      if (step === this.currentStep && index < this.steps.length - 1) {
        this.currentStep = this.steps[index + 1];
        track(`Moving to ${this.currentStep} step`);
        break;
      } else if (index === this.steps.length - 1) {
        this.endOnboard();
      }
    }
  }

  endOnboard() {
    track(`Ending onboarding for ${this.currentStep} step ${this.fund?.fundName ? "for " + this.fund?.fundName : ""}`);
    store.dispatch(fetchUserFunds());
    store.dispatch(fetchFundsOfUser()).then(() => {
      store.dispatch(navigate(routes.home));
      store.dispatch(showMessageBar(`${this.fund.fundName} is added on Fundwave. Get started!`, "success"));
      setTimeout(() => {
        this.loading=false;
      }, 1000);
    })
  }
}

customElements.define("onboarding-page", Onboarding);
