import { AssetAPI } from "@fundwave/api-client/src/asset";
import { UserUtil } from "@fundwave/ui-utils/src/UserUtil.js";
import { InstanceAPI } from "@fundwave/api-client/src/instance.js";
import { CaptableAPIClient } from "@fundwave/openapi-client/captable/src/index";
import { AssetAPI as GraphQLAssetAPI } from "@fundwave/api-client/src/graphql/asset.js";

export async function addUserToAsset(asset, user, captableTeamId) {
  let assetId = asset.id;

  try {
    //Add user
    await AssetAPI.addAssetUser(null, assetId, null, false);

    let assetRole = await AssetAPI.getAssetRole(assetId);
    let isAssetAdmin = UserUtil.isAssetAdmin(user, assetRole);
    if (!isAssetAdmin) return;

  } catch (errJoinAsset) {
    console.log(errJoinAsset);
    throw new Error("An error occured while adding user");
  }

  let captableId = await getCaptableId(assetId);

  if (captableId) {
    try {
      await CaptableAPIClient.joinCaptable(captableId);
    } catch (errJoinCaptable) {
      console.log("Couldn't join the captable", errJoinCaptable);
    }
  } else {
    let data = {
      assetId,
      captable: {
        name: asset?.name,
        country: asset?.country?.name,
      },
    };

    if (captableTeamId) data.captable.teams = [].concat(captableTeamId);
    else data.instance = HttpDetails.getBaseURL() ?? window.location.protocol + "//" + window.location.hostname;

    let captable = {};
    try {
      captable = await CaptableAPIClient.addCaptable(data);
    } catch (errCreateCaptable) {
      console.log(errCreateCaptable);
      throw new Error(`Unable to create captable for ${asset.name}`);
    }

    if (captable) {
      //update instance captable details

      //save team in global settings
      let teamId = captable.teams && captable.teams.length > 0 ? captable.teams[captable.teams.length - 1].id : null;

      if (teamId) {
        try {
          await InstanceAPI.saveCaptableTeamId(teamId);
          captableTeamId = teamId;
        } catch (err) {
          console.log(err);
          throw new Error("Unable to setup captable. Do you have the admin rights for this instance?");
        }
      } else {
        throw new Error("Captable doesn't contain teamId");
      }

      try {
        //save captableId in assetdetails
        let [userAsset, assetDetail] = await Promise.all([AssetAPI.fetchAsset({ id: assetId }), AssetAPI.fetchAssetDetails(asset)]);

        if (!assetDetail) assetDetail = {};
        if (!assetDetail.asset) {
          if (userAsset.assetDealType && Object.keys(userAsset.assetDealType) == 0) userAsset.assetDealType = null;
          assetDetail.asset = userAsset;
        }
        assetDetail.captableId = captable.id;

        //update assetDetails here
        AssetAPI.saveAssetDetails(assetId, assetDetail);
      } catch (errSavingAssetDetails) {
        console.log(errSavingAssetDetails);
        let message = `Unable to save captable for ${asset.name}`;
        if (errSavingAssetDetails.status == 403) message = `Unable to add captable. Do you have the required access rights for ${asset.name} ?`;
        throw new Error(message);
      }
    } else {
      console.log("Error creating captable");
      throw new Error(`Unable to create captable for ${asset.name}`);
    }
  }
}

export async function getCaptableId(assetId) {
  let fields = "asset{ id name } captableId";
  let variableContext = { assetIds: [assetId] };
  let assetDetails = (await GraphQLAssetAPI.assetDetails(fields, variableContext)) || [];
  let assetDetail = assetDetails.find((assetDetailObj) => String(assetDetailObj.asset.id) === String(assetId));
  let captableId = assetDetail?.captableId;
  return captableId;
}