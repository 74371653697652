export const routes = {
  portfoliodashboard: "portfolio-dashboard",
  portfoliosubmit: "portfolio-submit",
  portfolioconfirmation: "portfolio-confirmation",
  portfoliosubmission: "portfolio-submission",
  portfolioreview: "portfolio-review",
  portfolioexcel: "portfolio-excel",
  portfoliorequestOverview: "portfolio-request-overview",
  portfolioviewDeliverable: "portfolio-view-deliverable",
  portfoliometricsOverview: "portfolio-metrics-overview",
  portfolioportfolioSummary: "portfolio-portfolio-summary",
  portfolioimportAssets: "portfolio-importAssets",
  portfolioimportTransactions: "portfolio-importTransactions",
  portfolioassetDetails: "portfolio-asset-details",
  portfoliofeed: "portfolio-feed",
  portfoliorounds: "portfolio-rounds",
  portfolioround: "portfolio-round",
  portfolioforecastRounds: "portfolio-forecast-rounds",
  portfolioassetMetrics: "portfolio-asset-metrics",
  portfoliotimeline: "portfolio-timeline",
  portfolioexpense: "portfolio-expense",
  portfolioincome: "portfolio-income",
  portfoliorealization: "portfolio-realization",
  portfolioinstrument: "portfolio-instrument",
  portfolioinstruments: "portfolio-instruments",
  portfolioamortizationSchedule: "portfolio-amortization-schedule",
  portfolionoteModelling: "portfolio-note-modelling",
  portfolioexitWaterfall: "portfolio-exit-waterfall",
  portfoliocaptableByInvestor: "portfolio-captable-by-investor",
  portfoliocoinvestors: "portfolio-co-investors",
  portfolioprojections: "portfolio-jcurve-projections",
  portfoliopostRealization: "portfolio-post-realization",
  portfoliopostInvestment: "portfolio-post-investment",
  portfoliopostIncome: "portfolio-post-income",
  portfoliopostExpense: "portfolio-post-expense",
  portfoliopostValuation: "portfolio-post-valuation",
  portfolioconvertTransaction: "portfolio-convert",
  portfoliotransaction: "portfolio-transaction",
  portfoliovaluationTimeline: "portfolio-valuation-timeline",
  portfoliofiles: "portfolio-files",
  portfolioimportMetrics: "portfolio-import-metrics",
  portfolioassetImportMetrics: "portfolio-asset-import-metrics",
  portfolioonboarding: "portfolio-onboarding",
  portfolioimportPreview: "portfolio-import-preview",
  importDashboard: "import-dashboard",
};

export const routeProperties = {
  [routes.portfolioprojections]: { urls: ["/jcurve-projections", "/:fundId/jcurve-projections"], title: "Jcurve Projections", icon: "query_stats" },
  [routes.portfolioimportMetrics]: { urls: ["/import/metrics", "/:fundId/import/metrics"], title: "Metrics", name: "Metrics", parentRoute: routes.importDashboard },
  [routes.portfolioassetImportMetrics]: { urls: ["/asset/:assetId/import/metrics", "/:fundId/asset/:assetId/import/metrics"], title: "Import Metrics", name: "Import" },
  [routes.portfolioportfolioSummary]: { urls: ["/portfolio-summary", "/:fundId/portfolio-summary"], title: "Portfolio Performance", name: "Performance", keywords: ["Track Record"], icon: "insights" },
  [routes.portfoliorequestOverview]: {
    urls: ["/tracker", "/:fundId/asset/:assetId/tracker", "/:fundId/tracker", "/asset/:assetId/tracker", "/asset/:assetId/tracker/:action/:deliverableId", "/:fundId/asset/:assetId/tracker/:action/:deliverableId"],
    title: "Requests",
    keywords: ["Tracker"]
  },
  [routes.portfolioimportAssets]: {
    urls: ["/import/asset", "/:fundId/import/asset"],
    title: "Import Assets",
    name: (params) => {
      if (!params?.fundId) return "Import Assets"
      else return "Assets"
    },
    parentRoute: (params) => {
      if (params.fundId) return routes.importDashboard;
      else return null;
    }
  },
  [routes.portfolioimportTransactions]: { urls: ["/:fundId/import/cashflows", "/import/cashflows"], title: "Import Cashflows", name: "Cashflows", parentRoute: routes.importDashboard },
  [routes.portfoliodashboard]: { urls: ["/asset/:assetId/dashboard", "/:fundId/asset/:assetId/dashboard"], title: "Dashboard", keywords: ["KPIs"] },
  [routes.portfoliosubmit]: { urls: ["/asset/:assetId/submit/:requestId", "/:fundId/asset/:assetId/submit/:requestId"] },
  [routes.portfolioconfirmation]: { urls: ["/asset/:assetId/confirmation", "/:fundId/asset/:assetId/confirmation"], skipGlobalSearch: true },
  [routes.portfoliosubmission]: { urls: ["/asset/:assetId/submission/:requestId", "/:fundId/asset/:assetId/submission/:requestId"] },
  [routes.portfolioreview]: { urls: ["/asset/:assetId/review", "/:fundId/asset/:assetId/review"], skipGlobalSearch: true },
  [routes.portfolioexcel]: { urls: ["/asset/:assetId/excel/:requestId", "/:fundId/asset/:assetId/excel/:requestId"], skipGlobalSearch: true },
  [routes.portfolioviewDeliverable]: { urls: ["/asset/:assetId/view-deliverable", "/:fundId/asset/:assetId/view-deliverable", "/asset/:assetId/view-deliverable/:deliverableId", "/:fundId/asset/:assetId/view-deliverable/:deliverableId"], name: "Plan" },
  [routes.portfoliometricsOverview]: { urls: ["/asset/:assetId/metrics-overview", "/:fundId/asset/:assetId/metrics-overview"], name: "Metrics Overview" },
  [routes.portfolioassetDetails]: { urls: ["/asset/:assetId/asset-details", "/:fundId/asset/:assetId/asset-details"], title: "Company Details" },
  [routes.portfoliofeed]: { urls: ["/asset/:assetId/feed", "/:fundId/asset/:assetId/feed"], title: "Feed", keywords: ["Notes"] },
  [routes.portfoliorounds]: { urls: ["/asset/:assetId/rounds", "/:fundId/asset/:assetId/rounds"], title: "Rounds" },
  [routes.portfolioround]: { urls: ["/asset/:assetId/round", "/:fundId/asset/:assetId/round", "/asset/:assetId/round/:roundId", "/:fundId/asset/:assetId/round/:roundId"], name: "Round", title: "Add Round", icon: "add", skipGlobalSearch: true },
  [routes.portfolioforecastRounds]: { urls: ["/asset/:assetId/forecast-rounds", "/:fundId/asset/:assetId/forecast-rounds"], title: "Forecast Rounds" },
  [routes.portfolioassetMetrics]: { urls: ["/asset/:assetId/asset-metrics", "/:fundId/asset/:assetId/asset-metrics"], title: "Metrics" },
  [routes.portfoliotimeline]: { urls: ["/asset/:assetId/timeline", "/:fundId/asset/:assetId/timeline"], title: "Timeline", keywords: ["Cashflows"], searchPriority: 1 },
  [routes.portfolioexpense]: { urls: ["/asset/:assetId/expense", "/:fundId/asset/:assetId/expense"], name: "Expense" },
  [routes.portfolioincome]: { urls: ["/asset/:assetId/income", "/:fundId/asset/:assetId/income"], name: "Income" },
  [routes.portfoliorealization]: { urls: ["/asset/:assetId/realization", "/:fundId/asset/:assetId/realization"], name: "Realization" },
  [routes.portfolioinstrument]: { urls: ["/asset/:assetId/instrument", "/:fundId/asset/:assetId/instrument", "/asset/:assetId/instrument/:instrumentId", "/:fundId/asset/:assetId/instrument/:instrumentId"], name: "Security" },
  [routes.portfolioinstruments]: { urls: ["/asset/:assetId/instruments", "/:fundId/asset/:assetId/instruments"], title: "Securities" },
  [routes.portfolioamortizationSchedule]: {
    urls: ["/asset/:assetId/amortization-schedule/:loanId", "/:fundId/asset/:assetId/amortization-schedule/:loanId"],
    title: "Loan Schedule",
  },
  [routes.portfolionoteModelling]: { urls: ["/asset/:assetId/note-modelling/:noteId", "/:fundId/asset/:assetId/note-modelling/:noteId"], title: "Modelling" },
  [routes.portfolioexitWaterfall]: { urls: ["/asset/:assetId/exit-waterfall", "/:fundId/asset/:assetId/exit-waterfall"], title: "Exit waterfall" },
  [routes.portfoliocaptableByInvestor]: { urls: ["/asset/:assetId/captable-by-investor", "/:fundId/asset/:assetId/captable-by-investor"], title: "By Co-Investors", keywords: ["Captable By Investors"] },
  [routes.portfoliocoinvestors]: { urls: ["/captable-by-investor"], title: "Co-investors" },
  [routes.portfoliopostRealization]: { urls: ["/asset/:assetId/post-realization", "/:fundId/asset/:assetId/post-realization"], name: "Realization" },
  [routes.portfoliopostInvestment]: { urls: ["/asset/:assetId/post-investment", "/:fundId/asset/:assetId/post-investment"], name: "Investment" },
  [routes.portfoliopostIncome]: { urls: ["/asset/:assetId/post-income", "/:fundId/asset/:assetId/post-income"], name: "Income" },
  [routes.portfoliopostExpense]: { urls: ["/asset/:assetId/post-expense", "/:fundId/asset/:assetId/post-expense"], name: "Expense" },
  [routes.portfoliopostValuation]: { urls: ["/asset/:assetId/post-valuation", "/:fundId/asset/:assetId/post-valuation"], name: "Valuation", title: "Post valuation" },
  [routes.portfolioconvertTransaction]: { urls: ["/asset/:assetId/convert", "/:fundId/asset/:assetId/convert", "/asset/:assetId/convert/:transactionGroupId", "/:fundId/asset/:assetId/convert/:transactionGroupId"], name: "Convert" },
  [routes.portfoliotransaction]: { urls: ["/asset/:assetId/transaction/:transactionGroupId", "/:fundId/asset/:assetId/transaction/:transactionGroupId"] },
  [routes.portfoliovaluationTimeline]: { urls: ["/asset/:assetId/valuation-timeline", "/:fundId/asset/:assetId/valuation-timeline"], title: "Valuation Timeline" },
  [routes.portfoliofiles]: { urls: ["/asset/:assetId/files", "/:fundId/asset/:assetId/files"], title: "Files" },
  [routes.portfolioonboarding]: { urls: ["/asset/:assetId/onboard", "/:fundId/asset/:assetId/onboard"], skipGlobalSearch: true },
  [routes.portfolioimportPreview]: { urls: ["/asset/:assetId/import/:importId", "/import/:importId", "/:fundId/import/:importId", "/:fundId/asset/:assetId/import/:importId"], name: "Import" },
};
