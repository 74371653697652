import { LitElement, html } from "lit";
import { headerDisabledRoutes, headerHiddenRoutes, routes, assetEnabledRoutes } from "../constants.js";
import { HeaderStyles } from "@fundwave/styles/page-header-styles.js";
import { connect } from "pwa-helpers/connect-mixin.js";
import { store } from "../store.js";
import { fundRouteGroups, allFundRouteGroups, investorRouteGroups, allFundInvestorRouteGroups, routeProperties, assetRouteGroups, noMenuRouteGroups } from "../constants.js";
import "@material/mwc-icon";
import "@polymer/paper-dropdown-menu/paper-dropdown-menu.js";
import "@polymer/paper-listbox/paper-listbox.js";
import { getUserRoles, navigate } from "../actions/app.js";
import { DropdownStyles, PlainInputStyles } from "@fundwave/styles/input-styles.js";
import { updateAssetById } from "../actions/asset.js";
import { updateInvestorById } from "../actions/investor.js";
import { updateFund } from "../actions/fund.js";
import "./team/team-view.js";
import { UserUtil } from "@fundwave/ui-utils/src/UserUtil.js";
import { isSameObject } from "@fundwave/ui-utils/src/parse-util.js";

class PageHeader extends connect(store)(LitElement) {
  constructor() {
    super();
  }

  render() {
    const routeHasSiblings = Boolean(this.activeRouteGroup?.routes?.length > 1);

    return html`
      ${HeaderStyles} ${PlainInputStyles} ${DropdownStyles}
      <style>
        entity-selector {
          max-width: 60%;
          --entity-selector-align: left;
          --entity-selector-font: var(--theme-font);
          --entity-selector-background-color: var(--light-color);
          --entity-selector-text-color: var(--secondary-color);
          --entity-selector-item-text-color: var(--secondary-color);
          --entity-selector-item-font-size: var(--body-font-size);
          --entity-selector-font-size: var(--body-font-size);
          /* --entity-selector-hover-color: var(--primary-color); */
          /* --entity-selector-font-weight: bold; */
          /* --disabled-text-color: var(--light-color); */
        }
        paper-dropdown-menu.plain {
          --body-font-size: var(--secondary-font-size);
        }
        .page-header.no-space-top {
          margin: 0;
          padding: 0 max(3%, 20px);
        }
      </style>

      <div class="page-breadcrumbs-menu" ?hidden=${this.activePath == routes.home}>
        <div class="page-breadcrumbs">
          ${this.getFundBreadCrumbsMenu(this.fund, this.asset, this.investor)} ${this.getInvestorBreadCrumbsMenu(this.investor, this.fund)}
          ${this.getAssetBreadCrumbsMenu(this.asset, this.fund)}
          ${this.activeRouteGroup && routeHasSiblings
            ? html`
                <mwc-icon>chevron_right</mwc-icon>
                <span class="route breadcrumb" @tap=${() => this._navigate(this.activeRouteGroup.routes[0])}>${this.activeRouteGroup?.name}</span>
              `
            : null}
          ${this.activeRouteLadder?.map(
            (routeProperties, index) => html`
              ${routeProperties.title ? html`<mwc-icon>chevron_right</mwc-icon>` : null}
              ${index != this.activeRouteLadder.length - 1
                ? html` <div class="breadcrumb" @tap=${(e) => this._navigate(routeProperties.route)}>${routeProperties.title}</div> `
                : html` <span class="route">${routeProperties.title}</span> `}
            `
          )}
        </div>

        ${this.wideLayout ? html`<team-view style="margin: 0px 1%" ?active=${!!this.fund || !!this.asset}></team-view>` : ""}
      </div>
      <div class="page-header no-space-top" ?active=${!!this.activeRouteGroup && routeHasSiblings}>
        <div class="sub-header">
          ${this.activeRouteGroup?.routes?.map(
            (route) =>
              html`
                <a class="sub-header-item" ?active=${this.activePath === route} @tap=${(e) => this._navigate(route)}>
                  ${routeProperties[route].name ?? this.getRouteTitle(routeProperties[route], this.activeRouteGroup?.params?.[route])}
                </a>
              `
          )}
        </div>
      </div>
    `;
  }

  stateChanged(state) {
    this.user = state.user.user;
    this.fund = state.fund.fund;
    this.asset = state.asset.asset;
    this.investor = state.investor?.investor;
    this.investors = state.investor?.investors;
    this.funds = state.fund.fundsOfUser;
    this.assets = state.asset?.assets;
    this.assetsOfFund = state.asset?.assetsOfFund;
    this.params = state.router.params;
    this.activeRouteTitle = state.router.activeRouteTitle;
    this.fundRole = state.fund.userFundRole;
  }

  static get properties() {
    return {
      activeRouteGroup: Object,
      activePath: Object,
      activeRouteLadder: Object,
      activeRouteTitle: String,
      wideLayout: Boolean,
      investor: Object,
      investors: Array,
      fund: Object,
      fundRole: String,
      asset: Object,
      assets: Object,
      assetsOfFund: Array,
      funds: Object,
      user: Object,
      params: Object,
    };
  }

  updated(changedProps) {
    if (
      changedProps.has("fund") ||
      changedProps.has("investor") ||
      changedProps.has("asset") ||
      changedProps.has("activePath") ||
      changedProps.has("user") ||
      changedProps.has("fundRole") || 
      changedProps.has("assetsOfFund") ||
      changedProps.has("investors")
    ) {
      this.getActiveRouteGroup();
    }
    if ((changedProps.has("activePath") || changedProps.has("activeRouteTitle")) && this.activePath) this.getRouteLadder();
  }

  getRouteLadder() {
    let ladder = [];
    let route = this.activePath;
    ladder.push({ ...routeProperties[route], route, title: this.activeRouteTitle ?? this.getRouteTitle(routeProperties[route]) });
    route = this.getParentRoute(route);
    while (route) {
      ladder.push({ ...routeProperties[route], route, title: this.getRouteTitle(routeProperties[route]) });
      route = this.getParentRoute(route);
    }
    this.activeRouteLadder = ladder.reverse();
  }

  getParentRoute(route) {
    let parentRoute = routeProperties[route]?.parentRoute;
    if (parentRoute && parentRoute instanceof Function) return parentRoute(this.params);
    return parentRoute;
  }

  _selectRouteGroup() {
    let selectedRouteGroups = allFundRouteGroups;
    if (this.investor) {
      selectedRouteGroups = this.fund ? investorRouteGroups : allFundInvestorRouteGroups;
    } else if (this.asset) {
      selectedRouteGroups = assetRouteGroups;
    } else {
      selectedRouteGroups = this.fund ? fundRouteGroups : allFundRouteGroups;
    }
    return selectedRouteGroups;
  }

  getActiveRouteGroup() {
    const selectedRouteGroups = [...this._selectRouteGroup(), ...noMenuRouteGroups];
    const activeRouteGroup = selectedRouteGroups.find((routeGroup) => this.isRouteGroupActive(routeGroup));

    const permittedRoutes = activeRouteGroup?.routes?.filter((route) => {
      if (routeProperties[route].required && !routeProperties[route].required.evaluate({ asset: this.assetsOfFund?.length, investor: this.investors?.length })) return false;
      if (routeProperties[route].userRole) return getUserRoles(this.user?.role?.role)?.includes(routeProperties[route].userRole);

      else if (routeProperties[route].fundRole && this.fundRole) {
        const routeFundRoleRaw = routeProperties[route].fundRole;
        const routeFundRole = routeFundRoleRaw instanceof Function ? routeFundRoleRaw(this.params) : routeFundRoleRaw;

        return UserUtil.fundRoleChain[routeFundRole]?.includes(this.fundRole);
      }
      else return true;
    });

    this.activeRouteGroup = activeRouteGroup ? { ...activeRouteGroup, routes: permittedRoutes } : null;
  }

  isRouteGroupActive(routeGroup) {
    if (!routeGroup.routes?.includes(this.activePath)) return false;
    let routeGroupParams = routeGroup?.params?.[this.activePath];
    if (routeGroupParams) {
      for (let key in routeGroupParams) 
        if (!isSameObject(routeGroupParams[key], this.params[key])) return false;
    }
    return true;
  }
  _navigate(route) {
    let params = this.activeRouteGroup?.params?.[route] ?? {};
    store.dispatch(navigate(route, params));
  }

  _navigateToInvestorSummary() {
    store.dispatch(updateInvestorById(null));
    store.dispatch(navigate(routes.investorDashboard));
  }

  _navigateToAssetSummary() {
    store.dispatch(updateAssetById(null));
    store.dispatch(navigate(routes.portfolio));
  }

  _navigateToDashboard() {
    store.dispatch(navigate(this.fund ? routes.dashboard : routes.home));
  }

  _navigateToGlobalDashboard() {
    store.dispatch(navigate(routes.home));
    store.dispatch(updateFund(null));
  }

  getInvestorBreadCrumbsMenu(investor, fund) {
    if (!investor) return;
    return html`
      <div class="breadcrumb" @tap=${(e) => this._navigateToDashboard()}>
        <mwc-icon>home</mwc-icon>
        <span>${fund?.fundName ?? "Home"}</span>
      </div>
      <mwc-icon>chevron_right</mwc-icon>
      <div class="breadcrumb" @tap=${(e) => this._navigateToInvestorSummary()}>
        <mwc-icon>group</mwc-icon>
        <span>Investors</span>
      </div>
      <mwc-icon>chevron_right</mwc-icon>
      ${!this.investors?.length
        ? html`${investor?.name ?? investor?.code}`
        : html`
            <entity-selector
              on-context-menu
              @tap=${() => this._navigate(routes.investorHome)}
              class="breadcrumb"
              .items="${this.investors}"
              @item-select="${(e) => this.fireEvent("investor-updated", e.detail)}"
              .selected=${this.investor}
              .horizontalAlign=${"left"}
              .autosize=${true}
            ></entity-selector>
          `}
    `;
  }

  getAssetBreadCrumbsMenu(asset, fund) {
    if (!asset) return;
    return html`
      <div class="breadcrumb" @tap=${(e) => this._navigateToDashboard()}>
        <mwc-icon>home</mwc-icon>
        <span>${fund?.fundName ?? "Home"}</span>
      </div>
      <mwc-icon>chevron_right</mwc-icon>
      <div class="breadcrumb" @tap=${(e) => this._navigateToAssetSummary()}>
        <mwc-icon>apartment</mwc-icon>
        <span>Assets</span>
      </div>
      <mwc-icon>chevron_right</mwc-icon>
      ${!this.assets?.length
        ? html`${asset?.name}`
        : html`
            <entity-selector
              on-context-menu
              @tap=${() => this._navigate(routes.portfoliotimeline)}
              class="breadcrumb"
              .items="${this.assets}"
              @item-select="${(e) => this.fireEvent("asset-updated", e.detail)}"
              .selected=${this.asset}
              .horizontalAlign=${"left"}
              .autosize=${true}
              .disabled=${!assetEnabledRoutes.includes(this.activePath)}
            ></entity-selector>
          `}
    `;
  }

  getFundBreadCrumbsMenu(fund, asset, investor) {
    if (asset || investor) return;
    return html` <div class="breadcrumb" tabindex="0" @keydown=${(e) => [13, 32].includes(e.which) && e.currentTarget.click()} @tap=${() => this._navigateToGlobalDashboard()}>
        <mwc-icon>home</mwc-icon>
        ${!this.fund ? html`<span>Home</span>` : null}
      </div>
      ${this.funds && this.funds.length > 0 && this.fund
        ? html`
            <mwc-icon>chevron_right</mwc-icon>
            <entity-selector
              on-context-menu
              @tap=${this._navigateToDashboard}
              class="breadcrumb"
              .items="${this.funds}"
              @item-select="${(e) => this.fireEvent("fundChanged", e.detail)}"
              .selected=${fund ? fund : { fundId: 0 }}
              .selectionAttribute=${"fundId"}
              .nameAttribute=${"fundName"}
              .disabled=${this.isDisabled()}
              .hidden=${this.isHidden()}
              .autosize=${true}
              .horizontalAlign=${"left"}
            >
            </entity-selector>
          `
        : null}`;
  }

  getRouteTitle(routeProperties, params = this.params) {
    if (routeProperties.name && routeProperties.name instanceof Function) return routeProperties.name(params);
    else if (routeProperties.name) return routeProperties.name;
    else if (routeProperties.title && routeProperties.title instanceof Function) return routeProperties.title(params);
    else if (routeProperties.title) return routeProperties.title;
  }

  fireEvent(eventName, detail) {
    this.dispatchEvent(new CustomEvent(eventName, { detail, bubbles: true, composed: true }));
  }

  isDisabled() {
    return headerDisabledRoutes?.includes(this.activePath || "");
  }

  isHidden() {
    return !!this.asset || headerHiddenRoutes?.includes(this.activePath || "");
  }
}

customElements.define("page-header", PageHeader);
