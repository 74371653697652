import {
  FETCH_SUBSCRIPTION,
  FETCH_DEAL_SUBSCRIPTION,  
} from '../actions/subscription.js';

const initialState = {
  dealSubscription: undefined,
  subscription: undefined
}

const subscription = (state = initialState,  action) => {

  switch (action.type) {

    case FETCH_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.data
      };

    case FETCH_DEAL_SUBSCRIPTION:
      return {
        ...state,
        dealSubscription: action.data
      };

    default:
      return state;

  }

}

export default subscription;
