import { LitElement, html } from 'lit';
import { APIClientOIDC } from "@fundwave/api-client";
import { GET_HEADERS } from '@fundwave/api-client/src/common.js';

export class SubscriptionBanner extends LitElement {
  static get properties() {
    return {
      subscription: Object,
      daysLeft: Object,
      user: Object,
    };
  }

  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();
    this.loadSubscription();
  }

  async loadSubscription() {
    const headers = await APIClientOIDC.prepareHeaders(GET_HEADERS);

    const subscription = await fetch(`${process.env.SUBSCRIPTION_URL}/user`, {
      method: "POST",
      body: JSON.stringify({
        team: {
          id: localStorage.getItem("team").replace("https://", ""),
          type: "INSTANCE",
        },
      }),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        ...headers,
      },
    })
      .then((resp) => {
        if (resp.status === 404) return { stage: "PURCHASE" };
        if (resp.status === 402) return { stage: "EXPIRED" };
        return resp.json();
      })
      .catch((error) => console.warn(error));

    this.subscription = subscription;

    const subscriptionType = this.subscription?.stage;
    localStorage.setItem("subscriptionType", subscriptionType);

    this.daysLeft = Math.ceil((new Date(this.subscription?.expiry).getTime() - new Date().getTime()) / (24 * 60 * 60 * 1000));

    const isBannerVisible = subscriptionType === "TRIAL" || (subscriptionType === "PURCHASE" && this.daysLeft <= 7);
    this.shadowRoot.dispatchEvent(
      new CustomEvent("updated-subscription", { detail: { isBannerVisible, isTrialUser: subscriptionType === "TRIAL", subscriptionType }, bubbles: true, composed: true }),
    );
  }

  getCallLink() {
    const [team] = localStorage.getItem("team").replace("https://", "").split(".");

    const catchupURL = new URL("https://fundwave.com/catch-up");
    catchupURL.searchParams.set("utm_source", "fw-app");
    catchupURL.searchParams.set("name", this.user.userName);
    catchupURL.searchParams.set("email", this.user.altUserName);
    catchupURL.searchParams.set("organization", team);

    return catchupURL.toString();
  }

  render() {
    return html`
      <style>
        .trial-status-banner {
          padding: 5px;
          text-align: center;
          color: white;
          font-size: var(--secondary-font-size);
          font-weight: 500;
          height: 100%;
          box-sizing: border-box;
        }
        .expiration-banner {
          background-color: #e4a11b;
        }
        .expiration-banner-danger {
          background-color: red;
        }
        .active-trial-banner {
          background-color: #449d44;
        }
      </style>

      ${this.subscription?.stage === "TRIAL"
        ? this.daysLeft > 0
          ? html` <div class="trial-status-banner active-trial-banner">
              ${this.daysLeft} ${this.daysLeft == 1 ? "day" : "days"} remaining in your Free Trial. Need help?
              <a target="_blank" href="${this.getCallLink()}" style="text-decoration: underline; color: white;">Schedule a call with us.</a>
            </div>`
          : html` <div class="trial-status-banner expiration-banner">
              You're now on the Free plan. To access Pro features,
              <a target="_blank" href="${this.getCallLink()}" style="text-decoration: underline; color: white;">Get in touch.</a>
            </div>`
        : this.subscription?.stage === "PURCHASE" && this.daysLeft <= 7
          ? this.daysLeft >= 0
            ? html`<div class="trial-status-banner expiration-banner">Your subscription will renew in ${this.daysLeft} day(s).</div>`
            : html`<div class="trial-status-banner expiration-banner-danger">Your subscription is pending renewal for ${this.daysLeft * -1} day(s).</div>`
          : ""}
    `;
  }
}

customElements.define("subscription-banner", SubscriptionBanner);
