import { UPDATE_INVESTOR, FETCH_INVESTORS, FETCH_USER_INVESTORS, FETCHING_INVESTORS } from "../actions/investor.js";

const initialState = {};

const investor = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_INVESTOR:
      return {
        ...state,
        investor: action.data,
      };
    case FETCH_INVESTORS:
      return {
        ...state,
        investors: action.data,
      };
    case FETCH_USER_INVESTORS:
      return {
        ...state,
        userInvestors: action.data,
      };
    case FETCHING_INVESTORS:
      return {
        ...state,
        loadingInvestors: action.loading,
      };
    default:
      return state;
  }
};

export default investor;
