import { html } from 'lit';

export const MyAppStyles = html`
<style>

   :host {
    --top-app-bar-height: 48px;
    --default-app-banner-height: 30px;
   }

   @media all and (max-width: 767px) {
    :host {
     --default-app-banner-height: 40px;
    }
   }

    app-header {
      --entity-selector-font-size: var(--body-font-size);
    }

    .main-div, .onboarding-tab {
      display: none;
    }

    .main-div[hideDrawer] {
       --drawer-width: 0px;
    }

    .main-div[active] {
      display: flex;
      margin-top: var(--subscription-banner-height);
    }
    
    .onboarding-tab[active] {
      display: block;
    }

    .subscription-banner {
      position: fixed;
      width: 100%;
      top: 0;
      height: var(--subscription-banner-height);
      z-index: 202;
    }

</style>
`;
