import { html } from 'lit';
import { PageViewElement } from '@fundwave/ui-utils/src/page-view-element.js';
import { connect } from 'pwa-helpers/connect-mixin.js';
import { store } from '../../store.js';

//Components
import '@fundwave/fw-avatar/src/fw-avatar-group.js';
import '@polymer/paper-dialog/paper-dialog.js';
import '@fundwave/fw-team-panel/src/team-panel.js'

//Utils
import { UserUtil } from '@fundwave/ui-utils/src/UserUtil.js';

//API
import { FundAPI } from '@fundwave/api-client/src/fund';
import { AssetAPI } from '@fundwave/api-client/src/asset';
import { UserAPIClient } from '@fundwave/openapi-client/user/src';

//Actions
import { fetchFundsOfUser, fetchFundUsers, updateFund } from '../../actions/fund.js';
import { fetchAssetUsers, fetchAssets, updateAssetById } from '../../actions/asset.js';
import { navigate, showMessageBar } from '../../actions/app.js';
import { fetchAllUsers } from '../../actions/user.js';
import { routes } from '../../constants.js';

class TeamView extends connect(store)(PageViewElement) {

  render() {
    return html`
    <fw-team-panel
      id="team-panel"
      style="${!this.fund && !this.asset ? "display: none;" : ""}"
      .users=${this.asset ? this.allAssetUsers : this.allFundUsers} 
      .user=${this.user} 
      .roles=${this.asset ? this.assetRoles : this.fundRoles}
      .roleInfo=${this.asset ? this.assetRoleInfo : this.fundRoleInfo}
      .allUsers=${this.allUsers}
      .roleKey=${this.asset ? "assetRole" : "fundRole"}
      .contextType=${this.asset ? "asset" : "fund"}
      .contextName=${this.asset?.name ?? this.fund?.fundName}
      .defaultRole=${this.asset ? "ASSET_VIEWER" : "VIEWER"}

      .isAdmin=${this.isAdmin}

      @add-user=${this.addUser}
      @resend-invite=${this.resendInvite}
      @cancel-invite=${this.cancelInvite}
      @change-role=${this.changeRole}
      @remove-user=${this.removeUser}
    > 
    </fw-team-panel>
    `
  }

  static get properties() {
    return {
      fundUsers: Object,
      allFundUsers: Array,
      activeFundUsers: Object,
      fund: Object,
      user: Object,
      wideLayout: Boolean,
      userFunds: Object,
      selectedUserFund: Object,
      showRemoveConfirmation: Boolean,
      roles: Array,
      roleInfo: Object,
      showRoleSelector: Object,
      addingUser: Boolean,
      allUsers: Array,
      fundsOfUser: Array,
      assetUsers: Array,
      userAssetRole: Array,
      assetsOfUser: Array,
      asset: Array,
      allAssetUsers: Array,
      isAdmin: Array
    }
  }

  constructor() {
    super();
    this.fundRoles = ["ADMIN", "APPROVER","PORTFOLIO_MANAGER", "INITIATOR", "VIEWER", "PORTFOLIO_VIEWER"];
    this.fundRoleInfo = {
      "ADMIN": "Create and modify fund with investors, assets and more",
      "APPROVER": "Create, modify and approve fund transactions, notify investors and generate reports",
      "INITIATOR": "Create and modify fund transactions and generate reports",
      "VIEWER": "View transactions and generate reports",
      "PORTFOLIO_VIEWER": "Read-Only access to transactions, KPIs and captables",
      "PORTFOLIO_MANAGER": "Create and modify transactions, KPI requests, captables and more",
    };

    this.assetRoles = ["ASSET_MANAGER", "ASSET_VIEWER", "PORTFOLIO_COMPANY"];
    this.assetRoleInfo = {
      "ASSET_MANAGER": "Create and modify transactions, KPI requests, captables and more",
      "ASSET_VIEWER": "Read-Only access to transactions, KPIs and captables",
      "PORTFOLIO_COMPANY": "Invite company users to submit KPIs on a periodic basis",
    };
  }

  updated(changedProperties) {
    if (changedProperties.has('user') || changedProperties.has('fund') || changedProperties.has('userFunds') || changedProperties.has("asset") || changedProperties.has("userAssetRole"))
      this.checkUserRole();
    
    if (changedProperties.has('fundUsers') && this.fund)
      this.fundUsersChanged();

    if (changedProperties.has('assetUsers'))
      this.assetUsersChanged();

    if (changedProperties.has('allUsers'))
      this.sortAllUsers();

    if (changedProperties.has("fundsOfUser"))
      store.dispatch(fetchAllUsers(this.fundsOfUser));
  }

  stateChanged(state) {
    this.fund = state.fund.fund;
    this.user = state.user.user;
    this.allUsers = state.user.allUsers;
    this.fundUsers = state.fund.fundUsers;
    this.fundsOfUser = state.fund.fundsOfUser;
    this.userFunds = state.fund.userFunds;
    this.wideLayout = state.app.wideLayout;

    this.assetUsers = state.asset.assetUsers;
    this.userAssetRole = state.asset.userAssetRole;
    this.assetsOfUser = state.asset.assets;
    this.asset = state.asset.asset;
  }

  async assetUsersChanged() {
    let assetUsers = ( this.assetUsers || [] ).map(user => {
      return {...user, user: {...user.user, status: "accepted"}};
    });

    if (!this.asset?.id) {
      this.allAssetUsers = [...assetUsers];
      return;
    }

    try {
      const payload = {
        context: {
          type: "ASSET",
          id: String(this.asset.id)
        },
        status: "sent"
      }

      let invitedUsers = await UserAPIClient.getInvites(payload);
      invitedUsers = invitedUsers?.map(user => user.metadata);
      this.allAssetUsers = [...assetUsers, ...invitedUsers];
    } catch (err) {
      console.log(err);
      this.allAssetUsers = [...assetUsers];
    }
  }

  async fundUsersChanged() {
    let fundUsers = ( this.fundUsers || [] ).map(user => {
      return {...user, user: {...user.user, status: "accepted"}};
    });

    try {
      const payload = {
        context: {
          type: "FUND",
          id: String(this.fund.fundId)
        },
        status: "sent"
      }

      let invitedUsers = await UserAPIClient.getInvites(payload);
      invitedUsers = invitedUsers?.map(user => user.metadata);
      this.allFundUsers = [...fundUsers, ...invitedUsers];
    } catch (err) {
      console.log(err);
      this.allFundUsers = [...fundUsers];
    }
  }

  checkUserRole() {
    if (this.asset) {
      this.isAdmin = UserUtil.isAssetAdmin(this.user, this.userAssetRole);
    } else {
      this.isAdmin = UserUtil.hasFundRole(this.fund, this.userFunds, "ADMIN");
    }
  }

  removeUser(e) {
    const { user: userContext } = e.detail;

    delete userContext.user.status;
    if (this.asset) {
      AssetAPI.deleteAssetUser(this.asset.id, userContext).then(() => {
        store.dispatch(showMessageBar(`${userContext.user.firstName} removed from ${this.asset.name}`, "success"));
        if (userContext?.asset?.id === this.asset.id) store.dispatch(updateAssetById(null));
        if (userContext?.user?.userId == this.user?.userId)
          store.dispatch(fetchAssets());
        else
          store.dispatch(fetchAssetUsers());
      }).catch((e) => {
        console.log(e);
        store.dispatch(showMessageBar(e.message ? e.message : `We are facing trouble removing this user`, "error"));
      }).finally(() => {
        this.shadowRoot.querySelector("fw-team-panel").closeUserDialog();
      })
    } else {
      FundAPI.removeUserFund(userContext).then(async () => {
        store.dispatch(showMessageBar(`${userContext.user.firstName} removed from ${this.fund.fundName}`, "success"));
        if (userContext?.user?.userId == this.user?.userId){
          await store.dispatch(fetchFundsOfUser());
          store.dispatch(updateFund(null));
        }
        else
          store.dispatch(fetchFundUsers());
      }).catch((e) => {
        console.log(e);
        store.dispatch(showMessageBar(e.message ? e.message : `We are facing trouble removing this user`, "error"));
      }).finally(() => {
        this.shadowRoot.querySelector("fw-team-panel").closeUserDialog();
      })
    }
  }

  changeRole(e) {
    const { role, user } = e.detail;
    const successMessage = "Permission updated successfully";
    const errorMessage = "We are facing trouble changing permission for this user.";

    if (this.asset) {
      AssetAPI.addAssetUser({userName: user?.user?.altUserName}, this.asset.id, role)
        .then((userAsset) => {
          if(userAsset != null)
            store.dispatch(showMessageBar('User updated successfully', 'success'));
          else
            throw new Error();
          store.dispatch(fetchAssetUsers());
        })
        .catch(error => {
          store.dispatch(showMessageBar('An error occured while updating user permissions', 'error'));
        })
        .finally(() => {
          this.shadowRoot.querySelector("fw-team-panel").closeUserDialog();
        })
    } else {

      let userContext = { user: user?.user, fundRole: role, fund: this.fund };
      delete userContext.user.status;

      FundAPI.addUserFund(userContext, false).then(() => {
        store.dispatch(showMessageBar(successMessage ?? `${user.firstName} added to ${this.fund.fundName}`, "success"));
        store.dispatch(fetchFundUsers());
      }).catch((e) => {
        console.log(e);
        store.dispatch(showMessageBar(e.message ? e.message : (errorMessage ?? `We are facing trouble adding this user`), "error"));
      }).finally(() => {
        this.shadowRoot.querySelector("fw-team-panel").closeUserDialog();
      });
    }
  }

  resendInvite(e) {
    const userContext = e.detail.user;
    const { user } = userContext;
    const role = userContext?.[this.asset ? "assetRole" : "fundRole"];

    user.enabled = true;
    user.userName = (user.firstName + " " + user.lastName).trim();
    user.username = user.altUserName;

    const contextId = this.asset?.id || this.fund.fundId;
    const contextType = this.asset ? "ASSET" : "FUND";

    const payload = {
      receiver: {...user, userName: user.altUserName},
      redirectUrl: this.getRedirectUrl(this.asset, role),
      sender: {...this.user, userName: this.user.altUserName},
      context: {
        type: contextType,
        id: String(contextId)
      },
      metadata: userContext
    }

    UserAPIClient.invite(payload)
      .then(res => {
        store.dispatch(showMessageBar(`Invite sent`, "success"));
        this.fetchUsers();
      })
      .catch(e => {
        console.log(e);
        if (e.status == 409)
          store.dispatch(showMessageBar(`User ${user.userName} already exists`, "error"));
        else
          store.dispatch(showMessageBar(e.message ? e.message : (errorMessage ?? `We are facing trouble adding this user`), "error"));
      })
      .finally(() => {
        this.shadowRoot.querySelector("fw-team-panel").closeUserDialog();
      });
  }

  fetchUsers() {
    if (this.asset)
      store.dispatch(fetchAssetUsers());
    else
      store.dispatch(fetchFundUsers());
  }

  cancelInvite(e) {
    const { user } = e.detail;
    const contextId = this.asset?.id || this.fund.fundId;
    const contextType = this.asset ? "ASSET" : "FUND";

    const payload = {
      email: user.user.altUserName,
      context: {
        type: contextType,
        id: String(contextId)
      },
    }

    UserAPIClient.cancelInvite(payload)
      .then(res => {
        store.dispatch(showMessageBar(`Invite cancelled`, "success"));
        this.fetchUsers();
      })
      .catch(e => {
        if (e.status == 400)
          store.dispatch(showMessageBar("We're facing trouble finding this invite", "error"));
        else
          store.dispatch(showMessageBar(e.message ? e.message : (`We are facing trouble cancelling the invite`), "error"));
      })
      .finally(() => {
        this.shadowRoot.querySelector("fw-team-panel").closeUserDialog();
      });
  }

  addUser(e) {
    const { user, role, successMessage, errorMessage } = e.detail;

    if (!user || !role) {
      store.dispatch(showMessageBar("Please fill the inputs", "error"));
      return;
    }
    this.shadowRoot.querySelector("fw-team-panel").setAddingUser(true);
    let userContext = { user };
    user.enabled = true;
    user.userName = (user.firstName + " " + user.lastName).trim();
    user.username = user.altUserName
    user.userId = -1;

    let redirectUrl = this.getRedirectUrl(this.asset, role);

    if (this.asset) {
      userContext.asset = this.asset;
      userContext.assetRole = role;
    } else {
      userContext.fund = this.fund;
      userContext.fundRole = role;
    }

    const contextId = this.asset?.id || this.fund.fundId;
    const contextType = this.asset ? "ASSET" : "FUND";

    // Send the Invite
    const payload = {
      receiver: {...user, userName: user.altUserName},
      redirectUrl,
      sender: {...this.user, userName: this.user.altUserName},
      context: {
        type: contextType,
        id: String(contextId)
      },
      metadata: userContext
    }

    UserAPIClient.invite(payload)
      .then(res => {
        store.dispatch(showMessageBar(`Invited ${user.firstName} to ${this.asset ? this.asset.name : this.fund.fundName}`, "success"));
        this.fetchUsers();
      })
      .catch(e => {
        console.log(e);
        if (e.status == 409)
          store.dispatch(showMessageBar(`User ${user.userName} already exists`, "error"));
        else
          store.dispatch(showMessageBar(e.message ? e.message : (errorMessage ?? `We are facing trouble adding this user`), "error"));
      })
      .finally(() => {
        const teamPanel = this.shadowRoot.querySelector("fw-team-panel");
        teamPanel.setAddingUser(false);
        teamPanel.closeUserDialog();
      });
  }

  getRedirectUrl(asset, role) {
    let redirectUrl;
    const team = window.localStorage.getItem("team");

    if (asset) {
      if (role === "PORTFOLIO_COMPANY") {
        redirectUrl = `${team}/portfolio`;
      } else {
        redirectUrl = window.location.protocol + "//" + window.location.host + "/portfolio" + "?team=" + team;
      }
    } else {
      redirectUrl = window.location.protocol + "//" + window.location.host + "?team=" + team;
    }

    return redirectUrl;
  }

  sortAllUsers() {
    this.allUsers.sort((a, b) => a.altUserName.localeCompare(b.altUserName))
  }

}

customElements.define('team-view', TeamView);
