export const UPDATE_TIME = 'UPDATE_TIME';
export const UPDATE_ENTITY_TIME = 'UPDATE_ENTITY_TIME';
export const NOTICE = 'notice';
export const NOTICE_GROUP = 'noticeGroup';
export const ALLOCATION_KEY = 'allocationKey';
export const ALLOCATION_VARIABLE = 'allocationVariable';
export const INVESTOR = 'investor';
export const ASSET = 'asset';
export const MANAGEMENT_FEE = 'managementFee';
export const EQUILIZATION_POLICY = 'equilizationPolicy';
export const WATERFALL_POLICY = 'waterfallPolicy';

export const updateNotice = () => async (dispatch) => {
      dispatch({type: UPDATE_TIME, data: NOTICE});
      dispatch({type: UPDATE_TIME, data: NOTICE_GROUP});
}

export const updateNoticeGroup = () => async (dispatch) => {
      dispatch({type: UPDATE_TIME, data: NOTICE_GROUP});
}

export const updateAllocationKeylist = () => async (dispatch) => {
      dispatch({type: UPDATE_TIME, data: ALLOCATION_KEY});
}

export const updateAllocationVariablelist = () => async (dispatch) => {
      dispatch({type: UPDATE_TIME, data: ALLOCATION_VARIABLE});
}

export const updateInvestor = () => async (dispatch) => {
      dispatch({type: UPDATE_TIME, data: INVESTOR});
}

export const updateAssetModificationTime = () => async (dispatch) => {
      dispatch({type: UPDATE_TIME, data: ASSET});
}

export const updateManagementFeeTime = () => async (dispatch) => {
      dispatch({type: UPDATE_TIME, data: MANAGEMENT_FEE});
}

export const updateEquilizationPolicyTime = () => async (dispatch) => {
      dispatch({type: UPDATE_TIME, data: EQUILIZATION_POLICY});
}

export const updateWaterfallPolicyTime = () => async (dispatch) => {
      dispatch({type: UPDATE_TIME, data: WATERFALL_POLICY});
}

export const updateEntityTime = (parent, entityType) => async (dispatch) => {
  dispatch({ type: UPDATE_ENTITY_TIME, data: { parent, entityType } });
}

export const updateInvestorEntityTime = (entityType) => updateEntityTime("investor", entityType);
