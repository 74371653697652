import {
  UPDATE_CAPTABLE_DETAILS
} from '../actions/captable.js';

const initialState = {
  captableTeamId : undefined,
  captableServerURL: undefined,
  captableClientURL: undefined
}

const captable = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CAPTABLE_DETAILS:
      return {
        ...state,
        captableServerURL: action.data.captableServerURL,
        captableClientURL: action.data.captableClientURL,
        captableTeamId: action.data.captableTeamId
      };
    default:
      return state;
  }
}

export default captable;