import { routes } from '../constants';
import { getURLParameter, verifyPage } from './app';
import { UserUtil } from '@fundwave/ui-utils/src/UserUtil';
import { FundAPI } from '@fundwave/api-client/src/fund.js';
import { SettingsAPIClient } from '@fundwave/openapi-client';
import { FundAPI as GraphQLFundAPI } from '@fundwave/api-client/src/graphql/fund.js';

export const FETCH_USER_FUNDS = 'FETCH_USER_FUNDS';
export const FETCH_FUND_USERS = 'FETCH_FUND_USERS';
export const FETCH_FUND_SETTINGS = 'FETCH_FUND_SETTINGS';
export const FETCH_FUNDS_OF_USER = 'FETCH_FUNDS_OF_USER';
export const UPDATE_FUND = 'UPDATE_FUND';
export const UPDATE_SELECTED_FUND_ON_PAGE = 'UPDATE_SELECTED_FUND_ON_PAGE';
export const UPDATE_USER_FUND_ROLE = "UPDATE_USER_FUND_ROLE";
export const UPDATE_ASSET_FUNDS = 'UPDATE_ASSET_FUNDS';

export const fetchFundsOfUser = () => async (dispatch) => {
  return FundAPI.getFundsOfUser()
    .then(data => dispatch({type: FETCH_FUNDS_OF_USER, data}));
};

export const updateFund = (fund) => (dispatch) => {
  dispatch({ type: UPDATE_FUND, fund });
  dispatch(updateUserFundRole());
  dispatch(fetchFundUsers());
  dispatch(fetchFundSettings());
}

export const updateSelectedFundOnPage = (fund) => (dispatch) => {
  dispatch({
      type: UPDATE_SELECTED_FUND_ON_PAGE, fund
  });
}

export const fetchUserFunds = () => (dispatch) => {
  GraphQLFundAPI.userFunds()
  .then(data => {
    dispatch({type: FETCH_USER_FUNDS, data});
    dispatch(updateUserFundRole());
  })
}

export function filterFundsWithRole(fundsOfUser, userFunds, role) {
  const funds = new Map();
  fundsOfUser.forEach(( fund ) => {
    if (!UserUtil.hasFundRole(fund, userFunds, role)) return;
    funds.set(fund.fundId, fund);
  });

  return funds;
}

export const fetchFundUsers = () => (dispatch, getState) => {
  let state = getState();
  let fund = state.fund.fund;
  if(fund) {
    FundAPI.getFundUsers(fund?.fundId)
    .then(data => {
      dispatch({type: FETCH_FUND_USERS, data});
    });
  }
  else {
    let data = null;
    dispatch({type: FETCH_FUND_USERS, data});
  }
}

export const fetchFundSettings = () => (dispatch, getState) => {
  let state = getState();
  let fund = state.fund.fund;
  if(fund) {
    SettingsAPIClient.getFundSettings(fund?.fundId)
      .then(data => {
        dispatch(updateFundSettings(data))
      })
  }
  else {
    let data = null;
    dispatch(updateFundSettings(data))
  }
}

export const updateFundSettings = (fundSettings) => (dispatch) => {
  let data = fundSettings;
  dispatch({type: FETCH_FUND_SETTINGS, data});
}

export const updateFundById = (fundId) => (dispatch, getState) => {
  if (fundId === null) return dispatch(updateFund(null));

  const state = getState();
  const funds = state.fund.fundsOfUser;

  let fund;
  if(funds && funds.length > 0 && fundId){
    fund = funds.find((loopFund) => loopFund.fundId == fundId);
  }
  
  const selectedFund = state.fund.fund;
  if(selectedFund?.fundId == fund?.fundId) return;

  dispatch(updateFund(fund ?? null));
}
  
export const getFund = () => (dispatch, getState) => {

  let state = getState();
  const fundsOfUser = state.fund.fundsOfUser;
  let params = state.router.params;
  let activeRoute = state.router.activeRoute;
  let fundId = params?.fundId;

  if(!fundId)
    fundId = getURLParameter('fundId');

  // if(!fundId){
  //   let fundFromStorage = loadFromStorage('fund');
  //   if(fundFromStorage) fundId = fundFromStorage.fundId;
  // }

  //fund is undefined for the first time, redirects to first fund for first time
  // if (state.fund.fund === undefined && fundsOfUser?.length === 1 && activeRoute === routes.home) fundId = fundsOfUser[0].fundId;
  dispatch(updateFundById(fundId));
}

export const updateUserFundRole = () => (dispatch, getState) => {
  let state = getState();
  let userFunds = state.fund.userFunds;
  let fund = state.fund.fund;
  if (fund && userFunds?.length) {
    const fundRole = userFunds.find((details) => details.fund.fundId === fund.fundId)?.fundRole;
    dispatch({ type: UPDATE_USER_FUND_ROLE, data: fundRole });
  } else {
    dispatch({ type: UPDATE_USER_FUND_ROLE, data: null });
  }
};

export const fetchAssetFunds = (asset) => (dispatch, getState) => {
  if (!asset) {
    dispatch({type: UPDATE_ASSET_FUNDS});
    dispatch({type: UPDATE_SELECTED_FUND_ON_PAGE, fund: null});
    return;
  }

  let assets = [];
  assets.push(asset);
  FundAPI.getFundsOfAssets( { asset: assets })
  .then(assetFunds => {
    assetFunds = assetFunds ?? [];
    var fund = assetFunds != null && assetFunds.length > 0 ? assetFunds[0] : null;
    dispatch({type: UPDATE_ASSET_FUNDS, assetFunds});
    dispatch({type: UPDATE_SELECTED_FUND_ON_PAGE, fund});
  });
}
