import {
  UPDATE_WIDE_LAYOUT,
  OPEN_MESSAGEBAR,
  CLOSE_MESSAGEBAR,
  TOGGLE_DRAWER,
  SET_VERSION,
  SET_ABORT_CONTROLLER,
  ADD_ABORTABLE_FUNCTION
} from '../actions/app.js';

const app = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_WIDE_LAYOUT:
      return {
        ...state,
        wideLayout: action.wideLayout
      };
    case OPEN_MESSAGEBAR:
      return {
        ...state,
        messageBarOpened: true,
        message: action.message,
        messageClass: action.messageClass,
        messageTimeout: action.messageTimeout,
        messageURL: action.messageURL,
        messageURLTarget: action.messageURLTarget,
        messageURLText: action.messageURLText,
        messageCustomEvent: action.messageCustomEvent,
        messageCustomEventText: action.messageCustomEventText
      };
    case CLOSE_MESSAGEBAR:
      return {
        ...state,
        messageBarOpened: false
      };
    case TOGGLE_DRAWER:
      return {
        ...state,
        isDrawerOpen: action.isDrawerOpen
      };
    case SET_VERSION:
      return {
        ...state,
        version: action.version
      };
    case SET_ABORT_CONTROLLER:
      return {
        ...state,
        abortController: {
          ...state?.abortController,
          [action.actionName] : new AbortController()
        }
      };
    default:
      return state;
  }
}

export default app;