import { LitElement, html } from 'lit';
import {routes} from '../constants.js';

class Routes extends LitElement {

  static get properties() {
    return {
      route: { type: String },
      params: { type: Object },
      query: { type: Object },
      tracker: { type: Object }
    };
  }

  render() {
    return html`
      <style>
        .page{
          display: none;
          margin: max(3%, 20px);
        }
        .page[active]{
          display: block;
        }
        .wide{
          margin: max(1.5%, 15px);
        }
      </style>

      <portfolio-dashboard class="page" ?active="${this.route === routes.portfoliodashboard}"></portfolio-dashboard>
      <data-submission class="page" ?active="${this.route === routes.portfoliosubmit}" .deliverableRequestId=${this.params?.requestId}></data-submission>
      <submission-confirmation class="page" ?active="${this.route === routes.portfolioconfirmation}"></submission-confirmation>
      <view-submission class="page" ?active="${this.route === routes.portfoliosubmission}" .deliverableRequestId=${this.params?.requestId}></view-submission>
      <review-submission class="page" ?active="${this.route === routes.portfolioreview}"></review-submission>
      <excel-addin class="page" ?active="${this.route === routes.portfolioexcel}" .deliverableRequestId=${this.params?.requestId}></excel-addin>
      <request-overview class="page" ?active="${this.route === routes.portfoliorequestOverview}"></request-overview>
      <deliverable-planner class="page" ?active="${this.route === routes.portfolioviewDeliverable}" ._deliverableId=${this.params?.deliverableId}></deliverable-planner>
      <metrics-overview class="page" ?active="${this.route === routes.portfoliometricsOverview}"></metrics-overview>
      <portfolio-summary class="page" ?active="${this.route === routes.portfolioportfolioSummary}"></portfolio-summary>
      <import-assets class="page" ?active="${this.route === routes.portfolioimportAssets}"></import-assets>
      <bulk-import-transactions class="page" ?active="${this.route === routes.portfolioimportTransactions}"></bulk-import-transactions>
      <asset-details class="page" ?active="${this.route === routes.portfolioassetDetails}"></asset-details>
      <update-asset-feed class="page" ?active="${this.route === routes.portfoliofeed}"></update-asset-feed>
      <asset-round class="page" ?active="${this.route === routes.portfoliorounds}"></asset-round>
      <round-crud class="page" ?active="${this.route === routes.portfolioround}" .roundId=${this.params.roundId ?? 0}></round-crud>
      <asset-forecast-round class="page" ?active="${this.route === routes.portfolioforecastRounds}"></asset-forecast-round>
      <manage-asset-metrics class="page" ?active="${this.route === routes.portfolioassetMetrics}"></manage-asset-metrics>
      <timeline-view class="page" ?active="${this.route === routes.portfoliotimeline}"></timeline-view>
      <valuation-timeline class="page" ?active="${this.route === routes.portfoliovaluationTimeline}"></valuation-timeline>
      <post-investment-view class="page" ?active="${this.route === routes.portfoliopostInvestment}"></post-investment-view>
      <post-valuation-view class="page" ?active="${this.route === routes.portfoliopostValuation}"></post-valuation-view>
      <post-transaction class="page" ?active="${this.route === routes.portfoliopostRealization || this.route === routes.portfoliopostExpense || this.route === routes.portfoliopostIncome}"></post-transaction>
      <post-cashflow-view class="page" ?active="${this.route === routes.portfolioexpense || this.route === routes.portfolioincome}"></post-cashflow-view>
      <post-realization-view class="page" ?active="${this.route === routes.portfoliorealization}"></post-realization-view>
      <edit-assetAccount-view class="page" ?active="${this.route === routes.portfolioinstrument}" .assetAccountId=${this.params?.instrumentId}></edit-assetAccount-view>
      <instrument-dashboard class="page" ?active="${this.route === routes.portfolioinstruments}"></instrument-dashboard>
      <exit-waterfall-dashboard class="page" ?active="${this.route === routes.portfolioexitWaterfall}"></exit-waterfall-dashboard>
      <captable-by-investor class="page" ?active="${this.route === routes.portfoliocaptableByInvestor || this.route == routes.portfoliocoinvestors}"></captable-by-investor>
      <files-view class="page" ?active="${this.route === routes.portfoliofiles}"></files-view>
      <jcurve-projections class="page" ?active="${this.route === routes.portfolioprojections}"></jcurve-projections>
      <note-modelling-view class="page" ?active="${this.route === routes.portfolionoteModelling}" .assetAccountId=${this.params?.noteId}></note-modelling-view>
      <amortization-schedule class="page" ?active="${this.route === routes.portfolioamortizationSchedule}" .assetAccountId=${this.params?.loanId}></amortization-schedule>
      <convert-transaction-view class="page" ?active="${this.route === routes.portfolioconvertTransaction}" .transactionGroupId=${this.params?.transactionGroupId}></convert-transaction-view>
      <transaction-preview class="page" ?active="${this.route === routes.portfoliotransaction}" .transactionGroupId=${this.params?.transactionGroupId}></transaction-preview>
      <import-metrics class="page" ?active=${this.route === routes.portfolioimportMetrics || this.route === routes.portfolioassetImportMetrics}></import-metrics>
      <import-preview class="page" ?active="${this.route === routes.portfolioimportPreview}" .importId=${this.params?.importId}></import-preview>
    `;
  }

  constructor() {
    super();
    this.route = '';
    this.params = {};
    this.query = {};
  }

  connectedCallback() {
    super.connectedCallback();

    this.tracker?.observeNode(this.shadowRoot);
  }

}

customElements.define("portfolio-lit-routes", Routes);
