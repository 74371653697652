import { getURLParameter, verifyPage } from "./app";

import { InvestorAPIClient } from "@fundwave/openapi-client";

export const UPDATE_INVESTOR = "UPDATE_INVESTOR";
export const FETCH_INVESTORS = "FETCH_INVESTORS";
export const FETCH_USER_INVESTORS = "FETCH_USER_INVESTORS";
export const FETCHING_INVESTORS = "FETCHING_INVESTORS";

export const fetchInvestorsOfFund = (override = false, fundId) => async (dispatch, getState) => {
  const state = getState();
  const fund = state.fund.fund;

  const investorsExist = state.investor?.investors?.length;
  const investorsExistForFund = (state.investor.investors || [])
    .every(investor => parseInt(investor?.partnership?.fund?.id) === parseInt(fundId || fund?.fundId));

  if (!override && investorsExist && investorsExistForFund) return;

  if (fund || fundId) {
    dispatch({type: FETCHING_INVESTORS, loading : true});
    return InvestorAPIClient.getInvestors([fundId || fund.fundId])
      .then((data) => dispatch({ type: FETCH_INVESTORS, data }))
      .catch((err) => dispatch({ type: FETCH_INVESTORS, data: [] }))
      .finally(() => dispatch({type: FETCHING_INVESTORS, loading : false}));
  }
  else dispatch({ type: FETCH_INVESTORS, data: [] });
};

export const fetchAllInvestors = () => (dispatch, getState) => {
  let state = getState();
  let funds = state.fund.fundsOfUser;
  if (funds?.length){
    InvestorAPIClient.getInvestors(funds.map(fund => fund.fundId), undefined, ["id", "name", "partnership.fund.id", "partnership.fund.name"]).then((data) => {
      dispatch({ type: FETCH_USER_INVESTORS, data });
    }).catch((err) => {
      dispatch({ type: FETCH_USER_INVESTORS, data: [] });
    })
  }
  else dispatch({ type: FETCH_USER_INVESTORS, data: [] });
};

export const updateInvestor = (investor) => (dispatch) => {
  dispatch({
    type: UPDATE_INVESTOR,
    data: investor,
  });

  // dispatch(verifyPage());
};

export const updateInvestorById = (investorId) => (dispatch, getState) => {
  let state = getState();

  let selectedInvestor = state.investor.investor;
  let userInvestors = state.investor.userInvestors;
  let investorsOfFund = state.investor.investors;

  let investor;
  if (investorsOfFund?.length && investorId)
    investor = investorsOfFund.find((loopInvestor) => loopInvestor.id == investorId);

  if (!investor && userInvestors?.length && investorId)
    investor = userInvestors.find((loopInvestor) => loopInvestor.id == investorId);

  if (investor && selectedInvestor == investor) return;

  dispatch(updateInvestor(investor ?? null));
};

export const updateInvestorByCode = (code) => (dispatch, getState) => {
  
  let state = getState();

  let selectedInvestor = state.investor.investor;
  if (selectedInvestor?.code === code) return;

  dispatch(updateInvestor({code}));
}

export const getInvestor = () => (dispatch, getState) => {
  let state = getState();
  let params = state.router.params;
  // let queryParams = state.router.query;
  let investorId = params?.investorId;
  let investorCode = params?.investorCode;

  if (!investorId) {
    investorId = getURLParameter("investorId");
  }

  if (investorCode && !investorId) dispatch(updateInvestorByCode(investorCode));
  else dispatch(updateInvestorById(investorId));
};
