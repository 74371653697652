import { LitElement, html } from 'lit';
import '@material/mwc-icon-button/mwc-icon-button.js';
import '@material/mwc-icon/mwc-icon.js';
import '@material/mwc-menu/mwc-menu.js';
import '@material/mwc-list/mwc-list-item.js';
import '@polymer/paper-icon-button/paper-icon-button.js';
import { connect } from "pwa-helpers/connect-mixin.js";

import '@fundwave/selectors/entity-selector.js';
import '@fundwave/fw-apps/src/fw-apps-button.js';
import { MenuStyles } from '@fundwave/styles/meta-styles.js';
import { AppHeaderStyles } from '@fundwave/styles/app-header-styles.js';
import { UserUtil } from '@fundwave/ui-utils/src/UserUtil.js';
import { ChatbotAPIClient } from "@fundwave/openapi-client/chatbot/src";
import { store } from "../store.js"
import { fetchTeams } from '../actions/deal';

import { externalURLs } from '../constants';
import './team/team-view.js';
import "./search.js";

import { DateUtil } from '@fundwave/ui-utils/src/DateUtil.js';

class AppHeader extends connect(store)(LitElement) {
  render() {
    return html`

        ${AppHeaderStyles}
        ${MenuStyles}

        <style>
          @keyframes fadeIn {
              from { 
                  opacity: 0; 
                  transform: translateY(-50%);
              }
              to { 
                  opacity: 1; 
                  transform: translateY(0);
              }
          }

          #logo-section {
            flex: 1;
            padding: 8px 12px;
          }

          #action-section {
            flex: 1;
          }

          #search-section {
            align-self: flex-start;
            justify-content: center;
            padding: 7px 12px;
          }

          #fund-logo-container {
            max-width: 100%;
            cursor: pointer;
          }

          @media (max-width: 760px) {
            #logo-section {
              flex: none;
            }

            #action-section {
              flex: none;
            }

            #search-section {
              padding: 7px 0;
            }

            #logo-section, #action-section {
              padding: 0 0;
            }

            .top-bar-header {
              width: 112px;
              height: auto;
              margin: auto 10px;
            }

            .fw-apps-button {
              margin: 0px 1%;
              padding-left: 13px;
            }
          }
        </style>

        <div class="top-app-bar">
          
          <section class="top-app-bar-section top-app-bar-section--align-start" id="logo-section">

            ${this.wideLayout || this.hideDrawer ? html`
              <div class="top-bar-header">
                <img .src="${this.logoUrl}" @error=${(e) => this.onImageError(e)} @tap=${() => window.location.href = "/"} id="fund-logo-container" class="header-img" />
              </div>
            ` : html`
              <mwc-icon-button icon="menu" @tap=${(e) => this.fireEvent('toggleDrawer')}></mwc-icon-button>
            `}
          </section>

          <section class="top-app-bar-section top-app-bar-section--align-start" id="search-section">
            <search-bar .active=${true}></search-bar>
          </section>

          <section class="top-app-bar-section top-app-bar-section--align-end" id="action-section">
  
            ${!this.wideLayout ? html`<team-view style="margin: 0px 1%" ?active=${!!this.fund || !!this.asset}></team-view>` : ""}
  
            ${this.userInvites?.length ? html`
              <div style="position: relative;">
                <mwc-icon-button id="invites" icon="email" @tap=${(e) => this.fireEvent("open-invites-modal")}></mwc-icon-button>
              </div>
            ` : ""}

            <!-- <mwc-icon-button icon="notifications" @tap=${(e) => this.fireEvent('open-activities')}></mwc-icon-button> -->

            ${this.user?.role?.role == "fund" || this.user?.role?.role == "admin" ? html`
              <fw-apps-button .hiddenApps=${this.isPortfolioRole ? ["FUNDAPP", "FUNDADMIN"] : ["FUNDAPP"]} .corner=${"RIGHT"} class="fw-apps-button"></fw-apps-button>
            `:null}
            
            <div style="position: relative;">
              <mwc-icon-button icon="account_circle" @tap=${(e) => this.openMenu(e, "menu")}></mwc-icon-button>
              <mwc-menu innerRole="menu" id="menu">
                  <mwc-list-item graphic="avatar" noninteractive>
                    <mwc-icon slot="graphic">face</mwc-icon>
                    <span>Hello, <span style="color: var(--primary-color)">${this.user?.firstName}</span></span>
                  </mwc-list-item>
                  <li divider></li>
                  <a href=${externalURLs.help} target="_blank">
                    <mwc-list-item graphic="avatar">
                      Help guides
                      <mwc-icon slot="graphic">help_outline</mwc-icon>
                    </mwc-list-item>
                  </a>
                  <mwc-list-item @click=${(e) => this.fireEvent('logout')} graphic="avatar">
                    Sign Out
                    <mwc-icon slot="graphic">power_settings_new</mwc-icon>
                  </mwc-list-item>
              </mwc-menu>
            </div>
          </section>
          
        </div>
    `;
  }

  constructor() {
    super();
    // this._customItems = [{"name" : "Portfolio", "id" : 0}];
    this.userInvites = [];
    this.isPortfolioRole = false;
  }

  connectedCallback() {
    super.connectedCallback();
    store.dispatch(fetchTeams());
  }

  static get properties() { 
    return {
      activePath: Object,
      fund: Object,
      wideLayout: Object,
      user: Object,
      asset: Object,
      funds: Array,
      userFunds: Array,
      _customItems: Array,
      logoUrl: Object,
      userInvites: Array,
      isTrialUser: Boolean,
      hideDrawer: { type: Boolean, attribute: "hide-drawer-toggle" },
      dfTeams: Array,
      dfTeamIds: Array,
      isPortfolioRole: Boolean
    }
  }

  updated(changedProperties) {
    if (changedProperties.has("dfTeams")) {      
      if (this.dfTeams) {
        this.dfTeamIds = this.dfTeams.map((team) => team.id);
      } else {
        this.dfTeamIds = [];
      }
    }

    if (changedProperties.has("funds") || changedProperties.has("userFunds")){
      this.isPortfolioRole = !UserUtil.hasAccessToAtLeastOneFund(this.funds, this.userFunds , "VIEWER");
    }

  }

  stateChanged(state) {
    this.dfTeams = state.deal?.teams;
  }

  openMenu(e, menuRef){
    const menu = this.shadowRoot.querySelector(`#${menuRef}`);
    menu.anchor = e.target;
    menu.corner = "BOTTOM_START";
    menu.menuCorner = "START";
    menu.show();
  }

  closeMenu(_e, menuRef){
    const menu = this.shadowRoot.querySelector(`#${menuRef}`);
    menu.close();
  }

  fireEvent(eventName, detail){
    this.dispatchEvent(new CustomEvent(eventName, {detail, bubbles: true, composed: true}));
  }

  onImageError(e) {
    const defaultFundLogo = "node_modules/@fundwave/images/brand/logoFundwave.png";
    e.target.src = defaultFundLogo;
  }
  
  askQuestion(data, includeDealflow = false) {
    const domain = localStorage.getItem('team'); 
    const toDate = DateUtil.formatDate(new Date(), DateUtil.FORMATS.DEFAULT, true);
    const team = { id: [domain], type: "INSTANCE" };
    const context = [ { id: [], type: "FUND" } ];

    if (includeDealflow) {
      context.push({ id: [], type: "DEAL" });
      team.id = team.id.concat(this.dfTeamIds);
    }

    return ChatbotAPIClient.askQuestion({ ...data, team, context, toDate });
  };
}

customElements.define('app-header', AppHeader);
