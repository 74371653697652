import { APIClientOIDC } from "@fundwave/api-client";
import { GET_HEADERS } from '@fundwave/api-client/src/common.js';

export const FETCH_SUBSCRIPTION = 'FETCH_SUBSCRIPTION';
export const FETCH_DEAL_SUBSCRIPTION = 'FETCH_DEAL_SUBSCRIPTION';

export const fetchSubscription = () => (dispatch) => new Promise(async (resolve, reject) => {

    resolve();

});
  
  
export const fetchDealSubscription = () => (dispatch) => new Promise(async (resolve, reject) => {

    let options = {
        method: 'GET',
        credentials: 'include',
        headers: await APIClientOIDC.prepareHeaders(GET_HEADERS),
        timeout: 5000
      }

    let subscription = await fetch(`${process.env.DF_SUBSCRIPTION_URL}`, options) // team/detail
    .then(resp => resp.json())
    .catch((error) => console.log(error)) ;

    dispatch({type: FETCH_DEAL_SUBSCRIPTION, data: subscription });
    resolve();

});