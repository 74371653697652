// prettier-ignore
import { LitElement, html } from "lit";
import { store } from "../store.js";

import { router } from "lit-element-router";
import { outlet } from "lit-element-router";

import { importPage, importPagePortfolio } from "../actions/app.js";
import { updateRoute } from "../actions/router.js";

import { routes, routeProperties } from "../constants.js";

import "../../portfolio/components/lit-routes.js";

class Routes extends router(LitElement) {
  static get properties() {
    return {
      route: { type: String },
      params: { type: Object },
      query: { type: Object },
      visible: { type: Boolean },
      tracker: { type: Object },
      version: { type: String },
    };
  }

  static get routes() {
    return Object.values(routes)
      .map((route) => {
        // console.log('route: ', route);
        return routeProperties[route].urls.map((url) => {
          return {
            name: route,
            pattern: url,
            data: { title: routeProperties[route].title, pattern: url },
          };
        });
      })
      .flat();
  }

  render() {
    return html`
      <style>
        .tab {
          display: none;
          margin: max(3%, 20px);
        }
        .tab[active] {
          display: block;
        }
        .wide {
          margin: max(1.5%, 15px);
        }
      </style>

      <my-home class="tab" ?active=${this.route === routes.home}></my-home>
      <notice-dashboard class="tab" ?active=${this.route === routes.notice} .visible=${this.visible}></notice-dashboard>
      <fund-dashboard class="tab" style="margin:0" ?active=${this.route === routes.fundMetricDashboard} .visible=${this.visible}></fund-dashboard>
      <my-fund class="tab" ?active=${this.route === routes.dashboard}></my-fund>
      <notice-app class="tab" ?active=${this.route === routes.noticeApp} .noticeGroupId=${this.params.noticeGroupId} .noticeType=${this.params.noticeType}></notice-app>
      <notice-preview class="tab" ?active=${this.route === routes.preview} .noticeGroupId=${this.params.noticeGroupId}></notice-preview>
      <journal-view class="tab" ?active=${this.route === routes.journal} .journalID=${this.params.journalID}></journal-view>
      <notice-transfer class="tab" ?active=${this.route === routes.transfer}></notice-transfer>
      <allocation-settings class="tab" ?active=${this.route === routes.allocationSettings}></allocation-settings>
      <accounting-settings class="tab" ?active=${this.route === routes.accountingSettings}></accounting-settings>
      <allocation-dashboard class="tab" ?active=${this.route === routes.allocationDashboard}></allocation-dashboard>
      <waterfall-policy class="tab" ?active=${this.route === routes.waterfallPolicy}></waterfall-policy>

      <equalization-policy class="tab" ?active=${this.route === routes.equalizationPolicy}></equalization-policy> 

      <management-policy class="tab" ?active=${this.route === routes.managementPolicy}></management-policy>
      
      <allocationkey-container class="tab" ?active=${this.route === routes.allocationKey} .allocationKeyId=${this.params.allocationKeyId}></allocationkey-container>
      <stock-distribution class="tab" ?active=${this.route === routes.stockDistribution} .noticeGroupId=${this.params.noticeGroupId}></stock-distribution>
      <update-fund-feed class="tab" ?active=${this.route === routes.feed}></update-fund-feed>
      <investor-summary-page class="tab" ?active=${this.route === routes.investorDashboard || this.route == routes.allFundInvestorSummary}></investor-summary-page>
      <investor-summary class="tab" ?active=${this.route === routes.investorSummary}></investor-summary>
      <import-dashboard class="tab" ?active=${this.route === routes.importDashboard}></import-dashboard>
      <import-investors class="tab" ?active=${this.route === routes.importInvestors || this.route === routes.fundImportInvestors} .importType=${this.params.importType}></import-investors>
      <import-investor-team class="tab" ?active=${this.route === routes.importInvestorTeam}></import-investor-team>
      <investor-dashboard class="tab" style="margin:0" ?active=${this.route === routes.investorHome} .visible=${this.visible}></investor-dashboard>
      <investor-balances class="tab" style="margin:0" ?active=${this.route === routes.investorBalances} .visible=${this.visible}></investor-balances>
      <investor-contact class="tab" ?active=${this.route === routes.investorContact} .investorId=${this.params.investorId}></investor-contact>
      <investor-bank class="tab" ?active=${this.route === routes.investorBank} .investorId=${this.params.investorId}></investor-bank>
      <fund-bank class="tab" ?active=${this.route === routes.fundBank}></fund-bank>
      <asset-dashboard class="tab" ?active=${this.route === routes.portfolio}></asset-dashboard>
      <deal-dashboard class="tab" ?active=${this.route === routes.dealflow}></deal-dashboard>
      <asset-summary-page
        class="tab"
        ?active=${this.route === routes.portfolioSummary}
        .investorId=${this.params.investorId}
        .investorCode=${this.params.investorCode}
      ></asset-summary-page>
      <investor-compliance class="tab" ?active=${this.route === routes.investorKYC} .investorId=${this.params.investorId}></investor-compliance>
      <fund-contact class="tab" ?active=${this.route === routes.fundContact}></fund-contact>
      <fund-summary class="tab" ?active=${this.route === routes.fundSummary}></fund-summary>
      <bank-import-page class="tab" ?active=${this.route === routes.bankImport}></bank-import-page>
      <market-place class="tab" ?active=${this.route === routes.integrations}></market-place>
      <trial-balance class="tab" ?active=${this.route === routes.accounting}></trial-balance>
      <chart-dashboard class="tab" ?active=${this.route === routes.customDashboard}></chart-dashboard>
      <transaction-dashboard class="tab" ?active=${this.route === routes.transactions}></transaction-dashboard>
      <import-transactions class="tab" ?active=${this.route === routes.importTransactions}></import-transactions>
      <report-dashboard class="tab" ?active=${this.route === routes.report} .groupId=${this.params.reportGroupId}></report-dashboard>
      <notification-settings class="tab" ?active=${this.route === routes.notificationSettings} ></notification-settings>
      <watcher-settings class="tab" ?active=${this.route === routes.watcherSettings} ></watcher-settings>
      <metric-grid class="tab" ?active=${this.route === routes.metricGrid}></metric-grid>
      <deliverable-request-list class="tab" ?active=${this.route === routes.deliverableRequestList}></deliverable-request-list>
      <deliverable-request-manager class="tab" .mode=${this.params.requestMode} ?active=${[routes.deliverableGenerator].includes(this.route)}></deliverable-request-manager>
      <file-groups class="tab" ?active=${this.route === routes.fileGroups}></file-groups>
      <group-files-list class="tab" ?active=${this.route === routes.groupFilesList}></group-files-list>
      <upload-files class="tab" ?active=${this.route === routes.uploadFiles}></upload-files>
      <file-tags class="tab" ?active=${this.route === routes.fileTags}></file-tags>
      <portal-splash-screen class="tab" ?active=${this.route === routes.portalSplash} .portalType=${this.params?.portalType}></portal-splash-screen>
      <mfa-dashboard class="tab" ?active=${this.route === routes.mfa} ></mfa-dashboard>
      <import-preview class="tab" ?active=${this.route === routes.importPreview} .importId=${this.params.importId} .fundId=${this.params.fundId} ></import-preview>
      <exchange-rate class="tab" ?active=${this.route === routes.exchangeRate}></exchange-rate>
      <canvas-page class="tab" ?active=${this.route === routes.canvas}></canvas-page>
      <view-404 class="tab" ?active=${this.route === routes.unknown}></view-404>

      <portfolio-lit-routes .route=${this.route} .params=${this.params} .query=${this.query} .tracker=${this.tracker}></portfolio-lit-routes>
    `;
  }

  constructor() {
    super();
    this.route = "";
    this.params = {};
    this.query = {};
  }

  updated(changedProperties){
    if((changedProperties.has("version") ||  changedProperties.has("route")) && this.version){
      store.dispatch(importPage(this.route, this.version));
      store.dispatch(importPagePortfolio(this.route));
    }
  }

  connectedCallback() {
    super.connectedCallback();

    this.tracker?.observeNode(this.shadowRoot);
  }

  router(route, params, query, data) {
    this.route = route;
    this.params = params;
    //Don't use query - because we use URLSearchParams to encode and lit-element-router uses encodeURI
    this.query = Object.fromEntries(new URLSearchParams(window.location.search));
    
 
    store.dispatch(updateRoute({ route, params, query: this.query, pattern: data?.pattern }));

    if (data.title instanceof Function) data.title = data.title(this.params);
    document.title = data.title ? `${data.title} | Fundwave Fund Management` : `Fundwave Fund Management`;
  }
}

customElements.define("lit-routes", Routes);
