
import { AssetAPI } from '@fundwave/api-client/src/asset';
import { abort, getURLParameter } from './app.js';
import { fetchAssetFunds } from './fund.js';
import { AssetAPIClient } from '@fundwave/openapi-client';

export const FETCH_ASSET_USERS = 'FETCH_ASSET_USERS';
export const FETCH_ASSETS = 'FETCH_ASSETS';
export const FETCH_USER_ASSETS = 'FETCH_USER_ASSETS';
export const FETCH_ASSETS_OF_FUND = 'FETCH_ASSETS_OF_FUND';
export const FETCHING_ASSETS = 'FETCHING_ASSETS';
export const GET_ASSET_IMAGE = 'GET_ASSET_IMAGE';
export const UPDATE_SELECTED_ASSET = 'UPDATE_SELECTED_ASSET';
export const FETCH_USER_ASSET_ROLE = 'FETCH_USER_ASSET_ROLE';
export const UPDATE_FUND_LOGO_IMAGE_URL = 'UPDATE_FUND_LOGO_IMAGE_URL';
export const UPDATE_ASSET_USERS = 'UPDATE_ASSET_USERS';
export const FETCH_ASSET_DETAILS = 'FETCH_ASSET_DETAILS';
export const UPDATE_ASSET_LOGO_IMAGE_URL = 'UPDATE_ASSET_LOGO_IMAGE_URL';
export const UPDATE_ASSET_DESCRIPTION='UPDATE_ASSET_DESCRIPTION';
export const UPDATE_INDEX='UPDATE_INDEX';

export const updateAssetUsers = (userAsset) => (dispatch) => {
  dispatch({ type: UPDATE_ASSET_USERS, userAsset })
}

export const fetchAssets = () => (dispatch) => new Promise((resolve, reject) => {
  AssetAPI.getAssets(null,'asc')
  .then(data => {
    if (data?.error) throw data.error;
    data = data ?? [];
    dispatch({type: FETCH_ASSETS, data });
    resolve();
  });
})

export const fetchAllAssets = () => (dispatch, getState) => {
  let state = getState();
  let funds = state.fund.fundsOfUser;
  if (funds?.length){
    AssetAPIClient.getAssets(undefined, funds.map(fund => fund.fundId), ["id", "name"]).then((data) => {
      dispatch({ type: FETCH_USER_ASSETS, data });
    }).catch((err) => {
      dispatch({ type: FETCH_USER_ASSETS, data: [] });
    })
  }
  else dispatch({ type: FETCH_USER_ASSETS, data: [] });
}

export const fetchAsset = (assetId) => (dispatch) => {
   AssetAPI.fetchAsset({ id: assetId})
  .then(data => {
    if (data.error) throw data.error;
    dispatch(updateSelectedAsset(data));
  });
}

export const getAsset = () => (dispatch, getState) => {
    
  let state = getState();
  let params = state.router.params;
  let assetId = params?.assetId;

  if (!assetId) {
    assetId = getURLParameter("assetId");
  }

  dispatch(updateAssetById(assetId));
}

export const updateAssetById = (assetId) => (dispatch, getState) => {
  let state = getState();

  let selectedAsset = state.asset.asset;
  if (String(selectedAsset?.id) === String(assetId)) return;

  let assets = state.asset.assets;
  let asset;
  if (assets && assets.length > 0 && assetId) {
    asset = assets.find((loopAsset) => loopAsset.id == assetId);
  }
  dispatch(updateSelectedAsset(asset ?? null));
};

const updateSelectedAsset = (asset) => (dispatch, getState) => {

  dispatch({
    type: UPDATE_SELECTED_ASSET, asset
  });

  // saveToStorage("asset", asset);

  dispatch(fetchUserAssetRole(asset));
  dispatch(updateAssetLogoImageURL(asset));
  // dispatch(updateFundLogoImageURL(asset));
  dispatch(fetchAssetUsers());
  dispatch(fetchAssetDetails(asset));
  dispatch(fetchAssetFunds(asset));
  
}

export const updateAssetLogoImageURL = (asset) => (dispatch) => {

    if (!asset)  {
      dispatch({type: UPDATE_ASSET_LOGO_IMAGE_URL});
      return;
    }

    let imageURL = null;
    AssetAPI.getLogo(asset.id).then((blob) => {
      imageURL = URL.createObjectURL(blob);
    }).catch((err) => {
      console.log(err);
      imageURL = null;
    }).finally(() => {
      dispatch({
          type: UPDATE_ASSET_LOGO_IMAGE_URL, imageURL
      });
    })
}

export const updateFundLogoImageURL = (asset) => (dispatch) => {

  
    if (!asset)  {
      dispatch({type: UPDATE_FUND_LOGO_IMAGE_URL});
      return;
    }

    let imageURL = null;
    AssetAPI.getFundLogoForAsset(asset.id).then((blob) => {
      imageURL = URL.createObjectURL(blob);
    }).catch((err) => {
      console.log(err);
      imageURL = null;
    }).finally(() => {
      dispatch({
        type: UPDATE_FUND_LOGO_IMAGE_URL, imageURL
      })
    })
}

export const updateAssetDescription = (description) => (dispatch) => {

  dispatch({
      type: UPDATE_ASSET_DESCRIPTION, description
  })
}

export const fetchUserAssetRole = (asset) => (dispatch, getState) => {
  
  if (!asset)  {
    dispatch({type: FETCH_USER_ASSET_ROLE});
    return;
  }

  AssetAPI.getAssetRole(asset.id)
  .then(data => {
    if (data.error) throw data.error;
    dispatch({type: FETCH_USER_ASSET_ROLE, data })
  });
  
}

export const fetchAssetDetails = (asset) => (dispatch, getState) => {
  
  if (!asset)  {
    dispatch({type: FETCH_ASSET_DETAILS});
    return;
  }
  
  const state = getState();

  AssetAPI.fetchAssetDetails(asset)
  .then(data => {
    dispatch({type: FETCH_ASSET_DETAILS, data })
  })
  .catch(() => {
    var data = null;
    dispatch({type: FETCH_ASSET_DETAILS, data })
  })
}

export const fetchAssetUsers = () => (dispatch, getState) => {
  const state = getState();
  let asset = state.asset.asset;
  if(!asset) return;
  AssetAPI.fetchAssetUsers(asset.id)
  .then(data => {
    dispatch({type: FETCH_ASSET_USERS, data });
  })
  .catch(() => {
    var data = null;
    dispatch({type: FETCH_ASSET_USERS, data });
  });
}

export const fetchAssetsOfFund = () => (dispatch, getState) => {
  dispatch(abort(FETCH_ASSETS_OF_FUND));

  const state = getState();
  const fund = state.fund.fund;
  const fundsOfUser = state.fund.fundsOfUser;
  const fundIds = fund ? [fund.fundId] : fundsOfUser.map((fund) => fund.fundId);
  
  const abortController = state.app.abortController?.[FETCH_ASSETS_OF_FUND];

  dispatch({ type: FETCHING_ASSETS, loading: true });
  AssetAPIClient.getAssets(undefined, fundIds, undefined, { signal: abortController.signal })
    .then((data) => {
      dispatch({ type: FETCH_ASSETS_OF_FUND, data });
    })
    .catch((err) => {
      // Aborted calls are caught as error with same message as the action name for abort
      if (err === FETCH_ASSETS_OF_FUND) {
        console.log("aborted the call for fetching assets");
        return;
      }
      dispatch({ type: FETCH_ASSETS_OF_FUND, data: [] });
    })
    .finally(() => dispatch({ type: FETCHING_ASSETS, loading: false }));
  
};

