import {
  FETCH_ASSET_USERS,
  FETCH_ASSETS,
  FETCH_USER_ASSETS,
  FETCHING_ASSETS,
  FETCH_ASSETS_OF_FUND,
  FETCH_USER_ASSET_ROLE,
  UPDATE_SELECTED_ASSET,
  UPDATE_ASSET_LOGO_IMAGE_URL,
  UPDATE_FUND_LOGO_IMAGE_URL,
  UPDATE_ASSET_USERS,
  UPDATE_ASSET_DESCRIPTION,
  FETCH_ASSET_DETAILS,
  UPDATE_INDEX
} from '../actions/asset.js';

const initialState = {
  assets: undefined,
  imageURL: '',
  businessDescription:'',
  successMessage: '',
  errorMessage: '',
  assetUsers: [],
  currentAsset: {},
  formIndex:0
}

const asset = (state = initialState,  action) => {
  switch (action.type) {
    case UPDATE_INDEX:
      return{
        ...state,
        formIndex:state.index
      };
    case FETCH_ASSETS:
      return {
        ...state,
        assets: action.data
      };
    case FETCH_USER_ASSETS:
      return {
        ...state,
        userAssets: action.data
      };
    case FETCH_ASSETS_OF_FUND:
      return {
        ...state,
        assetsOfFund: action.data
      };
    case FETCHING_ASSETS:
      return {
        ...state,
        loadingAssets: action.loading
      };
    case UPDATE_ASSET_LOGO_IMAGE_URL:
      return {
        ...state,
        assetLogoImageURL: action.imageURL
      };
    case UPDATE_FUND_LOGO_IMAGE_URL:
      return {
        ...state,
        fundLogoImageURL: action.imageURL
      };
    case UPDATE_SELECTED_ASSET:
      return {
        ...state,
        asset: action.asset,
        currentAsset: action.asset
      };
    case UPDATE_ASSET_DESCRIPTION:
      return{
        ...state,
        businessDescription:action.description
    }
    case FETCH_USER_ASSET_ROLE:
      return {
        ...state,
        userAssetRole: action.data
      };
    case FETCH_ASSET_USERS:
      return {
        ...state,
        assetUsers: action.data.userAsset
      };
    case FETCH_ASSET_DETAILS:
      return {
        ...state,
        assetDetails: action.data,
        businessDescription:(action.data!=null)?action.data.businessDescription:''
       
      };
    case UPDATE_ASSET_USERS:
      return {
        ...state,
        assetUsers: action.userAsset
      };
    default:
      return state;
  }
}

export default asset;

export const assetsSelector = state => state.asset.assets;

export const assetSelector = state => state.asset.asset;

export const currentAssetSelector = state => {
  
  if (isEmpty(state.asset.asset) && state.asset.assets != null)
    return state.asset.assets[0];
  else 
    return state.asset.asset;
  
}

const isEmpty = (object) => {
  return object == null || Object.keys(object).length === 0;
}
