export const UPDATE_WIDE_LAYOUT = "UPDATE_WIDE_LAYOUT";
export const OPEN_MESSAGEBAR = "OPEN_MESSAGEBAR";
export const CLOSE_MESSAGEBAR = "CLOSE_MESSAGEBAR";
export const TOGGLE_DRAWER = "TOGGLE_DRAWER";
export const SET_VERSION = "SET_VERSION";
export const SET_ABORT_CONTROLLER  = "SET_ABORT_CONTROLLER";

import { routes, routeProperties, linkedRoutes } from "../constants.js";
import { track } from "../usage-tracking.js";
import { satisfies } from 'compare-versions';

import { resetStatus, resetSubmission } from "./submission.js";

import { UserUtil } from "@fundwave/ui-utils/src/UserUtil.js";

export const importPage = (page, version) => (dispatch) => {
  switch (page) {
    case routes.home:
      iH(import("../components/dashboard/my-home.js"), dispatch);
      break;
    case routes.dealflow:
      iH(import("../components/deal/deal-dashboard.js"), dispatch);
      break;      
    case routes.activities:
      iH(import("../components/activities/activity-feed.js"), dispatch);
      break;
    case routes.notice:
      iH(import("../components/notice/notice-dashboard.js"), dispatch);
      break;
    case routes.fundMetricDashboard:
      iH(import("../components/dashboard/fund-dashboard.js"), dispatch);
      break;
    case routes.dashboard:
      iH(import("../components/fund/my-fund.js"), dispatch);
      break;
    case routes.preview:
      iH(import("../components/notice/notice-preview.js"), dispatch);
      break;
    case routes.journal:
      iH(import("../components/accounting/journal-view.js"), dispatch);
      break;
    case routes.noticeApp:
      iH(import("../components/notice/notice-app.js"), dispatch);
      break;
    case routes.transfer:
      iH(import("../components/notice/notice-transfer.js"), dispatch);
      break;
    case routes.allocationSettings:
      iH(import("../components/allocation/allocation-settings.js"), dispatch);
      break;
    case routes.transactions:
      iH(import("../components/accounting/transaction-dashboard.js"), dispatch);
      break;
    case routes.allocationDashboard:
      iH(import("../components/allocation/allocation-dashboard.js"), dispatch);
      break;
    case routes.waterfallPolicy:
      iH(import("../components/policy/waterfall-policy.js"), dispatch);
      break;

    case routes.managementPolicy:
      iH(import("../components/policy/management-policy.js"), dispatch);
      break;
    case routes.equalizationPolicy:
      iH(import("../components/policy/equalization-policy.js"), dispatch);
      break;
    case routes.accountingSettings:
      iH(import("../components/settings/accounting-settings.js"), dispatch);
      break;
    case routes.allocationKey:
      iH(import("../components/allocation/allocationkey-container.js"), dispatch);
      break;
    case routes.stockDistribution:
      iH(import("../components/notice/stock-distribution.js"), dispatch);
      break;
    case routes.feed:
      iH(import("../components/feed/update-feed.js"), dispatch);
      break;
    case routes.portfolioSummary:
      iH(import("../components/asset/asset-summary-page.js"), dispatch);
      break;
    case routes.portfolio:
      iH(import("../components/asset/asset-dashboard.js"), dispatch);
      break;
    case routes.investorSummary:
      iH(import("../components/investor/investor-summary.js"), dispatch);
      break;
    case routes.investorDashboard:
      iH(import("../components/investor/investor-summary-page.js"), dispatch);
      break;
    case routes.importDashboard:
      iH(import("../components/investor/import-dashboard.js"), dispatch);
      break;
    case routes.importInvestors:
      iH(import("../components/investor/import-investors.js"), dispatch);
      break;
    case routes.importInvestorTeam:
      iH(import("../components/investor-team/import-investor-team.js"), dispatch);
      break;
    case routes.investorContact:
      iH(import("../components/investor/investor-contact.js"), dispatch);
      break;
    case routes.investorBank:
      if(satisfies(version,">1.16.85"))iH(import("../components/investor/investor-bank.js"), dispatch);
      else iH(import("../components/investor/investor-bank-old.js"), dispatch);
    case routes.investorKYC:
      iH(import("../components/investor/investor-compliance.js"), dispatch);
      break;
    case routes.investorHome:
      iH(import("../components/investor/investor-dashboard.js"), dispatch);
      break;
    case routes.investorBalances:
      iH(import("../components/investor/investor-balances.js"), dispatch);
      break;
    case routes.fundContact:
      iH(import("../components/miscellaneous/fund-contact.js"), dispatch);
      break;
    case routes.fundBank:
      if(satisfies(version,">1.16.85"))iH(import("../components/miscellaneous/fund-bank.js"), dispatch);
      else iH(import("../components/miscellaneous/fund-bank-old.js"), dispatch);
      break;
    case routes.fundSummary:
      iH(import("../components/fund/fund-summary.js"), dispatch);
      break;
    case routes.accounting:
      iH(import("../components/accounting/trial-balance.js"), dispatch);
      break;
    case routes.bankImport:
      iH(import("../components/accounting/bank-import-page.js"), dispatch);
      break;
    case routes.integrations:
      iH(import("../components/miscellaneous/integrations.js"), dispatch);
      break;
    case routes.report:
      iH(import("../components/report/report-dashboard.js"), dispatch);
      break;
    case routes.customDashboard:
      iH(import("../components/dashboard/chart-dashboard.js"), dispatch);
      break;
    case routes.transactions:
      iH(import("../components/accounting/transaction-dashboard.js"), dispatch);
      break;
    case routes.importTransactions:
      iH(import("../components/accounting/import-transactions.js"), dispatch);
      break;
    case "login":
      window.location = window.location.origin + "/login";
      break;
    case routes.notificationSettings:
      iH(import("../components/settings/notification-settings.js"), dispatch);
      break;
    case routes.watcherSettings:
      iH(import("../components/settings/watcher-settings.js"), dispatch);
      break;
    case routes.deliverableRequestList:
      iH(import("../components/deliverable/list.js"), dispatch);
      break;
    case routes.deliverableGenerator:
      iH(import("../components/deliverable/manager.js"), dispatch);
      break;
    case routes.metricGrid:
      iH(import("../components/deliverable/metric-grid.js"), dispatch);
      break;
    case routes.fileGroups:
      iH(import("../components/files/groups-list.js"), dispatch);
      break;
    case routes.groupFilesList:
      iH(import("../components/files/group-files-list.js"), dispatch);
      break;
    case routes.uploadFiles:
      iH(import("../components/upload-files/index.js"), dispatch);
      break;
    case routes.fileTags:
      iH(import("../components/files/tags-crud.js"), dispatch);
      break;
    case routes.portalSplash:
      iH(import("../components/miscellaneous/portal-splash.js"), dispatch);
      break;
    case routes.mfa:
      iH(import("../components/settings/mfa-dashboard.js"), dispatch);
      break;
    case routes.importPreview:
      iH(import("../components/accounting/import-preview.js"), dispatch);
      break;
    case routes.exchangeRate:
      iH(import("../components/fund/exchangeRate.js"), dispatch);
      break;
    default:
      iH(import("../components/view-404.js"), dispatch);
      break;
  }
};

export const verifyPagePermission = () => async (dispatch, getState) => {
  const state = getState();

  const fund = state?.fund?.fund;
  const user = state?.user?.user;
  const userFundRole = state?.fund?.userFundRole;

  const route = state?.router?.activeRoute;
  const params = state?.router?.params;

  let routeUserRole = routeProperties[route].userRole;
  let routeFundRole = routeProperties[route].fundRole;
  if (routeFundRole && routeFundRole instanceof Function) routeFundRole = routeProperties[route].fundRole(params);

  const needsAppRole = Boolean(routeUserRole);
  const needsFundRole = Boolean(routeFundRole);

  if ((needsFundRole && !userFundRole) || (needsAppRole && !user?.role?.role)) return;

  const hasUserRoleAccess = needsAppRole && getUserRoles(user?.role?.role)?.includes(routeUserRole);
  const hasFundRoleAccess = needsFundRole && UserUtil.fundRoleChain[routeFundRole]?.includes(userFundRole);

  if ((needsAppRole && !hasUserRoleAccess) || (needsFundRole && !hasFundRoleAccess)) {
    dispatch(navigate(fund ? routes.dashboard : routes.home));
  }
};

export const verifyPage = () => async (dispatch, getState) => {
  const state = getState();

  let fund = state?.fund?.fund;
  let investor = state?.investor?.investor;
  let asset = state?.asset?.asset;

  let activeRoute = state?.router?.activeRoute;
  let activeRouteUrl = state?.router?.activeRouteUrlPattern;

  let redirectRoute;
  if (asset) {
    if (!activeRouteUrl.includes(":assetId")) redirectRoute = routes.portfoliotimeline;
  } else if (investor) {
    if (!activeRouteUrl.includes(":investorId") || !activeRouteUrl.includes(":investorCode")) redirectRoute = fund ? routes.investorHome : routes.portfolioSummary;
  } else if (fund) {
    if (!activeRouteUrl.includes(":fundId")) redirectRoute = routes.dashboard;
    if (activeRouteUrl.includes(":investorId") && !investor) redirectRoute = routes.investorDashboard;
    if (activeRouteUrl.includes(":assetId") && !asset) redirectRoute = routes.portfolio;
  } else {
    if (activeRouteUrl.includes(":assetId")) redirectRoute = routes.portfolio;
    else if (activeRouteUrl.includes(":investorId") || activeRouteUrl.includes(":investorCode")) redirectRoute = routes.investorDashboard;
    else if (activeRouteUrl.includes(":fundId")) redirectRoute = routes.home;
  }

  let params = state?.router?.params ?? {};
  params = getParamsWithContext(params, fund, investor, asset);

  if (!redirectRoute) {
    dispatch(navigate(activeRoute, params)); //would update any entity change in url
    return;
  }
  
  let linkedUrl = getLinkedUrl(activeRoute, { fundId: fund?.id ?? fund?.fundId, investorId: investor?.id ?? investor?.investorId, assetId: asset?.id ?? asset?.assetId });

  if (!linkedUrl) linkedUrl = getRelevantUrl(activeRoute, params, false);
  if (linkedUrl) {
    navigateToUrl(linkedUrl, params);
  }
  else {
    dispatch(navigate(redirectRoute, params));
  }

};

export const navigateInHistory = (pageIndex) => {
  window.history.go(pageIndex);
  // window.dispatchEvent(new CustomEvent('route')); //lit-element-router implicitly dispatch route on window.onpopstate
};

//use when programatically navigating else use <a> tag
export const navigate = (route, params={}, query={}, inNewTab) => async (dispatch, getState) => {
  const state = getState();

  const fund = state?.fund?.fund;
  const investor = state?.investor?.investor;
  const asset = state?.asset?.asset;

  params = getParamsWithContext(params, fund, investor, asset);

  const href = getRelevantUrl(route, params);

  navigateToUrl(href, params, query, inNewTab);

  track(`Navigate to ${route}`);
};

const navigateToUrl = (href, params={}, query={}, inNewTab) => {
  const url = prepareURL(href, params, query);

  if (url != window.location.pathname) {
    if (inNewTab) window.open(url, "_blank");
    else window.history.pushState({}, null, url);
    window.dispatchEvent(new CustomEvent("route"));
  }
};

const getParamsWithContext = (params, fund, investor, asset) => {
  params = {
    ...(params ?? {}),
    fundId: fund?.id ?? fund?.fundId,
    investorId: investor?.id ?? investor?.investorId,
    assetId: asset?.id ?? asset?.assetId,
  };
  params.investorCode = !params.investorId ? investor?.code : null;
  return params;
}

export const prepareURL = (href, params, query) => {

  for (let [key, value] of Object.entries(params)) {
    href = href.replace(`:${key}`, value);
  }

  let urlSearchParams = new URLSearchParams();
  if (query)
    for (let [key, value] of Object.entries(query)) {
      if (value != null && value !== "" && typeof value != "undefined") urlSearchParams.append(key, value);
    }
  else urlSearchParams = new URLSearchParams(location.search);
  let urlParams = urlSearchParams.toString();

  return `${href}${urlParams ? `?${urlParams}` : ``}`;
};

export const getRouteHref = (route) => async (dispatch, getState) => {
  const state = getState();

  let fund = state?.fund?.fund;
  let investor = state?.investor?.investor;
  let asset = state?.asset?.asset;

  let params = state?.router?.params ?? {};
  let query = state?.router?.query ?? {};
  params = getParamsWithContext(params, fund, investor, asset);

  let href = getRelevantUrl(route, params);

  return prepareURL(href, params, query);
};

export const getRelevantUrl = (route, params = {}, defaultToFirstRoute = true) => {
  
  let urls = routeProperties[route]?.urls;
  
  let paramKeys = Object.entries(params)
  .filter((param) => !!param[1])
  .map((param) => param[0]);
  
  let url = urls.find((url) => {
    return paramKeys.every((key) => url.includes(`:${key}`)) && (url.match(/:(\w+)/g) ?? []).every((key) => paramKeys.includes(key.slice(1)));
  }) ?? (defaultToFirstRoute ? urls[0] : null);
  return url;
};

export const getLinkedUrl = (route, params = {}) => {
  let urls = linkedRoutes
    .find((linkedRoute) => linkedRoute.includes(route))
    ?.map((route) => routeProperties[route].urls)
    ?.flat();
  let paramKeys = Object.entries(params)
    .filter((param) => !!param[1])
    .map((param) => param[0]);
  return urls?.find((url) => {
    if (paramKeys?.length) return paramKeys?.length && paramKeys.every((key) => url.includes(`:${key}`));
    else return !url.includes(":fundId") && !url.includes(":investorId");
  });
};

export const getURLParameter = (name) => {
  let param = new URLSearchParams(location.search).get(name);
  if (param) param = param.replace(/^"(.*)"$/, "$1");
  return param;
};

export const getUserRoles = (role) => {
  let roles = ["admin", "fund", "asset", "portfolio", "investor"];
  let userRoles = roles.slice(roles.indexOf(role), roles.length);
  return userRoles;
};

export const updateLayout = (wideLayout) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_WIDE_LAYOUT,
    wideLayout,
  });
};

let snackbarTimer;

export const showMessageBar = (message, messageClass, timeout, url, target, urlText, customEvent, customEventText, customEventData, timeoutCustomEvent) => (dispatch, getState) => {
  if (timeout == null) timeout = 3000;
  dispatch({
    type: OPEN_MESSAGEBAR,
    message: message,
    messageClass: messageClass,
    messageURL: url,
    messageURLTarget: target,
    messageURLText: urlText,
    messageCustomEvent: customEvent,
    messageCustomEventText: customEventText,
    messageCustomEventData: customEventData,
  });
  clearTimeout(snackbarTimer);
  snackbarTimer = setTimeout(() => {
    const app = getState().app;
    if (app.messageBarOpened) {
      if (timeoutCustomEvent) document.dispatchEvent(new CustomEvent(timeoutCustomEvent, { detail: { data: customEventData }, bubbles: true, composed: true }));
      dispatch({ type: CLOSE_MESSAGEBAR });
    }
  }, timeout);
};

export const closeMessageBar = () => (dispatch) => {
  dispatch({ type: CLOSE_MESSAGEBAR });
};

export const toggleDrawer = (isDrawerOpen) => (dispatch) => {
  dispatch({ type: TOGGLE_DRAWER, isDrawerOpen });
};

export const iH = (importPromise, dispatch) => {
  return new Promise((resolve, reject) => {
    importPromise
      .then((module) => {
        resolve(module);
      })
      .catch((err) => {
        console.log(err);
        reject(new Error(err));
        dispatch(showMessageBar("An update is required to view this page.", "warning", 10000, window.location.origin + "/", "_self", "Update"));
      });
  });
};

export const importPagePortfolio = (page) => (dispatch, getState) => {
  const state = getState();

  // Scroll to the top of the page on navigation
  window.scrollTo(0, 0);
  let asset = state.asset.asset;

  switch (page) {
    case routes.portfoliosubmit:
      clearSubmission(state, dispatch);
      clearStatus(state, dispatch);
      iH(import("../../portfolio/components/data-submission/src/data-submission.js"), dispatch);
      break;

    case routes.portfolioconfirmation:
      iH(import("../../portfolio/components/data-submission/src/submission-confirmation.js"), dispatch);
      break;

    case routes.portfoliofeed:
      iH(import("../../portfolio/components/feed/update-feed.js"), dispatch);
      break;

    case routes.portfoliofiles:
      iH(import("../../portfolio/components/files/files.js"), dispatch);
      break;

    case routes.portfoliorequestOverview:
      clearStatus(state, dispatch);
      iH(import("../../portfolio/components/requests/request-overview.js"), dispatch);
      break;

    case routes.portfoliometricsOverview:
      iH(import("../../portfolio/components/data-overview/metrics-overview.js"), dispatch);
      break;

    case routes.portfolioviewDeliverable:
      iH(import("../../portfolio/components/deliverable/deliverable-planner.js"), dispatch);
      break;

    case routes.portfoliosubmission:
      clearStatus(state, dispatch);
      iH(import("../../portfolio/components/view-submission/src/view-submission.js"), dispatch);
      break;

    case routes.portfolioexcel:
      iH(import("../../portfolio/components/data-submission/src/excel-addin.js"), dispatch);
      break;

    case routes.portfolioportfolioSummary:
      iH(import("../../portfolio/components/summary/portfolio-summary.js"), dispatch);
      break;

    case routes.portfoliodashboard:
      iH(import("../../portfolio/components/dashboard/portfolio-dashboard.js"), dispatch);
      break;

    // case routes.portfoliocreateAsset:
    //   iH(import('../../portfolio/src/components/create-asset/create-asset.js'),dispatch);
    //   break;

    case routes.portfolioimportAssets:
      iH(import("../../portfolio/components/create-asset/create-assets.js"), dispatch);
      break;

    case routes.portfolioimportTransactions:
      iH(import("../../portfolio/components/transactions/bulk-import-transactions.js"), dispatch);
      break;

    case routes.portfolioassetDetails:
      iH(import("../../portfolio/components/create-asset/asset-details.js"), dispatch);
      break;

    case routes.portfoliorounds:
      iH(import("../../portfolio/components/rounds/asset-round.js"), dispatch);
      break;

    case routes.portfolioround:
      iH(import("../../portfolio/components/rounds/round-crud.js"), dispatch);
      break;

    case routes.portfolioforecastRounds:
      iH(import("../../portfolio/components/rounds/asset-forecast-round.js"), dispatch);
      break;

    case routes.portfolioexitWaterfall:
      iH(import("../../portfolio/components/modelling/exit-waterfall-dashboard.js"), dispatch);
      break;

    case routes.portfoliocaptableByInvestor:
    case routes.portfoliocoinvestors:
      iH(import("../../portfolio/components/rounds/captable-by-investor.js"), dispatch);
      break;
    // case 'model':
    //   iH(import('../../portfolio/src/components/modelling/note-modelling-dashboard.js'),dispatch);
    //   break;

    case routes.portfolioprojections:
      iH(import("../../portfolio/components/projections/jcurve-projections.js"), dispatch);
      break;

    case routes.portfolioamortizationSchedule:
      iH(import("../../portfolio/components/modelling/amortization-schedule.js"), dispatch);
      break;

    case routes.portfolionoteModelling:
      iH(import("../../portfolio/components/modelling/note-modelling-view.js"), dispatch);
      break;

    case routes.portfolioassetMetrics:
      iH(import("../../portfolio/components/metrics/manage-asset-metrics.js"), dispatch);
      break;

    case routes.portfoliotimeline:
      iH(import("../../portfolio/components/transactions/timeline-view.js"), dispatch);
      break;

    case routes.portfoliovaluationTimeline:
      iH(import("../../portfolio/components/transactions/valuation-timeline.js"), dispatch);
      break;

    case routes.portfolioconvertTransaction:
      iH(import("../../portfolio/components/transactions/convert-transaction-view.js"), dispatch);
      break;

    case routes.portfoliopostInvestment:
      var transaction = state.transaction.transaction;
      if (transaction) iH(import("../../portfolio/components/transactions/post-investment-view.js"), dispatch);
      else dispatch(navigate(routes.portfoliotimeline));
      break;

    case routes.portfoliopostValuation:
      var selectedValuation = state.transaction.selectedValuation;
      if (selectedValuation) iH(import("../../portfolio/components/transactions/post-valuation-view.js"), dispatch);
      else dispatch(navigate(routes.portfoliotimeline));
      break;

    case routes.portfoliotransaction:
      iH(import("../../portfolio/components/transactions/transaction-preview.js"), dispatch);
      break;

    case routes.portfolioinstrument:
      iH(import("../../portfolio/components/asset-account/edit-assetaccount-view.js"), dispatch);
      break;

    case routes.portfolioinstruments:
      iH(import("../../portfolio/components/transactions/instrument-dashboard.js"), dispatch);
      break;

    case routes.portfoliopostRealization:
    case routes.portfoliopostIncome:
    case routes.portfoliopostExpense:
      iH(import("../../portfolio/components/transactions/post-transaction.js"), dispatch);
      break;

    case routes.portfoliorealization:
      var transaction = state.transaction.transaction;
      if (transaction) iH(import("../../portfolio/components/transactions/post-realization-view.js"), dispatch);
      else dispatch(navigate(routes.portfoliopostRealization));
      break;

    case routes.portfolioexpense:
    case routes.portfolioincome:
      var transaction = state.transaction.transaction;
      if (transaction) iH(import("../../portfolio/components/transactions/post-cashflow-view.js"), dispatch);
      else dispatch(navigate(routes.portfoliotimeline));
      break;

    case routes.portfolioimportMetrics:
    case routes.portfolioassetImportMetrics:
      iH(import("../../portfolio/components/metrics/import-metrics.js"), dispatch);
      break;

    // case routes.portfolioonboarding:
    //   iH(import("../../portfolio/components/onboarding/onboarding.js"), dispatch);
    //   break;

    case routes.portfolioimportPreview:
      iH(import("../../portfolio/components/metrics/import-preview.js"), dispatch);
      break;
    
    case routes.canvas:
      iH(import("../components/canvas/canvas.js"), dispatch);
      break;

    case routes.portfoliobye:
      fetch(`/bye`, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
        agent: null,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      window.location = "/bye";
      break;
  }
};

const clearStatus = (state, dispatch) => {
  if (state.submission != null) dispatch(resetStatus());
};

const clearSubmission = (state, dispatch) => {
  if (state.submission != null) dispatch(resetSubmission());
};

export const setServerVersion = (version) => (dispatch) => {
  dispatch({
    type: SET_VERSION,
    version,
  });
};

export const setAbortController = (actionName) => (dispatch) => {
  dispatch({
    type: SET_ABORT_CONTROLLER,
    actionName,
  });
};

export const abort = (actionName) => (dispatch, getState) => {
  const state = getState();
  const abortController = state.app.abortController?.[actionName];
  abortController?.abort(actionName);
  //reset abort controller;
  dispatch(setAbortController(actionName));
} 

