import { html } from "lit";

export const OnboardingStyles = html`
<custom-style>
  <style>
    :host {
      --onboard-widget-height: 70vh;
    }

    .loading-message {
      font-family: var(--theme-font);
      font-size: 20px;
    }

    .loading-img {
      width: 50%;
      max-width: 600px;
      min-width: 300px;
      margin: 35px 0;
    }

    #onboard-container {
      padding: 36px;
    }

    .step-header {
      font-family: var(--theme-font);
      width: 100%;
      font-weight: 600;
      font-size: 27px;
      color: #374151;
      margin: 0px;
    }

    .entity-amount{
      --paper-input-container-input: {
          color: blue;
          font-style: inherit;
          font-size: 35px;
          font-family: var(--theme-font);
          font-weight: 600 !important;
        }
    }

    .step-subheader {
      font-family: var(--theme-font);
      width: 100%;
      font-size: var(--secondary-font-size);
      margin: 10px 0 20px 0px;
      color: #c0c0c0;
    }

    .step-container {
      display: flex;
      align-items: center;
      margin: 20px;
      padding: 0;
      /* border: #eeeeee 1px solid; */
      min-height: 70vh;
    }

    .section-wrapper {
      width: 70%;
      display: flex;
      flex-direction: column;
      margin: auto;
      justify-content: center;
      align-items: center;
    }

    .section {
      width: 70%;
      padding: 30px;
    }

    .graphic {
      height: 70vh;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      background: #f1f1f1;
      padding: 0 20px;
    }

    @media (max-width: 1200px) {
      #onboard-container {
        padding: 0px;
      }
    }
    @media (max-width: 1024px) {
      .graphic {
        display: none;
      }
      .section-wrapper {
        width: 90%;
      }
      .section {
        width: 100%;
      }
    }

    @media (max-width: 660px) {
      #onboard-container {
        padding: 0px;
      }
      .step-container {
        justify-content: center;
      }

      .step-header {
        text-align: center;
      }
    }

    fw-date-picker > input {
      font-weight: 600;
    }
    .entity {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      flex-wrap: nowrap;
      margin: 2% 0;
    }
    .entity-input {
      display: inline-block;
      margin: 0px 10px;
    }

    .entity-name {
      flex: 1.5;
    }

    .entity-amount {
      width : 25%;
      min-width: 115px;
    }

    .step-actions {
      margin-top: 20px;
      display: flex;
      gap: 10px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 3rem;
    }
    @media (max-width: 1150px) {
      .entity {
        justify-content: start;
        flex-wrap: nowrap;
      }

      .entity-input {
        width: fit-content;
      }
    }

    @media (max-width: 769px) {
      .entity {
        border: none;
        box-shadow: 0 0 5px #00000026;
        padding: 0 15px;
        border-radius: 5px;
        flex-direction: column;
        justify-content: center;
        width: 100%;
      }

      .entity-input {
        display: inline-block;
        width: 100%;
        margin: 0;
      }

      .entity {
        height: 70%;
        padding: 1rem;
      }

      .entity-name {
        flex: 0;
      }

      .entity-amount {
        width: 100%;
      }

      .entity-wrapper {
        display: flex;
        align-items: center;
        height: fit-content;
        margin: 1rem 0;
      }

      .commitment-date {
        margin-top: 5px;
      }
    }
  </style>
  </custom-style>
`;
